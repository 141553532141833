@import '../../node_modules/bootstrap/scss/mixins/grid';

@mixin sm-full-width-col {
  @media (max-width: $breakpoint-sm-max) {
    @include make-col(12);

    &:not(:first-child){
      padding-top: 24px;
    }

    &:not(:last-child){
      padding-bottom: 0;
    }
  }
}

.one-col,
.two-col,
.three-col{
  padding: 30px 0;

  .row {
      margin-right: 0;
      margin-left: 0;
  }

  .col{
    @include make-col-ready();
  }

  > .container > .row > .col {
    padding-left: 20px;
    padding-right: 20px;

    &:first-child{
      padding-left: 0;
    }

    &:last-child{
      padding-right: 0;
    }

    @media (max-width: $breakpoint-sm-max) {
      padding-left: 0;
      padding-right: 0;
    }
  }
}

.one-col{
  .col{
    @include make-col(12);
    @include sm-full-width-col;
  }

  &.margin-large{
    .col{
      @media (min-width: $breakpoint-lg-min){
        @include make-col-offset(3);
        @include make-col(6);
      }
    }
  }
}

.two-col{
  &.equal{
    .col{
      &:nth-child(1){
        @include make-col(6);
        @include sm-full-width-col;
      }
      &:nth-child(2){
        @include make-col(6);
        @include sm-full-width-col;
      }
    }
  }
  &.wide-left{
    .col{
      &:nth-child(1){
        @include make-col(8);
        @include sm-full-width-col;
      }
      &:nth-child(2){
        @include make-col(4);
        @include sm-full-width-col;
      }
    }
  }
  &.wide-right{
    .col{
      &:nth-child(1){
        @include make-col(4);
        @include sm-full-width-col;
      }
      &:nth-child(2){
        @include make-col(8);
        @include sm-full-width-col;
      }
    }
  }
}

.three-col{
  .col{
    @include make-col(4);
    @include sm-full-width-col;
  }
}

// background

.dark-bg,
.light-bg{
  margin: 30px 0;
  padding: 60px 0;
}

.dark-bg{
  background-color: $black;
  color: $white;
}

.light-bg{
  background-color: $light-grey;
  color: $black;
}
