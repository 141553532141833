#pipeline-page {
  .page-title-container {
    background: linear-gradient($white, $light-grey);
    margin-bottom: 0;

    .publish-date {
      color: $mid-grey;
      display: flex;
      font-family: $font-family-secondary;
      font-size: 14px;
      letter-spacing: 0.15em;
      justify-content: flex-end;
    }

    &.with-hero .publish-date {
      color: #fff;
    }

    h1 {
      text-align: center;
    }
    .summary {
      text-align: center;
    }
  }

  #pipeline-top-section {
    background: linear-gradient(180deg, $light-grey, $white);
  }

  #pipeline-list-container {
    position: relative;
  }

  #pipeline-actions {
    position: relative;
    z-index: 2;

    .link {
      color: $blue;
      display: inline-block;
    }

    .right-actions {
      .link {
        margin-left: 36px;

        &:first-child {
          margin-left: 0;
        }
      }
    }
  }

  #pipeline-list {
    position: relative;
    z-index: 1;

    .therapeutic-area {
      align-items: center;
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      padding: 28px 0 4px 0;

      h4 {
        margin-bottom: 0;
      }

      .therapeutic-area-count {
        align-items: center;
        display: flex;

        span {
          font-size: 44px;
          font-weight: $weight-light;
          margin-right: 20px;
        }

        .fal {
          font-size: 24px;
        }
      }

      &.collapsed .fa-chevron-up {
        display: none;
      }

      &:not(.collapsed) .fa-chevron-down {
        display: none;
      }

      &:hover {
        color: $blue;
      }
    }

    .drug-grouping {
      font-family: $font-family-secondary;
      font-size: 16px;
      text-transform: uppercase;
      padding-top: 32px;
      padding-bottom: 28px;
      letter-spacing: 0.1em;
    }

    .drug {
      .drug-summary {
        align-items: center;
        border-bottom: 1px solid $border-grey;
        cursor: pointer;
        display: flex;
        padding-top: 20px;
        padding-bottom: 20px;

        .drug-name,
        .drug-indication,
        .drug-phases {
          padding-left: 0;
        }

        .drug-indication {
          color: $mid-grey;
          display: flex;
          font-size: 16px;
          padding-right: 20px;

          span {
            margin-right: 24px;

            &:last-child {
              margin-right: none;
            }
          }
        }

        .drug-name {
          color: $blue;
          font-weight: $weight-bold;
          margin-bottom: 0;
          word-break: break-word;
        }

        .drug-phases {
          display: flex;
          justify-content: space-between;
          position: relative;

          .drug-phase {
            align-items: center;
            border-left: 1px solid $mid-grey;
            display: flex;
            height: 54px;
            width: 25%;
            position: relative;

            span {
              align-items: center;
              color: $orange;
              display: none;
              font-family: $font-family-secondary;
              font-weight: $weight-medium;
              font-size: 16px;
              letter-spacing: 0.15em;
              text-transform: uppercase;
              padding-left: 8px;
            }

            .checkmark {
              align-items: center;
              background: $orange;
              border-radius: 100%;
              display: flex;
              justify-content: center;
              height: 14px;
              width: 14px;
              position: relative;
              padding-left: 3px;
              margin-right: 12px;
              top: -2px;

              .fa-check {
                color: #fff;
                font-size: 9px;
              }
            }

            &:last-child {
              border-left: none;
            }
          }

          &:before {
            background: $orange;
            border-radius: 999px 0 0 999px;
            content: "";
            height: 8px;
            position: absolute;
            top: 50%;
            left: -20px;
            transform: translateY(-50%);
          }

          &.phase-1 {
            .phase-label-1 {
              display: flex;
            }

            &:before {
              width: 20px;
            }
          }

          &.phase-2 {
            .phase-label-2 {
              display: flex;
            }

            &:before {
              width: calc(18px + 25%);
            }
          }

          &.phase-3 {
            .phase-label-3 {
              display: flex;
            }

            &:before {
              width: calc(16px + 50%);
            }
          }

          &.phase-4 {
            .phase-label-4 {
              display: flex;
            }

            &:before {
              display: none;
            }
          }

          &.concept {
            .concept-label {
              display: flex;
            }

            &:before {
              width: 20px;
            }
          }

          &.feasibility {
            .feasibility-label {
              display: flex;
            }

            &:before {
              width: calc(18px + 25%);
            }
          }

          &.development {
            .development-label {
              display: flex;
            }

            &:before {
              width: calc(16px + 50%);
            }
          }

          &.confirmation {
            .confirmation-label {
              display: flex;
            }

            &:before {
              display: none;
            }
          }
        }

        .drug-row-toggle {
          text-align: right;

          .fal {
            color: $mid-grey;
            display: none;
            font-size: 24px;
          }
        }

        &[aria-expanded="false"] .drug-row-toggle .fa-chevron-down {
          display: block;
        }

        &[aria-expanded="true"] .drug-row-toggle .fa-chevron-up {
          display: block;
        }

        &:hover,
        &[aria-expanded="true"] {
          background-color: $light-blue-hover;
        }

        &[aria-expanded="true"] > .container > .row {
          position: relative;

          &:before,
          &:after {
            content: "";
            height: 0;
            width: 0;
            border-left: 12px solid transparent;
            border-right: 12px solid transparent;
            position: absolute;
            right: 58px;
            transform: translateY(-100%);
          }

          &:before {
            position: absolute;
            border-bottom: 12px solid $border-grey;
            bottom: -32px;
            right: 10px;
          }

          &:after {
            position: absolute;
            border-bottom: 12px solid $light-grey;
            bottom: -33px;
            right: 10px;
          }
        }
      }

      .drug-details {
        background: $light-grey;
        position: relative;
        border-bottom: 1px solid $border-grey;

        .inner-container {
          display: flex;
          padding: 40px 0;

          .drug-info {
            padding-left: 0;

            .drug-detail {
              font-family: $font-family-primary-alt;

              .label {
                font-weight: $weight-bold;
              }
            }
          }
        }
      }
    }
  }

  // Medium Screen & Large Screen
  @media (min-width: $breakpoint-md-min) {
    #pipeline-actions {
      .container {
        position: relative;
        .right-actions {
          position: absolute;
          top: -27px;
          right: 0;
          z-index: 2;
        }
      }
    }

    #pipeline-list {
      margin-top: -66px;
    }
  }

  // Medium Screen & Small Screen
  @media (max-width: $breakpoint-md-max) {
    #pipeline-list .drug .drug-summary {
      .drug-phases {
        .drug-phase {
          position: absolute;
          top: 0;

          span {
            padding-top: 2px;
            padding-left: 20px;
          }
        }

        &.phase-4 .drug-phase {
          border-left: none;

          span {
            padding-left: 0;
          }
        }
      }
    }
  }

  // Medium Screen
  @media (min-width: $breakpoint-md-min) and (max-width: $breakpoint-md-max) {
    #pipeline-list .drug {
      .drug-summary {
        .drug-indication {
          padding-right: 10px;
        }

        .drug-phases {
          height: 54px;

          .drug-phase {
            width: 100%;
          }

          &:before {
            left: -6px;
            width: 18px !important;
          }
        }
      }

      .drug-details:before,
      .drug-details:after {
        right: 50px;
      }
    }
  }

  // Small Screen
  @media (max-width: $breakpoint-sm-max) {
    #pipeline-actions {
      display: flex;
      justify-content: flex-start;
      padding-left: 0;
      padding-right: 0;
      margin: 20px 0;

      .right-actions .link {
        margin-left: 0;
        margin-right: 36px;
      }
    }

    #pipeline-list {
      .therapeutic-area {
        h4 {
          font-size: 24px;
        }

        .therapeutic-area-count span {
          font-size: 28px;
        }
      }

      .drug {
        .drug-summary {
          flex-direction: column;
          position: relative;

          .drug-name {
            padding-right: 32px;
          }

          .drug-indication {
            margin-top: 8px;
            margin-bottom: 8px;
          }

          .drug-phases {
            height: 32px;

            .drug-phase {
              border-left: none;
              height: 32px;
              left: 66px;

              .checkmark {
                margin-right: 8px;
              }
            }

            &:before {
              left: 0;
              width: 66px !important;
            }
          }

          .drug-row-toggle {
            padding: 0;
            position: absolute;
            top: 20px;
            right: 30px;
            width: auto;
          }
        }

        .drug-details {
          .inner-container {
            flex-direction: column;
          }

          [class^="col-"] {
            padding-right: 0;
            padding-left: 0;
          }

          .drug-description {
            margin-top: 20px;
          }

          &:before,
          &:after {
            right: 30px;
          }
        }
      }
    }
  }
}
