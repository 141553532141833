
.carousel{
  margin-bottom: $component-margin-bottom;

  .carousel-inner{
    .carousel-item{
      img{
        width: 100%;
      }
    }
  }

  .control-container{
    display: flex;

    position: absolute;
    z-index: 1; 
    top: 12px;
    left: 12px;

    .prev-control,
    .next-control{
      background-color: $white;
      height: 48px;
      width: 60px;
      text-align: center;
      line-height: 48px;

      border: 1px solid $light-grey;

      i{
        color: $blue;
        font-size: 16px;
      }
    }

    .prev-control{
      border-radius: 30px 0 0 30px;
    }

    .next-control{
      border-radius: 0 30px 30px 0;
    }


    @media (max-width: $breakpoint-sm-max) {
      top: -24px;
      left: -1px;

      .prev-control{
        border-radius: 30px 0 0 0;
      }
    }
  }
}
