.safety-notice {

  .safety-notice-header {
    padding-top: 16px;
    padding-bottom: 16px;

    .container {
      align-items: center;
      display: flex;
      justify-content: space-between;
    }

    p {
      font-size: 16px;
      font-weight: $weight-light;
      margin-bottom: 0;
    }

    button {
      white-space: nowrap;
      margin-left: 24px;
    }

    @media (max-width: $breakpoint-sm-max) {
      padding: 12px;

      .container {
        flex-direction: row;
        
        p {
          margin-right: 15px; 
         
          font-size: 14px;
        }
      }
    }
  }

  .safety-notice-body {
    font-size: 16px;

    p {
      font-size: 16px;
    }

    .container {
      padding-top: 24px;
      padding-bottom: 24px;
    }
  }

  .safety-notice-fixed {
    width: 100% !important;
    height: auto !important;
    bottom: 0;
    left: 0;
    position: fixed;
    z-index: $z-index-safety;
   
    
  
    .safety-notice-header {
      background: $black;
      color: $white;
  
      button {
        background: transparent;
        border: none;
        color: $white;
        font-family: $font-family-primary-alt;
        font-size: 16px;
  
        span {
          align-items: center;
          display: flex;
        }
  
        .fa {
          font-size: 16px;
          margin-left: 8px;
        }
  
        .readLess {
          display: none;
        }
      }
    }
  
    .safety-notice-body {
      background: $white;
      overflow: auto;
      max-height: 0;
      transition: max-height 400ms ease;
      
      .summary-message {
        transition: margin-bottom 200ms;
      }
  
      .safety-notice-main {
        margin-top: 40px;
        overflow: hidden;
        transition: max-height 400ms ease;
      }
    }
  
    &.show {
     

      .safety-notice-body{
        max-height: 400px;
        @media (max-width: $breakpoint-sm-max) {
          max-height: 40vh;
        }
      }

      .safety-notice-header button {
        
        .readLess {
          display: block;
        }
  
        .readMore {
          display: none;
        }
      }
  
      .safety-notice-body .safety-notice-main {
        display: block;
      }
    }
  
    &.summary {  
      .safety-notice-body {
        @media (max-width: $breakpoint-sm-max) {
          max-height: 20vh;
        }
        .safety-notice-main {
          display: none;
        }
      }
    }

  
    
  }

  .safety-notice-inline {
    background: $light-bg-body-bg;
    background: linear-gradient($light-bg-body-bg, $white);
    .safety-notice-header {
      background: $light-bg-body-bg;
      color: $black;
    }
    .summary-message{
      margin: 32px 0 48px 0;
    }
    .safety-notice-main{
      margin-top: 40px;
    }
  }
}