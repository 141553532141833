//your theme name
$theme-name: 'os-allergan';

//horizontal scrollbar
$scrollbar-horizontal-size: 8px; //horizontal scrollbar height
$scrollbar-horizontal-padding: 2px;
$scrollbar-horizontal-inner-expansion: 6px;
$scrollbar-horizontal-track-background: $light-blue;
$scrollbar-horizontal-track-background-hover: $light-blue;
$scrollbar-horizontal-track-background-active: $light-blue;
$scrollbar-horizontal-track-transition: background-color 0.3s;
$scrollbar-horizontal-handle-min-size: 30px; //horizontal scrollbar handle min width
$scrollbar-horizontal-handle-max-size: none; //horizontal scrollbar handle max width
$scrollbar-horizontal-handle-background: $dark-blue;
$scrollbar-horizontal-handle-background-hover: $dark-blue;
$scrollbar-horizontal-handle-background-active: $dark-blue;
$scrollbar-horizontal-handle-transition: background-color 0.3s;

//vertical scrollbar
$scrollbar-vertical-size: 8px; //vertical scrollbar width
$scrollbar-vertical-padding: 2px;
$scrollbar-vertical-inner-expansion: 6px;
$scrollbar-vertical-track-background: $light-blue;
$scrollbar-vertical-track-background-hover: $light-blue;
$scrollbar-vertical-track-background-active: $light-blue;
$scrollbar-vertical-track-transition: background-color 0.3s;
$scrollbar-vertical-handle-min-size: 30px; //vertical scrollbar handle min height
$scrollbar-vertical-handle-max-size: none; //vertical scrollbar handle max height
$scrollbar-vertical-handle-background: $dark-blue;
$scrollbar-vertical-handle-background-hover: $dark-blue;
$scrollbar-vertical-handle-background-active: $dark-blue;
$scrollbar-vertical-handle-transition: background-color 0.3s;

//scrollbar corner
$scrollbar-corner-background-color: transparent;

.#{$theme-name} > .os-scrollbar-horizontal {
    right: $scrollbar-vertical-size + ($scrollbar-vertical-padding * 2);
    height: $scrollbar-horizontal-size;
    padding: $scrollbar-horizontal-padding;
}
.#{$theme-name} > .os-scrollbar-vertical {
    bottom: $scrollbar-horizontal-size + ($scrollbar-horizontal-padding * 2);
    width: $scrollbar-vertical-size;
    padding: $scrollbar-vertical-padding;
}
.#{$theme-name}.os-host-rtl > .os-scrollbar-horizontal {
    left: $scrollbar-vertical-size + ($scrollbar-vertical-padding * 2);
    right: 0;
}
.#{$theme-name} > .os-scrollbar-corner {
    height: $scrollbar-horizontal-size + ($scrollbar-horizontal-padding * 2);
    width: $scrollbar-vertical-size + ($scrollbar-vertical-padding * 2);
    background-color: $scrollbar-corner-background-color;
}
.#{$theme-name} > .os-scrollbar-horizontal > .os-scrollbar-track {
    background: $scrollbar-horizontal-track-background;
}
.#{$theme-name} > .os-scrollbar-vertical > .os-scrollbar-track {
    background: $scrollbar-vertical-track-background;
}
.#{$theme-name} > .os-scrollbar-horizontal > .os-scrollbar-track:hover {
    background: $scrollbar-horizontal-track-background-hover;
}
.#{$theme-name} > .os-scrollbar-vertical > .os-scrollbar-track:hover {
    background: $scrollbar-vertical-track-background-hover;
}
.#{$theme-name} > .os-scrollbar-horizontal > .os-scrollbar-track.active {
    background: $scrollbar-horizontal-track-background-active;
}
.#{$theme-name} > .os-scrollbar-vertical > .os-scrollbar-track.active {
    background: $scrollbar-vertical-track-background-active;
}
.#{$theme-name}.os-host-transition > .os-scrollbar-horizontal > .os-scrollbar-track {
    -webkit-transition: $scrollbar-horizontal-track-transition;
            transition: $scrollbar-horizontal-track-transition;
}
.#{$theme-name}.os-host-transition > .os-scrollbar-vertical > .os-scrollbar-track {
    -webkit-transition: $scrollbar-vertical-track-transition;
            transition: $scrollbar-vertical-track-transition;
}
.#{$theme-name} > .os-scrollbar-horizontal > .os-scrollbar-track > .os-scrollbar-handle:before,
.#{$theme-name} > .os-scrollbar-vertical > .os-scrollbar-track > .os-scrollbar-handle:before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: block;
}
.#{$theme-name} > .os-scrollbar-horizontal > .os-scrollbar-track > .os-scrollbar-handle:before {
    top: -$scrollbar-horizontal-inner-expansion;
    bottom: -$scrollbar-horizontal-padding;
}
.#{$theme-name} > .os-scrollbar-vertical > .os-scrollbar-track > .os-scrollbar-handle:before {
    left: -$scrollbar-vertical-inner-expansion;
    right: -$scrollbar-vertical-padding;
}
.#{$theme-name}.os-host-rtl > .os-scrollbar-vertical > .os-scrollbar-track > .os-scrollbar-handle:before {
    right: -$scrollbar-vertical-inner-expansion;
    left: -$scrollbar-vertical-padding;
}
.#{$theme-name} > .os-scrollbar-horizontal > .os-scrollbar-track > .os-scrollbar-handle {
    border-radius: $scrollbar-horizontal-size;
}
.#{$theme-name} > .os-scrollbar-vertical > .os-scrollbar-track > .os-scrollbar-handle {
    border-radius: $scrollbar-vertical-size;
}
.#{$theme-name} > .os-scrollbar-horizontal > .os-scrollbar-track > .os-scrollbar-handle {
    min-width: $scrollbar-horizontal-handle-min-size;
    max-width: $scrollbar-horizontal-handle-max-size;
    background: $scrollbar-horizontal-handle-background;
}
.#{$theme-name} > .os-scrollbar-vertical > .os-scrollbar-track > .os-scrollbar-handle {
    min-height: $scrollbar-vertical-handle-min-size;
    max-height: $scrollbar-vertical-handle-max-size;
    background: $scrollbar-vertical-handle-background;
}
.#{$theme-name} > .os-scrollbar-horizontal > .os-scrollbar-track > .os-scrollbar-handle:hover {
    background: $scrollbar-horizontal-handle-background-hover;
}
.#{$theme-name} > .os-scrollbar-vertical > .os-scrollbar-track > .os-scrollbar-handle:hover {
    background: $scrollbar-vertical-handle-background-hover;
}
.#{$theme-name} > .os-scrollbar-horizontal > .os-scrollbar-track > .os-scrollbar-handle.active {
    background: $scrollbar-horizontal-handle-background-active;
}
.#{$theme-name} > .os-scrollbar-vertical > .os-scrollbar-track > .os-scrollbar-handle.active {
    background: $scrollbar-vertical-handle-background-active;
}
.#{$theme-name}.os-host-transition > .os-scrollbar-horizontal > .os-scrollbar-track > .os-scrollbar-handle {
    -webkit-transition: $scrollbar-horizontal-handle-transition;
            transition: $scrollbar-horizontal-handle-transition;
}
.#{$theme-name}.os-host-transition > .os-scrollbar-vertical > .os-scrollbar-track > .os-scrollbar-handle {
    -webkit-transition: $scrollbar-vertical-handle-transition;
            transition: $scrollbar-vertical-handle-transition;
}


/*
.os-scrollbar {
  padding: 0px;
}
.os-scrollbar-horizontal {
  right: 16px;
  height: 16px;
}
.os-scrollbar-vertical {
  bottom: 16px;
  width: 16px;
}
.os-host-rtl > .os-scrollbar-horizontal {
  left: 16px;
  right: 0;
}
.os-scrollbar-corner {
  height: 16px;
  width: 16px;
  background-color: transparent;
}

.os-scrollbar > .os-scrollbar-track{
  background-color: red;
}

.os-scrollbar > .os-scrollbar-track,
.os-scrollbar > .os-scrollbar-track > .os-scrollbar-handle {
  background: transparent;
}
.os-scrollbar > .os-scrollbar-track > .os-scrollbar-handle:before {
  content: '';
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: $dark-blue;
}
.os-scrollbar > .os-scrollbar-track > .os-scrollbar-handle:hover:before {
  opacity: 0.68;
}
.os-scrollbar > .os-scrollbar-track > .os-scrollbar-handle.active:before {
  opacity: 0.7;
}
.os-scrollbar-horizontal > .os-scrollbar-track > .os-scrollbar-handle {
  min-width: 15px;
}
.os-scrollbar-vertical > .os-scrollbar-track > .os-scrollbar-handle {
  min-height: 15px;
}
.os-scrollbar-horizontal > .os-scrollbar-track > .os-scrollbar-handle:before {
  height: 6px;
  bottom: 0;
  top: auto;
}
.os-scrollbar-vertical > .os-scrollbar-track > .os-scrollbar-handle:before {
  width: 6px;
  right: 0;
  left: auto;
}
.os-host-rtl > .os-scrollbar-vertical > .os-scrollbar-track > .os-scrollbar-handle:before {
  left: 0;
  right: auto;
}
.os-scrollbar-horizontal > .os-scrollbar-track > .os-scrollbar-handle:hover:before,
.os-scrollbar-horizontal > .os-scrollbar-track > .os-scrollbar-handle.active:before {
  height: 100%;
}
.os-scrollbar-vertical > .os-scrollbar-track > .os-scrollbar-handle:hover:before,
.os-scrollbar-vertical > .os-scrollbar-track > .os-scrollbar-handle.active:before {
  width: 100%;
}
.os-host-transition > .os-scrollbar-horizontal > .os-scrollbar-track > .os-scrollbar-handle:before {
  transition: opacity 0.3s, height 0.3s;
}
.os-host-transition > .os-scrollbar-vertical > .os-scrollbar-track > .os-scrollbar-handle:before {
  transition: opacity 0.3s, width 0.3s;
}
*/
