.title-component {
  @include make-col-ready();
  @include make-col(12);

  padding-left: 0;
  padding-right: 0;

  &.left > * {
    text-align: left;
  }
  &.right > * {
    text-align: right;
  }
  &.center > * {
    text-align: center;
  }
  &.justify > * {
    text-align: justify;
  }
}

@media (min-width: $breakpoint-lg-min) {
  .one-col {
    .title-component.left {
      @include make-col(8);
    }
  }
}
