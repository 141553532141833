.country-selector-btn {
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 10px;
  padding-right: 10px;
  transition: color $transition-speed $transition-style;

  .fa-globe {
    font-size: 16px;
    font-weight: $weight-light;
    padding-right: 8px;
    transition: color $transition-speed $transition-style;
  }

  &::after {
    font-family: $font-awesome-pro;
    font-size: 8px;
    content: "\f054"; // chevron-right

    position: absolute;
    top: 0;
    right: -1px;
  }

  &.is-open {
    &::after {
      content: "\f053"; // chevron-left
    }

    .fa-globe {
      transition: none;
    }

    &:hover .fa-globe {
      transition: color $transition-speed $transition-style;
    }
  }

  &:hover {
    color: $blue;

    .fa-globe {
      color: $blue !important;
      //transition: color $transition-speed $transition-style;
    }
  }
}

#country-selector-container-overlay {
  position: fixed;
  z-index: 0;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: $black;
  opacity: 0;
  pointer-events: none;

  &.open {
    pointer-events: all;
  }

  @media (max-width: $breakpoint-md-max) {
    //opacity: 0 !important;
    //pointer-events: none !important;
  }
}

#country-selector-container {
  position: absolute;
  z-index: $z-index-country-selector;
  top: $eyebrow-nav-height;
  left: 0;
  padding: 0 50px;
  width: auto;

  @media (max-width: $breakpoint-md-max) {
    width: 100%;
    top: $mobile-main-nav-menu-header-height;
    padding: 0;
  }

  // display: none;

  .container {
    position: relative;
    padding: 0;

    $country-menu-header-height-md: 154px;
    $country-menu-header-height-lg: 88px;

    .country-select-left-background {
      position: absolute;
      top: 0;
      right: 100%;
      background-color: $white;
      height: 100%;
      width: 100%;

      &::before {
        position: absolute;
        top: 0;
        left: 0;
        content: " ";
        height: $country-menu-header-height-lg;
        width: 100%;
        background-color: $black;

        @media (max-width: $breakpoint-md-max) {
          height: $country-menu-header-height-md;
        }
      }
    }

    #country-selector-menu {
      $sm-width: 100%;
      $md-width: 375px;
      $lg-width: 530px;

      display: flex;
      flex-direction: column;
      justify-self: flex-end;

      $header-curve-width: 43px;

      // Setup menu's initial width
      // on small, take up 100% of screen
      width: $sm-width;
      height: calc(100vh - #{$mobile-main-nav-menu-header-height});

      // on medium, take up 375px of screen
      @media (min-width: $breakpoint-md-min) and (max-width: $breakpoint-md-max) {
        width: $md-width;
        height: calc(100vh - #{$mobile-main-nav-menu-header-height});
      }

      // on large, take up 375px of screen
      @media (min-width: $breakpoint-lg-min) {
        width: $lg-width;
        $eyebrow-nav-height: 30px;
        height: calc(100vh - #{$eyebrow-nav-height});
      }
      // End setup menu's initial width

      background-color: $white;

      .country-select-header {
        position: relative;
        display: flex;
        height: $country-menu-header-height-lg;

        // @media (max-width: $breakpoint-md-max) {
        //   height: $country-menu-header-height-md;
        // }

        background-color: $black;

        .header-curve {
          position: absolute;
          top: 0;
          left: 100%;
          width: $header-curve-width;
          margin-left: -1px;

          @media (max-width: $breakpoint-md-max) {
            display: none;
          }
        }

        .mobile {
          display: none;

          @media (max-width: $breakpoint-md-max) {
            display: block;
          }
        }

        > div {
          //padding-left: 50px;
          padding-bottom: 16px;
          height: 100%;

          @media (max-width: $breakpoint-md-max) {
            padding-left: 34px;
          }

          &:not(.close-container) {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            width: 50%;
          }

          .title {
            color: $white;
            font-size: 24px;
          }

          .site-title {
            font-size: 20px;
            font-family: $font-family-primary-alt;
            font-weight: $weight-semibold;

            a {
              text-decoration: none;
            }
          }

          .descr {
            color: $white;
            font-size: 12px;
            line-height: 12px;
          }

          &.close-container {
            flex: 1;
            padding-right: 30px;
            text-align: right;

            i {
              color: $white;
              cursor: pointer;
            }

            @media (max-width: $breakpoint-md-max) {
              display: none;
            }
          }
        }
      }

      .country-select-labels {
        border-bottom: 1px solid $light-grey;
        display: flex;
        padding-top: 15px;
        padding-bottom: 15px;
        width: calc(100% - 38px);

        span {
          color: $mid-grey;
          font-family: $font-family-primary-alt;
          font-weight: $weight-semibold;
          flex-grow: 1;
          flex-shrink: 1;
          flex-basis: 50%;
        }

        @media (max-width: $breakpoint-md-max) {
          display: none;
        }
      }

      .country-select-body {
        flex: 1;

        .country-links-container {
          height: 100%;
          table {
            width: calc(100% - 38px);

            @media (max-width: $breakpoint-md-max) {
              width: 100%;
            }

            .country-select-labels-table {
              display: none;

              th {
                padding-left: 34px;
              }

              @media (max-width: $breakpoint-md-max) {
                display: table-row;
              }
            }

            tr {
              border-bottom: 1px solid $light-grey;

              td,
              th {
                width: 50%;
                padding-top: 15px;
                padding-bottom: 15px;
              }

              th {
                color: $mid-grey;
                font-family: $font-family-primary-alt;
                font-weight: $weight-semibold;
              }

              .country {
                color: $black;
                font-family: $font-family-primary-alt;
                font-weight: $weight-bold;

                @media (max-width: $breakpoint-md-max) {
                  padding-left: 34px;
                }

                a {
                  font-weight: $weight-bold;
                  text-decoration: none;
                }
              }

              .link {
                display: table-cell;
                font-family: $font-family-link;
                font-weight: $weight-semibold;

                @media (max-width: $breakpoint-md-max) {
                  padding-left: 34px;
                }

                a {
                  text-decoration: none;
                  width: 200px;
                  display: flex;
                  justify-content: space-between;
                  align-items: center;

                  @media (max-width: $breakpoint-md-max) {
                    padding-right: 30px;
                  }

                  i {
                    color: $blue;
                  }
                }
              }

              &.divider {
                border-color: $mid-grey;

                td {
                  padding: 0;
                }
              }
            }
          }
        }
      }
    }
  }
}

#main-nav-mobile-container {
  .country-selector-btn {
    &::after {
      top: 2px;
    }
  }
}

body.country-selector-open {
  overflow: hidden;
}

// This is used on the container when the list is a component
.countries-list-component {
  .table-container {
    table {
      th,
      td {
        min-width: 0;

        a {
          white-space: nowrap;
        }
      }
    }
  }
}

// When the component is in a one column layout, make it 2/3rs of the width
.one-col {
  .countries-list-component {
    @media (min-width: $breakpoint-lg-min) {
      width: 66%;
    }
  }
}
