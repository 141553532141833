@import './base';

body.homepage {
  .homepage-hero-container {

    .page-title-container {
      .home-titles{
        width: 890px;
        margin: 0 auto!important;
        @media (max-width: $breakpoint-md-max) {
          width: 675px;
        }
        @media (max-width: $breakpoint-sm-max) {
          width: 300px;
        }
      }
      .home-title{
        white-space:initial;
        width: 646px;
        margin: 0 auto;
        @media (max-width: $breakpoint-sm-max) {
          width: 100%;
        }
      }
      .home-title__text{
        font-size: 110px!important;
        @media (max-width: $breakpoint-sm-max) {
          font-size: 60px!important;
          margin-left: 0!important;
          margin-right: 0!important;
        }
      }
      .home-subtitle {
        margin: auto 100px 0;
        letter-spacing: 0;
        font-weight: $weight-light;
        text-transform: none;
        font-family: $font-family-primary;
        font-size: 32px;
      }
    }
  }

  // Small Screen
  @media (max-width: $breakpoint-sm-max) {
    .homepage-hero-container {
      .page-title-container {
        .home-subtitle {
          font-size: 24px;
          margin: 0; 
        }
      }
    }
  }
}
