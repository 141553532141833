.privacy-warning {
  .expand-container {
    display: none;
  }

  // Medium and higher
  @media (min-width: $breakpoint-md-min) {
    .container {
      .info {
        .info-content {
          position: relative;
          height: 41px;
          overflow: hidden;
        }

        .expand-container {
          display: block;
          margin-top: 10px;

          .expand-link,
          .collapse-link {
            color: $link-color;
            text-transform: uppercase;
            cursor: pointer;

            position: relative;
            padding-right: 16px;
            &:after {
              font-family: $font-awesome-pro;
              font-weight: $weight-light;
              font-size: 14px;
              position: absolute;
              top: 50%;
              transform: translateY(-50%);
              right: 0;
            }
          }

          .expand-link {
            display: inline-block;

            &:after {
              content: "\f077";
            }
          }

          .collapse-link {
            display: none;

            &:after {
              content: "\f078";
            }
          }
        }

        &.expanded {
          .expand-container {
            .expand-link {
              display: none;
            }

            .collapse-link {
              display: inline-block;
            }
          }
        }
      }
    }
  }
}
