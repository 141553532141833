/**
 * These values will not show up in content, but can be
 * queried by JavaScript to know which breakpoint is active.
 * Add or remove as many breakpoints as you like.
 */
 body:before {
  content: "sm";
  display: none; /* Prevent from displaying. */

  @media (min-width: $breakpoint-md-min) {
    content: "md";
  }

  @media (min-width: $breakpoint-lg-min) {
    content: "lg";
  }
}


// Language Fonts
body 		{font-family: $font-family-primary}
:lang(ar) 	{font-family: "Scheherazade",serif;
                 font-size: 120%;}
:lang(zh-Hant) 	{font-family: Kai,KaiTi,serif;}
:lang(zh-Hans) 	{font-family: DFKai-SB,BiauKai,serif;}
:lang(din) 	{font-family: "Doulos SIL",serif;}

// Base style
body{
  line-height: 1.4;
}

a {
  color: $link-color;
  font-family: $font-family-link;
}

.link{
  cursor: pointer;
}

* {
  outline-color: $link-color;
} 

// Layout
html,
body{
  margin: 0;
  min-height: 100vh;
}

body{
  display: flex;
  flex-direction: column;
  position: relative;

  footer {
    flex: none;
  }

  main{
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;
    min-height: 1px;
  }

  nav .background-container,
  main .background-container,
  footer .background-container{
    width: 100%;
    padding-left: 50px;
    padding-right: 50px;
    min-height: 1px;
    

    @media (max-width: $breakpoint-md-max) {
      padding-left: 42px;
      padding-right: 42px;
      
    }

    @media (max-width: $breakpoint-sm-max) {
      padding-left: 30px;
      padding-right: 30px;
    
    }
  }

  [class^="col-"],
  [class*=" col-"] {
    padding-right: $grid-gutter-width;
    padding-left: $grid-gutter-width;

    @media (max-width: $breakpoint-sm-max) {
      padding-left: $grid-gutter-width-sm;
      padding-right: $grid-gutter-width-sm;
    }

    &:first-child { 
      padding-left: 10px;
    }
    &:last-child { 
      padding-right: 10px;
    }
  }
}

blockquote{
  border: 1px solid $black;
  font-weight: bold;
  box-sizing: border-box;
  padding: 20px;
}

.inline-component {
  display: inline;
  margin-right: 40px;
  margin-bottom: 40px;
}