@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

.loading {
  position: relative;
  overflow: hidden;
  
  &::before {
    position: absolute;
    z-index: 20;
    top: 0;
    left: 0;
    
    height: 100%;
    width: 100%;

    content: " ";
    
    background-color: $light-grey;
  }

  &::after {
    position: absolute;
    z-index: 21;
    top: 50%;
    margin-top: -56px;
    left: 50%;
    margin-left: -40px;
    
    display: block;
    font-family: $font-awesome-pro;
    font-weight: $weight-light;
    font-size: 80px;
    content: "\f3f4";
    color: $dark-blue;

    animation: rotation 2s infinite linear;
  }

  .loading-message {
    position: absolute;
    z-index: 21;
    top: 50%;
    margin: 56px auto 0;
    width: 100%;
    text-align: center;
  }
}


// .loading-container {
//   position: relative;

//   .loading-wheel{
//     position: absolute;
//     z-index: 10;
//     top: 0;
//     left: 0;
//     height: 100%;
//     width: 100%;

//     background-color: red;//$light-grey;

//     display: flex;
//     align-items: center;
//     justify-content: center;

//     i {
//       font-size: 20px;
//       color: $dark-blue;
//     }
//   }
// }
