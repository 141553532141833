.image-promo {
  display: block;
  margin-right: 0;
  a {
    .image-top-container {
      position: relative;
      overflow: hidden;
      width: 100%;

      img {
        object-fit: cover;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        transition: transform $transition-speed $transition-style;
      }

      .image-content-container {
        position: relative;
        margin-top: 200px;
        .image-disclaimer{
          display: flex;
          flex-flow: row-reverse;
          margin-right: 50px;
        }
      }
    }

    &:hover {
      text-decoration: none;

      .image-top-container {
        img {
          transform: translate(-50%, -50%) scale(1.1);
        }
      }
    }

    &.external {
      border-radius: 0 30px 0 0;
      overflow: hidden;

      .external-img-icon {
        position: absolute;
        top: 0;
        right: 0;
        background-color: $orange;
        color: $white;
        font-size: 16px;
        padding: 15px;
      }
    }
  }
}
