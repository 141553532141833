@import '../../node_modules/bootstrap/scss/mixins/buttons';
@import '../../node_modules/bootstrap/scss/mixins/transition';

.link-list {
  display: inline-block;
  margin-right: 40px;
  margin-bottom: 40px;
  max-width: 100%;
  vertical-align: top;

  h4{
    margin-bottom: 24px;
  }

  ul{
    display: inline-block;
    list-style: none;
    padding-left: 0;
    max-width: 100%;

    li{
      display: flex;
      padding-bottom: 16px;

      &:last-child{
        padding-bottom: 0;
      }

      &.link-section-title{
        color: $mid-grey;
        font-size: 16px;
        padding-top: 12px;
      }

      .link{
        display: flex;
        width: 100%;
        margin-right: 0;
        margin-bottom: 0;

        .text{
          flex-grow: 1;
          flex-shrink: 1;
          flex-basis: auto;
        }

        .info {
            white-space: nowrap;
        }

        &.with-icon{
          i{
            padding-top: 5px;
          }
        }

        &.with-arrow-right,
        &.with-arrow-left{
          .info{
            margin-top: -7px;
          }
        }

        &.with-arrow-right,
        &.with-icon{
          .text{
            padding-right: 48px;
          }
        }
      }
    }
  }
}
