// Article Detail Layout
#article-detail-page {
  .page-title-container {
    margin-bottom: 0;

    &.with-hero {
      .blog-title {

        @media (min-width: $breakpoint-lg-min) {
          top: -30px;
        }
      }
    }
  }

  .placeholder-container {
    padding-top: 0;
  }

  @media (max-width: $breakpoint-sm-max) {
    .article-info {
      flex-direction: column-reverse;

      .main-date-container {
        margin-top: 20px;
        margin-bottom: 20px;
        margin-right: 12px;

        .tag-label,
        .author {
          display: none;
        }
      }

      .secondary-date-container {
          display: inline-block;
          margin-bottom: 20px;
          margin-right: 12px;

          .detail-date-container {
              margin-bottom: 0;
          }
      }
    }
  }

  @media (min-width: $breakpoint-md-min) {
    .article-link {
      text-align: right;
    }

    .article-info {
      .secondary-date-container {
        display: none;
      }
    }
  }
}

body.has-hero {
  #article-detail-page {
    .page-title-container {
      margin-bottom: 0;
      .blog-title {
        top: -30px;

        .back-link {
          a {
            color: $white;
          }
        }
      }
    }

    .article-info{
      margin-top: -25px;
      position: relative;
      z-index: 1;
    }
  }
}
// End Article Detail Layout


// Latest Articles
.latest-articles {
  $post-spacing: 22px;

  margin-bottom: $component-margin-bottom;

  .component-header {
    display: flex;

    @media (max-width: $breakpoint-sm-max) {
      flex-direction: column;
    }

    .component-title {
      flex: 1;
      text-transform: uppercase;
    }

    .view-all {
      a {
        text-decoration: none;
        font-family: $font-family-link;
        font-weight: $weight-semibold;
      }
    }
  }

  .posts {
    white-space: nowrap;
    display:flex;

    .post {
      margin-top: 35px;
      margin-bottom: 20px;
      white-space: normal;

      display: inline-block;
      position: relative;
      min-height: 100%;

      margin-right: 20px;
      &:last-child{
        margin-right: 0;
      }

      padding-bottom: 20px;

      background-color: $light-grey;
      border-radius: 0 0 30px 0;

      a {
        text-decoration: none;

        .post-date {
          position: absolute;
          top: -25px;
          left: -1px;
          z-index: 2;
        }

        .post-image-container {
          position: relative;
          overflow: hidden;
          width: 100%;
          height: 220px;

          .post-image {
            object-fit: cover;
            position: absolute;
            width: 100%;
            height: 100%;
            top: 50%;
            left: 50%;
            transform: translateX(-50%) translateY(-50%);
            transition: transform $transition-speed $transition-style;
          }
        }

        .post-title {
          margin-top: 20px;
          margin-left: 25px;
          margin-right: 25px;
          color: $blue;
        }
      }

      &:hover {
        background-color: $blue;
        -webkit-box-shadow: 0px 2px 12px rgba($black, 0.2);
        -moz-box-shadow: 0px 2px 12px rgba($black, 0.2);
        box-shadow: 0px 2px 12px rgba($black, 0.2);

        a {
          .post-image {
            transform: translate(-50%, -50%) scale(1.1);
          }

          .post-title{
            color: $white;
          }
        }
      }
    }
  }

  &.scrollable-posts {
    .posts {
      .post {
        width: 100%;

        @media (min-width: $breakpoint-md-min) {
          min-width: 300px;
          flex: 1 0 calc(33% - #{$post-spacing});
        }

        @media (max-width: $breakpoint-sm-max) {
            flex: 1 0 100%;
        }

        .post-image-container {
          height: 221px;
        }
      }
    }
  }

  &.double-post,
  &.single-post {
    .posts {
      .post {
        min-width: calc(50% - #{$post-spacing});
        width: calc(50% - #{$post-spacing});
      }
    }
  }

  &.double-post{
    .posts {
      .post {
        min-width: calc(50% - #{$post-spacing});
        width: calc(50% - #{$post-spacing});
      }
    }
  }

  &.single-post {
    .posts {
      .post {
        width: 100%;

        @media (min-width: $breakpoint-lg-min) {
          min-width: 70%;
          width: 70%;
        }
      }
    }
  }

  &.img-full{
    .post{

      margin-bottom: 0;
      padding-bottom: 0;
      border-radius: 30px 0 30px 0;

      a {
        display: flex;
        flex-direction: row-reverse;

        .post-date {
          top: -1px;
        }

        .post-image-container {
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          border-radius: 30px 0 30px 0;

          .post-image {
            height: 100%;
          }
        }

        .post-content{
          padding-top: 200px;
          padding-bottom: 35px;
          z-index: 2;
          width: 100%;

          .post-title {
            color: $white;
          }
        }
      }
    }
  }
}

// Background variations
.light-bg{
  .latest-articles {
    .posts {
      .post {
        background-color: $white;
        &:hover {
          background-color: $blue;
        }
      }
    }
  }
}
// End Background variations

// End Latest Articles
