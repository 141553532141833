// Set up the logo for the regular vs scrolled logo 
#main-nav-desktop-container {
  .logo-container {
    position: relative;
    top: -2px;

    .scrolled-logo {
      transition: opacity $transition-speed $transition-style;
      position: absolute;
      opacity: 0;
      top: 50%;
      transform: translateY(-50%);
      margin-top: 4px;
    }

    // Hide the new logos that are for baseV2
    .logo-secondary,
    .logo-secondary-variant {
      display: none;
    }
  }
}

//  When there is no hero on the page, the scrolling doesnt matter
body:not(.has-hero) {
  #main-nav-desktop-container {
    .logo-container {
      .scrolled-logo {
        opacity: 1;
      }
    }
  }
}

#header.scrolled {
  #main-nav-desktop-container {
    background: transparent;
    background-color: $white;

    #main-nav-desktop-container {
      padding-top: 12px;
    }

    .left-col,
    .right-col {
      padding-bottom: 13px !important;
    }

    .logo-container {
      .scrolled-logo {
        opacity: 1;
      }
    }

    #main-nav-desktop {
      > ul {
        /* First Level Items */
        > li {
          padding-bottom: 13px;

          /* Second Level Dropdown */
          > ul {
            &:before {
              top: -20px;
            }
          }
        }
      }
    }
  }
}
