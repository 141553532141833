// Press Release Detail Layout
#press-release-detail-page {
  .page-title-container {
    margin-bottom: 0;

    .title {
      padding-bottom: 40px;
    }

    &.with-hero {
      .title {
        @media (min-width: $breakpoint-lg-min) {
          top: -30px;
        }
      }
    }
  }

  .placeholder-container {
    padding-top: 0;
  }

  @media (min-width: $breakpoint-sm-max) {
    .secondary-date-container {
      display: none;
    }
  }

  @media (max-width: $breakpoint-md-min) {
    .main-date-container {
      margin-right: 12px;
      margin-bottom: 20px;

      .tag-label {
        display: none;
      }
    }
    .secondary-date-container {
      display: inline-block;
    }
  }

  img {
    max-width: 100%;
  }

  @media (max-width: $breakpoint-md-max) {
    .article-info {
      flex-direction: column-reverse;

      .main-date-container {
        margin-top: 20px;
        margin-bottom: 20px;

        .author {
          display: none;
        }
      }
    }
  }

  @media (min-width: $breakpoint-lg-min) {
    .details {
      .secondary-date-container {
        display: none;
      }
    }
  }

  .tertiary-date-container {
    display: none;
  }
  @media (max-width: $breakpoint-md-max) {
    .tag-global {
      display: none;
    }

    .tertiary-date-container {
      display: inline-block;
      margin-bottom: 20px;
      margin-right: 12px;

      .detail-date-container {
        margin-bottom: 0;
      }
    }
  }
}

body.has-hero {
  #press-release-detail-page {
    .page-title-container {
      margin-bottom: 0;
      .title {
        top: -30px;

        .back-link {
          a {
            color: $white;
          }
        }
      }
    }

    .details {
      margin-top: -25px;
    }
  }
}
// End Press Release Detail Layout
