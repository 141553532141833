@import '../../node_modules/bootstrap/scss/mixins/grid';

.promos-container{
  margin-bottom: $component-margin-bottom;

  @include make-row();

  .promo{
    @include make-col-ready();
    @include make-col(12);

    @media (min-width: $breakpoint-md-min) {

      display: flex;
      align-items: stretch;
      min-height: 464px;

      a {
        position: relative;
        overflow: hidden;
        flex: 1 0 100%;

        display: flex;
        justify-content: flex-end;
        flex-direction: column;

        background-color: $light-grey;
        color: $white;

        text-decoration: none;
        transition: box-shadow $transition-speed $transition-style;

        margin-bottom: 20px;

        img{
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          object-fit: cover;
          transition: transform $transition-speed $transition-style;
        }
        
        .content-container{
          z-index: 2;
          margin: 200px 32px 40px 32px;
          max-width: 600px;

          .summary{
            font-family: $font-family-primary;
            font-size: 16px;
          }
        }

        .image--disclaimer--sm {
          display: none;
        }

        .image--disclaimer--md {
          color: $font-color-dark-bg;
          font-size: 12px;

          display: flex;
          justify-content: flex-end;

          
          @media (max-width: $breakpoint-md-max) {
            position: relative;
            right: 15px;
            bottom: 15px;
          }

          @media (min-width: $breakpoint-lg-min) {
            position: absolute;
            bottom: 15px;
            right: 15px;
          }

          span {
            max-width: 200px;
          }
        }


        &:hover{
          box-shadow: 0 8px 16px rgba($black, 0.2);

          text-decoration: none;
          color: $white;

          img{
            transform: scale(1.1);
          }
        }
      }

      &.without-image{
        a{
          color: $black;

          &:hover{
            color: $black;
            h3{
              color: $blue;
            }
          }
        }
      }
    }

    @media (max-width: $breakpoint-sm-max) {

      a {
        flex: 1 0 100%;

        display: flex;
        justify-content: flex-end;
        flex-direction: column;

        color: $black;

        text-decoration: none;
        transition: box-shadow $transition-speed $transition-style;

        margin-bottom: 20px;

        .image-container{
          display: inline-block;
          position: relative;
          overflow: hidden;
          height: 248px;
          margin-bottom: 20px;

          img{
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            object-fit: cover;
            transition: transform $transition-speed $transition-style;
          }
        }

        .image--disclaimer--sm {
          color: $font-color-dark-bg;
          font-size: 12px;
          
          position: absolute;
          bottom: 10px;
          width: 100%;
          padding: 0 10px;
          text-align: right;
        }

        .image--disclaimer--md {
          display: none;
        }
      }
    }
  }

  &.has-multiple{
    .promo{
      @media (min-width: $breakpoint-md-min) {
        @include make-col(6);
      }

      @media (max-width: $breakpoint-sm-max) {

        &:not(:first-child){
          border-top: 1px solid $light-grey;
          padding-top: 40px;
          img{
            margin-bottom: 40px;
          }
        }
      }
    }
  }
}


// Background variations
.light-bg{
  .promos-container {
    .promo{
      @media (min-width: $breakpoint-md-min) {
        a {
          background-color: $white;
        }
      }
    }
  }
}

.dark-bg{
  .promos-container {
    .promo{
      @media (max-width: $breakpoint-sm-max) {
        a {
          color: $white;
        }
      }
    }
  }
}
// End Background variations


.is-edge{
  .promo{
    .image-container{
      outline: 1px solid pink;
      display: inline-block;
    }
  }
}