// Grid 
$grid-columns:      12;
$grid-gutter-width: 20px;

$grid-breakpoints: (
  // Small screen / phone
  sm: $breakpoint-sm-min,
  // Medium screen / tablet
  md: $breakpoint-md-min,
  // Large screen / desktop
  lg: $breakpoint-lg-min,  
);

$container-max-widths: (
  sm: $breakpoint-sm-max,
  md: $breakpoint-md-max,
  lg: $breakpoint-lg-max,
);

$font-size-base: 1.25rem;