@import "../../link/styles/base";

.table-container {
  margin-bottom: $component-margin-bottom;

  table {
    width: 100%;
    font-family: $font-family-primary-alt;

    thead {
      th {
        &.sortable {
          cursor: pointer;

          span {
            position: relative;
            padding-right: 20px;

            &:before,
            &:after {
              display: inline-block;
              font-family: $font-awesome-pro;
              font-size: 16px;
              position: absolute;
              right: 0;
            }

            &:before {
              bottom: 0.25em;
              margin-bottom: 1px;
              content: "\f0de";
            }

            &:after {
              bottom: 0.25em;
              content: "\f0dd";
            }
          }
        }
      }
    }

    tbody {
      th {
        font-weight: $weight-bold;
      }
    }

    th,
    td {
      padding: 28px;
      vertical-align: top;
      min-width: 225px;

      a {
        @extend .link;

        .fa-external-link {
          padding-left: 20px;
        }
      }
    }
  }

  &:before,
  &:after {
    content: "";
    position: absolute;
    top: 0;
    height: 100%;
    width: 80px;
    pointer-events: none;
    z-index: 2;

    // cant do a transition on a gradient background, so doing it on opacity
    transition: opacity $transition-speed $transition-style;
    opacity: 0;
  }

  &:before {
    left: 0;
    background: linear-gradient(90deg, rgba(black, 0.1), rgba(black, 0));
  }

  &:after {
    right: 0;
    background: linear-gradient(90deg, rgba(black, 0), rgba(black, 0.1));
  }

  &.has-left-shadow {
    &:before {
      opacity: 1;
    }
  }

  &.has-right-shadow {
    &:after {
      opacity: 1;
    }
  }

  &.default {
    table {
      thead {
        background-color: $black;

        th {
          color: $white;
          font-weight: $weight-semibold;

          &.sortable {
            span {
              &:before,
              &:after {
                color: rgba($white, 0.5);
              }
            }

            &.sort-asc {
              span {
                &:before {
                  color: $white;
                }
              }
            }

            &.sort-desc {
              span {
                &:after {
                  color: $white;
                }
              }
            }
          }
        }
      }

      tbody {
        th {
          font-weight: $weight-bold;
        }
      }

      tr {
        border-bottom: 1px solid $mid-grey;
      }

      &.striped-rows {
        tbody {
          tr:nth-child(even) {
            background-color: $light-grey;
          }
        }
      }

      &.striped-cols {
        tbody {
          tr {
            > *:nth-child(odd) {
              background: rgba($light-blue, 0.1);
            }
          }
        }
      }
    }
  }  
}


  // Background Variants
  .light-bg {
    .table-container.default {
      table {
        &.striped-rows {
          tbody {
            tr:nth-child(even) {
              background-color: $white;
            }
          }
        }
      }
    }
  } //.light-bg

  .dark-bg {
    .table-container.default {
      table {
        thead {
          background-color: $white;

          th {
            color: $black;

            &.sortable {
              span {
                &:before,
                &:after {
                  color: rgba($black, 0.5);
                }
              }

              &.sort-asc {
                span {
                  &:before {
                    color: $black;
                  }
                }
              }

              &.sort-desc {
                span {
                  &:after {
                    color: $black;
                  }
                }
              }
            }
          }
        }

        &.striped-rows {
          tbody {
            tr:nth-child(even) {
              color: $black;
            }
          }
        }
      }
    }
  } //.dark-bg

  // End Background Variants

// Wrapping Table
.wrapping-table {  
  font-family: $font-family-primary;

  .table-header{
    background-color: $white;

    .row {
      display: flex;
      padding: 6px 0;

      > div {
        font-size: 32px;
        font-weight: $weight-bold;
        white-space: nowrap;
        flex: 1;
        
        color: $black;

        &.sortable {
          cursor: pointer;

          span {
            position: relative;
            padding-right: 20px;

            &:before,
            &:after {
              display: inline-block;
              font-family: $font-awesome-pro;
              font-weight: 900;
              font-size: 16px;
              position: absolute;
              right: 0;
              color: rgba($blue, 0.5);
            }
            &:before {
              bottom: 0.25em;
              margin-bottom: 1px;
              content: "\f0de";
            }

            &:after {
              bottom: 0.25em;
              content: "\f0dd";
            }
          }
    
          &.sort-asc {
            span {
              &:before {
                color: $blue;
              }
            }
          }
    
          &.sort-desc {
            span {
              &:after {
                color: $blue;
              }
            }
          }
        }
      }
    }
  }

  .table-body {
    .background-container{
      border-bottom: 1px solid $border-grey;

      .row {
        display: flex;
        padding-top: 22px;
        padding-bottom: 22px;

        > div {
          vertical-align: text-top;
          flex: 1;

          a {
            font-weight: $weight-bold;
            text-decoration: none;
          }
        }
      }
    }
    
    .row-col-header {
      display: none;
      width: 50%;
    }
  }

  @media (max-width: $breakpoint-sm-max) {
    .table-header{
      .row > div {      
        // hide all table headers
        display: none;

        // except the first one
        &:first-child {
          display: block;

          // prevent the first column header from being sortable
          &.sortable {
            cursor: default;

            span {
              position: relative;
              padding-right: 0;

              &:before,
              &:after {
                display: none;
              }
            }
          }
        }
      }
    }

    .table-body {
      .row {
        display: flex;
        flex-direction: column;

        > div {
          padding: 0;

          .row-col-header {
            display: inline;
            font-weight: $weight-bold;
          }
        }
      }
    }
  }
}

.listing-no-results{
  font-size: 32px;
  font-weight: 700;
  padding-top: 80px;
  text-align: center;
}

// End Wrapping Table
