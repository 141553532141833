// Colors
$black:       #203243;
$black-hover: #00101F;
$light-grey:  #F7F7F8;
$grey:        #E5E5E5;
$mid-grey:    #A2A2A2;
$mid-grey-hover: #6F6F6F;
$dark-grey:   #586572;
$white:       #FFFFFF;
$white-hover: #FFFFFF;
$dark-blue:   #2C5697;
$blue:        #3E87CB;
$light-blue:  #7FADE3;
$light-blue-hover:  #F2F7FC;
$light-green: #C4D600;
$orange:      #FBA455;
$orange-hover:#EF7A0F;
$red:         #E34D4D;

// Section Colors
$light-bg-body-bg:  #F0F0F0;
$dark-bg-body-bg:   #1C2B3B;
$blue-bg:         #F1F6FC;
$border-grey: #E5E5E5;

// Breakpoints
$breakpoint-sm-min: 0;
$breakpoint-sm-max: 767px;
$breakpoint-md-min: 768px;
$breakpoint-md-max: 1024px;
$breakpoint-lg-min: 1025px;
$breakpoint-lg-max: 1520px;

// Grid
$grid-gutter-width: 10px;
$grid-gutter-width-sm: 8px;

// Fonts
$titling-gothic-skyline: 'titling-gothic-fb-skyline', sans-serif;
$myriad-pro: 'myriad-pro', sans-serif;
$myriad-pro-condensed: 'myriad-pro-condensed', sans-serif;
$font-awesome-pro: 'Font Awesome 5 Pro';

// Layout Dimensions
$header-height-sm: 70;
$header-height-md: 70;
$header-height-lg: 135;
$page-title-hero-height: 400;

// Defaults
$component-margin-bottom: 12px;

// Element dimensions
$eyebrow-nav-height: 30px;
$mobile-main-nav-menu-header-height: 69px;


/*****************************************/
/*********** Generic Variables ***********/
/*****************************************/

// Fonts
$font-family-primary: $myriad-pro;
$font-family-primary-alt: $myriad-pro-condensed;
$font-family-secondary: $titling-gothic-skyline;
$font-family-link: $myriad-pro-condensed;

// Font Weights
$weight-ultra-light: 100;
$weight-light:       300;
$weight-regular:     400;
$weight-medium:      500;
$weight-semibold:    600;
$weight-bold:        700;
$weight-extra-bold:  800;
$weight-black:       900;

// Colors
// $primary-color: 
// $primary-color-alt:
// $secondary-color:
// $secondary-color-alt:

$font-color: $black;
$font-color-hover: $black-hover;
$font-color-dark-bg: $white !default;
$font-color-dark-bg-hover: $white-hover !default;
$link-color: $blue;
$link-color-hover: $dark-blue;
$error: $red;

// Transition Values
$transition-speed: 0.8s;
$transition-style: ease;

// Z-index values
$z-index-page-title-hero: 1;
$z-index-page-title-text: 2;
$z-index-header-fixed: 4;
$z-index-page-alert: 3;
$z-index-video-img-overlay: 10;
$z-index-safety: 20;
$z-index-privacy: 1000;
$z-index-mobile-nav: 10000;
$z-index-country-selector:10001;
$z-index-modal-backdrop: 20000;
$z-index-modal: 20004;

// Generic Component Values
$inline-component-margin-right: 40px;
$inline-component-margin-bottom: 40px;