@import "../../node_modules/bootstrap/scss/mixins/buttons";

.page-alerts-modal {
  .modal {
    z-index: $z-index-modal;
    padding-left: 17px;
    padding-right: 17px;

    // @media (max-width: $breakpoint-sm-max) {
    //   margin-right: 17px;
    // }

    overflow: hidden;

    &.show {
      .modal-dialog {
        min-height: 50%;
        max-height: 70%;
        top: 50%;
        transform: translateY(-50%);
        margin-top: 0;
      }
    }
  }

  .modal-content {
    box-shadow: 0 8px 16px rgba($black, 0.2);
    padding-bottom: 40px;

    .modal-header {
      flex-direction: column;
      align-items: center;
      border-bottom: none;
      padding: 40px 40px 0 40px;

      @media (max-width: $breakpoint-sm-max) {
        padding: 20px 5px 0 5px;
        overflow-wrap: break-word;
      }

      .modal-header-image {
        img {
          max-width: 100%;
        }
      }

      .modal-title {
        flex: 1 1 auto;
        text-align: center;
        font-family: $font-family-secondary;
        font-size: 44px;
        letter-spacing: 0.01em;
        width: 100%;
      }
    }

    .modal-body {
      padding: 20px 40px 0 40px;
      margin-bottom: 0;

      .modal-body-scroll {
        max-height: calc(100vh - 450px);
        // min-height: 50px;
        padding-right: 10px;
      }
      .modal-body-link {
        text-align: center;
        padding: 10px 0;
      }
      @media (max-width: $breakpoint-sm-max) {
        padding: 10px 20px 0 20px;
        // min-height: 2em;
      }
    }

    .modal-footer {
      display: none;
      border-top: none;
      padding: 20px 40px 0 40px;
      text-align: right;
      flex-direction: row;
      flex-wrap: nowrap;

      .btn {
        margin-left: 40px;
        margin-bottom: 0 !important;
        margin-right: 0px;

        &:first-child {
          margin-left: 0;
        }

        color: $white;
        &:hover {
          text-decoration: none;
        }
      }

      .btn-deny {
        @extend .btn-secondary;
      }

      .btn-access {
        @extend .btn-primary;
        margin-right: 0;
        margin-bottom: 0;
      }

      @media (max-width: $breakpoint-sm-max) {
        padding-left: 20px;
        padding-right: 20px;

        // On small screen, stack buttons
        flex-direction: column;

        .btn {
          margin: 20px 0 0 0;

          &:first-child {
            margin-top: 0;
          }
        }
      }
    }

    .additional-modals {
      position: absolute;
      top: 100%;
      width: 100%;

      .additional-modal-1,
      .additional-modal-2 {
        height: 20px;
        margin: 0 auto;
        border-radius: 0 0 0.3rem 0.3rem;
      }

      .additional-modal-1 {
        width: 80%;
        opacity: 0.8;
      }
      .additional-modal-2 {
        width: 60%;
        opacity: 0.6;
      }
    }
  }

  .modal {
    &.is-access-deny {
      .modal-header {
        .close {
          display: none;
        }
      }
      .modal-footer {
        display: flex;
      }
    }

    &.abbvie {
      height: calc(100% - 30px);

      $font-color: $white;
      $background-color: #071d49;
      $link-color: #2f9ece;

      .modal-dialog {
        @media (max-width: $breakpoint-sm-max) {
          // horizontally center the modal on small screens.
          // its off becuase of the close X being outside the modal
          padding-left: 30px;
          padding-right: 30px;
        }
      }

      .modal-header {
        padding: 72px 72px 0 72px;
        @media (max-width: $breakpoint-sm-max) {
          padding: 24px 24px 0 24px;
        }
        .close {
          position: absolute;
          right: -25px;
          top: -30px;
          color: $white;
        }
      }

      .modal-content {
        color: $font-color;
        background-color: $background-color;
        padding-bottom: 72px;
        @media (max-width: $breakpoint-sm-max) {
          padding-bottom: 24px;
        }
        .modal-body {
          padding: 36px 72px 0 72px;
          text-align: center;
          @media (max-width: $breakpoint-sm-max) {
            padding: 12px 24px 0 24px;
          }
        }

        a {
          color: $link-color;

          &:hover {
            color: $link-color;
            text-decoration: none;
          }
        }

        .modal-footer {
          .btn-access {
            @include button-variant(
              $font-color,
              $font-color,
              $link-color,
              $link-color,
              $font-color,
              $font-color
            );
            color: $dark-blue;

            &:hover {
              color: $font-color;
            }
          }

          .btn-deny {
            @include button-variant(
              rgba($font-color, 0.2),
              transparent,
              rgba($font-color, 0.5)
            );
            color: $white;
          }
        }
      }

      .additional-modals {
        .additional-modal-1,
        .additional-modal-2 {
          background-color: $background-color;
        }
      }
    } // .abbvie

    &.message {
      .modal-content {
        border-radius: 0 24px 24px 24px;
      }

      .modal-header {
        .close {
          position: absolute;
          right: 15px;
          top: 15px;
          color: $black;
        }
      }

      .additional-modals {
        .additional-modal-1,
        .additional-modal-2 {
          background-color: $white;
          border-radius: 0 0 24px 24px;
          border: 1px solid rgba($black, 0.2);
        }
      }
    } // .message

    &.warning {
      .modal-content {
        border-radius: 0 24px 24px 24px;
      }

      .modal-header {
        color: $red;
        border-top: 20px solid $red;
        border-radius: 0 20px 0 20px;
        text-transform: uppercase;
        margin-top: -1px;
        .close {
          position: absolute;
          right: 15px;
          top: 15px;
          color: $black;
        }
      }

      .additional-modals {
        .additional-modal-1,
        .additional-modal-2 {
          background-color: $white;
          border-radius: 0 0 24px 24px;
          border: 1px solid rgba($black, 0.2);
        }
      }
    } // .warning
  } // .modal
} // #modal-notice

.modal-backdrop {
  z-index: $z-index-modal-backdrop;

  &.show {
    opacity: 0.85;
  }
}

.is-ios {
  .modal {
    padding-right: 17px;
  }
}

.page-alert-banner {
  line-height: 40px;
  font-family: $font-family-primary-alt;

  .col-12{
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-top: 12px;
      padding-bottom: 12px;
      color: $white;
      @media (max-width: $breakpoint-sm-max){
        display: block;
      }
  }

  .banner__title {
    font-size: 24px;
  }

  .banner__link {
    font-size: 20px;
    padding-right: 20px;
    line-height: 28px;
    @media (max-width: $breakpoint-sm-max){
     text-align: left;
     min-width: 200%; 
    }
    a {    
      span {
        padding-right: 10px;
      }
    }
  }

  .close {
    font-size: 1em;
    color: $white;
    opacity: 1;
    @media (max-width: $breakpoint-sm-max){
      position: absolute;
      right: 0;
      bottom: 40%;
    }
    &:hover {
      color: $white;
    }
  }

  a {
    color: $white;
    &:hover {
      color: $white;
      text-decoration: none;
    }
  }

  .banner-right{
    display: flex;
    align-items: normal;
    max-width: 450px;
    text-align: right;

    @media (max-width: $breakpoint-sm-max) {
      max-width: 50%;
    }
  }

  
  &.abbvie {
    background-color: #071d49;
  } // .abbvie

  &.message {
    background-color: $mid-grey;
  } // .message

  &.warning {
    background-color: $red;
  }
  // .warning
}