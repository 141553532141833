
.paginator-container{

  .page-navigation-container{
    display: flex;
    justify-content: center;
    margin-bottom: 20px;

    .btn-first,
    .btn-last{
      @extend .btn;
      @include button-variant($white, $light-grey, $white, $light-grey);
      color: $blue;
      cursor: pointer;

      position: relative;

      box-shadow: 0 8px 16px rgba($black,.1);
      height: 60px;
      font-family: $font-family-primary-alt;
      font-size: 20px;
      line-height: 45px;
      text-decoration: none;
      text-align: left;

      padding-left: 28px;
      padding-right: 28px;

      @media (max-width: $breakpoint-sm-max) {
        padding-left: 12px;
        padding-right: 12px;
      }

      &:hover{
        text-decoration: none;
      }

      &.disabled{
        color: $blue;
      }
    }

    .btn-first{
      border-radius: 30px 0 30px 30px;
    }
    .btn-last{
      border-radius: 0 30px 30px 30px;
    }

    .pages{
      color: $blue;

      border-radius: 30px 30px 30px 30px;
      box-shadow: 0 8px 16px rgba($black,.1);
      padding-left: 14px;
      padding-right: 14px;
      font-family: $font-family-primary-alt;
      font-size: 20px;
      height: 60px;
      text-decoration: none;

      margin-left: 14px;
      margin-right: 14px;
      @media (max-width: $breakpoint-sm-max) {
        margin-left: 10px;
        margin-right: 10px;
      }


      display: flex;

      .prev-group{
        margin-right: 12px;
      }

      .next-group{
        margin-left: 12px;
        margin-right: 0;
      }

      .prev-group,
      .prev-page,
      .next-page,
      .next-group{
        font-size: 20px;
        text-transform: uppercase;
      }
      .page-nums{
        display: flex;
        margin-left: 20px;
        margin-right: 20px;
        @media (max-width: $breakpoint-sm-max) {
          margin-left: 15px;
          margin-right: 15px;
        }

        .page-num{
          flex-grow: 1;
          flex-shrink: 1;
          flex-basis: auto;
          font-size: 16px;
          margin-left: 12px;

          &:first-child{
            margin-left: 0;
          }

          &.active{
            color: $black;
          }
        }
      }

      &.no-nums .prev-page{
          margin-right: 36px;
      }
    }

    .disabled{
      cursor: not-allowed;
      opacity: .3;
    }
  }

  .page-count-container{
    text-align: center;

    font-family: $font-family-primary-alt;
    font-weight: $weight-bold;

    .current-group{
      color: $blue;
    }
  }
}
