@media (min-width: $breakpoint-lg-min) {
  body {
    #header {
      position: relative;
      z-index: $z-index-header-fixed;

      &:not(.in-page) {
        position: fixed;
        top: 0;
        right: 0;
        left: 0;
      }   
    }
  }
}
