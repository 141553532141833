.recent-events-grid-container,
.upcoming-events-grid-container {
  margin-bottom: $component-margin-bottom;

  > h4 {
    position: relative;

    .action-container {
      .view-all {
        vertical-align: text-top;

        i {
          padding-left: 20px;
        }
      }
    }
  }

  .event-item-container {
    margin-bottom: 20px;

    .event-item {
      height: 100%;

      .event-day-of-week,
      .event-date {
        text-transform: uppercase;
        font-size: 20px;
        font-family: $font-family-primary-alt;
        font-weight: $weight-semibold;
        
        display: inline-block;

        @media (min-width: $breakpoint-md-min) and (max-width: $breakpoint-md-max) {
          display: block;
        }
      }

      .event-type {
        font-family: $font-family-primary-alt;
        font-weight: $weight-semibold;
        text-transform: uppercase;
        color: $orange;
      }

      .event-date {
        padding-bottom: 16px;
      }

      h4 {
        padding-bottom: 60px;

        a {
          text-decoration: none;
        }
      }

      .link-list {
        width: 100%;
        margin-right: 0;

        ul {
          width: 100%;
          margin-bottom: 0;

          li{
            .link.with-icon .text{
              padding-right: 0;
            }

            &:last-child {
              padding-bottom: 0;
            }
          }

         
        }
      }

      &.has-detail {
        a {
          padding: 35px 30px;
        }
      }

      &:not(.has-detail) {
        padding: 35px 30px;
      }

      &.has-image {
        position: relative;
        display: flex;
        justify-content: flex-end;
        flex-direction: column;

        .event-img {
          overflow: hidden;
          position: absolute;
          z-index: 1;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;

          img {
            object-fit: cover;
            width: 100%;
            height: 100%;
            transition: transform 200ms ease-out;
          }
        }

        .event-day-of-week,
        .event-date,
        .event-type,
        .event-title,
        .link-list {
          position: relative;
          z-index: 2;
          color: $white;
        }

        .link-list {
          ul li.link-section-title {
            color: $white;
          }
        }

        &:not(.has-detail) {
          a {
            color: $white;
            transition: color 160ms;

            &:hover {
              color: rgba($white, 0.75);
            }
          }

          > *:last-child {
            padding-bottom: 0;
            margin-bottom: 0;
          }
        }

        &.has-detail {
          a {
            > *:last-child {
              padding-bottom: 0;
              margin-bottom: 0;
            }

            &:hover {
              text-decoration: none;

              .event-img img {
                transform: scale(1.1);
              }
            }
          }
        }
      }

      &:not(.has-image) {
        &.has-detail {
          transition: background 160ms;

          a {
            display: block;
            height: 100%;
            width: 100%;

            .event-day-of-week,
            .event-date,
            .event-type,
            .event-title {
              transition: color 160ms;
            }

            &:hover {
              background: $blue;
              text-decoration: none;

              .event-day-of-week,
              .event-date,
              .event-type,
              .event-title {
                color: $white !important;
              }
            }
          }
        }
      }
    }
  }
}

// Event Item Backgrounds
.recent-events-grid-container,
.upcoming-events-grid-container {
  .event-item-container {
    .event-item {
      .event-day-of-week,
      .event-date {
        color: $black;
      }
    }

    // on small, all backgrounds are grey
    .event-item {
      background: $light-grey;
    }

    // large, alternate backgrounds
    @media (min-width: $breakpoint-lg-min) {
      &:nth-child(even) {
        .event-item {
          background: $white;
        }
      }
    }

    // medium, alternate backgrounds per row. this class is added by eventsGrid.js
    @media (min-width: $breakpoint-md-min) and (max-width: $breakpoint-md-max) {
      .event-item {
        background: $light-grey;

        &.two-col-has-bg {
          background: $white;
        }
      }
    }
  }
}
// End Event Item Backgrounds

// Event Column Widths
.recent-events-grid-container,
.upcoming-events-grid-container {
  .events-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-left: -1%;
    

    // By default, events are 100% width
    .event-item-container {
      padding-left: 1%;

      flex-grow: 0;
      flex-shrink: 0;
      flex-basis: 100%;
    }
  }

  // When 2 events, on md and lg screens, events are 50% width
  @media (min-width: $breakpoint-md-min) {
    &.two-event-cols {
      .event-item-container {
        flex-basis: 49%;
      }
    }
  }

  // When 3 events, on md and lg screens, events are 33% width
  @media (min-width: $breakpoint-md-min) {
    &.three-event-cols {
      .event-item-container {
        flex-basis: 32%;
      }
    }
  }
}
// End Event Column Widths

/*
.upcoming-events-grid-container {
  .event-item {
    .event-header {
      > div {
        padding-right: 0;
        padding-left: 0;
      }

      .event-day-of-week,
      .event-date {
        display: inline-block;

        @media (min-width: $breakpoint-md-min) and (max-width: $breakpoint-md-max) {
          display: block;
        }
      }

      @media (max-width: $breakpoint-sm-max) {
        flex-direction: column-reverse;

        .event-date {
          padding-bottom: 7px;
        }
      }
      
    }
  }
}
*/

.recent-events-list-container,
.upcoming-events-list-container {
  margin-bottom: $component-margin-bottom;

  > h4 {
    position: relative;

    .action-container {
      .view-all {
        vertical-align: text-top;

        i {
          padding-left: 20px;
        }
      }
    }
  }

  .event-item {
    margin-top: 40px;

    .event-date-container {
      display: flex;
      min-width: 200px;
      margin-right: 25px;

      .event-day-of-week,
      .event-date {
        text-transform: uppercase;
        font-size: 20px;
        font-family: $font-family-primary-alt;
        font-weight: $weight-semibold;
        color: $black;
        padding-right: 5px;
      }
    }

    .event-type {
      font-family: $font-family-primary-alt;
      font-weight: $weight-semibold;
      text-transform: uppercase;
      color: $orange;
    }

    h4 {
      a {
        text-decoration: none;
      }
    }
  }
}

.recent-events-list-container {
  .event-item {
    .event-link {
      display: inline-block;
    }
  }
}

.upcoming-events-list-container {
  .event-item {
    .event-links {
      .list-inline-item {
        margin-right: 60px;

        .dropdown-items {
          .link {
            display: flex;
            justify-content: space-between;
            align-items: center;

            font-family: $font-family-primary;
            font-size: 16px;

            i {
              width: 20px;
              text-align: left;
            }
          }
        }
      }
    }
  }
}

.upcoming-events-grid-container {
  .event-day-of-week,
  .event-date {
    color: $mid-grey;
  }

  .dropdown-items {
    .link {
      display: flex;
      justify-content: space-between;
      align-items: center;

      font-family: $font-family-primary;
      font-size: 16px;

      i {
        width: 20px;
        text-align: left;
      }
    }
  }
}

// Event Background Variations

.light-bg {
  .recent-events-grid-container {
  }
} // .light-bg

.dark-bg {
  .upcoming-events-grid-container,
  .recent-events-grid-container {
    .event-item {
      background: $white !important;
      color: $black;
    }
  }

  .upcoming-events-list-container,
  .recent-events-list-container {
    .event-item {
      .event-day-of-week,
      .event-date {
        color: $white;
      }
    }
  }
} // .dark-bg

// End Event Background Variations

// Event Listing Layout
#event-listing-page {
  .page-title-container {
    text-align: center;

    .rich-text {
      padding-top: 10px;
    }

    &.with-hero {
      color: $white;
    }
  }

  // Event Listing Filters
  #event-list-filters {
    padding-top: 24px;
    position: relative;
    z-index: 2;

    .dropdown-item {
      color: $blue;

      &.selected {
        color: $black;
      }
    }

    @media (max-width: $breakpoint-md-max) {
      flex-direction: column;

      .dropdown.filter,
      .input-group.filter-group {
        margin-bottom: 24px;
        margin-right: 0;
      }
    }
  }
  // End Event Listing Filters

  // Event Listing List
  #event-listing-list-container {
   
    #event-listing-list {
      margin-top: 80px;

      @media (min-width: $breakpoint-md-min) {
        &.has-results{
          border-left: 1px solid $blue;
          padding-left: 20px;
          margin-left: 5px;
        }
      }

      .event-item {
        position: relative;
        padding-top: 24px;
        border-radius: 0 0 30px 0;
        margin-bottom: 80px;

        > a {
          width: 100%;

          &:hover {
            text-decoration: none;
          }
        }

        .event-detail-link {
          color: $white;
        }

        .detail-date-container {
          position: absolute;
          z-index: 1;
          top: 0;
          left: 0;
          transform: translate(-55px, -50%);
          font-size: 16px;

          @media (max-width: $breakpoint-sm-max) {
            transform: translate(0, -50%);
            padding-left: 5px;
            padding-right: 15px;

            span {
              margin-left: 5px;
              padding-left: 5px;
            }
          }
        }

        .event-img {
          border-radius: 0 0 30px 0;
          position: relative;
          width: 100%;
          left: 0;

          img {
            object-fit: cover;
            position: absolute;
            width: 100%;
            height: 100%;
            transition: transform 200ms ease-out;
          }
        }

        .event-text-container {
          padding: 40px 32px 44px;
          position: relative;
          z-index: 1;
          width: 100%;

          .event-type {
            font-family: $font-family-primary-alt;
            font-weight: $weight-semibold;
            text-transform: uppercase;
            color: $orange;
          }
        }

        transition: box-shadow 160ms;

        &:hover {
          box-shadow: 0 2px 12px rgba(0, 0, 0, 0.2);
        }

        &:not(.has-image) {
          background: $light-grey;

          &.has-detail {
            transition: background 160ms;

            .event-type {
              transition: color 160ms;
            }

            a {
              color: $blue;
              transition: color 160ms;
            }

            &:hover {
              background: $blue;

              .event-type {
                color: $white;
              }

              a {
                color: $white;
              }
            }
          }
        }

        &.has-image {
          align-items: flex-end;
          display: flex;
          min-height: 380px;

          .event-text-container {
            padding-right: 100px;
            position: relative;
            color: $white;

            &:before {
              background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);
              background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);
              background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);
              filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#000000',GradientType=0 );
              border-radius: 0 0 30px 0;
              content: "";
              opacity: 0.75;
              position: absolute;
              bottom: 0;
              left: 0;
              height: 80%;
              width: 100%;
              z-index: -1;
            }

            .event-type {
              color: $white;
            }
          }

          .event-img {
            overflow: hidden;
            position: absolute;
            top: 0;
            height: 100%;
          }

          &:after {
            content: "";
            min-height: inherit;
            font-size: 0;
          }

          &:not(.has-detail) {
            a {
              color: $white;
              transition: color 160ms;

              &:hover {
                color: rgba($white, 0.75);
              }
            }
          }

          &.has-detail {
            &:hover {
              .event-img img {
                transform: scale(1.1);
              }
            }
          }
        }

        &:last-child:before {
          background: #fff;
          content: "";
          height: 100%;
          width: 2px;
          position: absolute;
          left: -21px;
          top: 0;
        }
      }

      .no-results {
        font-size: 32px;
        font-weight: $weight-bold;
        text-align: center;
      }

      .error {
        font-size: 32px;
        font-weight: $weight-bold;
        color: $red;

        padding-top: 80px;
        padding-left: 40px;
      }
    }
  }
  // End Event Listing List
}
// End Event Listing Layout

// Event Detail Layout
#event-detail-page {
  .page-title-container {
    margin-bottom: 0;

    .event-type {
      font-family: $font-family-primary-alt;
      font-weight: $weight-semibold;
      text-transform: uppercase;
      color: $orange;
      padding-bottom: 5px;
    }

    &.with-hero {
      .event-type {
        color: $white;
      }
    }

    h1 {
      padding-bottom: 25px;
    }
  }

  .page-content {
    margin-top: -70px;
  }

  .link-col {
    .link-list {
      //margin-top: 25px;
      margin-top: 113px;

      border-left: 1px solid $mid-grey;
      padding-left: 20px;

      ul {
        margin-bottom: 0;

        li {
          &:last-child {
            padding-bottom: 0;
          }
        }
      }
    }
  }
}
// End Event Detail Layout
