#news-listing-page {
  .page-title-container {
    background: linear-gradient(180deg, $white, $light-grey);

    h1,
    .summary {
      text-align: center;
    }

    margin-bottom: 0;
  }

  #news-list-content {
    position: relative;

    .bg-gradient {
      position: absolute;
      top: 0;
      left: 0;
      height: 140px;
      width: 100%;
      background: linear-gradient(180deg, $light-grey, $white);
    }

    #news-list-filters {
      position: relative;
      z-index: 2;

      .col-12 {
        flex-wrap: wrap;

        .filter {
          margin-bottom: 20px;
        }

        .dropdown.filter {
          width: auto;
        }

        .btn-group-toggle {
          margin-right: 20px;
          
        }

        .filter-group {
          min-width: 200px;
          height: 60px;
        }
      }
    }
  }

  .loading-container {
    &.loading {
      &::before {
        background-color: $white;
      }
    }
  }
}

#news-listing-list {
  margin-top: 60px;

  @media (min-width: $breakpoint-md-min) {
    &.has-results {
      border-left: 1px solid $blue;
      padding-left: 20px;
      margin-left: 5px;
    }
  }

  .news-item {
    position: relative;
    padding-top: 24px;
    border-radius: 0 0 30px 0;
    margin-bottom: 80px;

    a {
      color: $black;
      text-decoration: none;
      width: 100%;
    }

    .detail-date-container {
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
      transform: translate(-55px, -50%);
    }

    .news-img {
      border-radius: 0 0 30px 0;
      position: relative;

      img {
        object-fit: cover;
        position: absolute;
        width: 100%;
        height: 100%;
        transition: transform 200ms ease-out;
      }
    }

    .no-image-spacer {
      height: 44px;
    }

    .news-text-container {
      padding: 40px 32px 44px;
      position: relative;
      z-index: 1;
    }

    .news-title {
      color: $blue;
    }

    .author-name {
      color: $blue;
      font-family: $myriad-pro-condensed;
    }

    .news-summary {
      font-size: 14px;
      line-height: 1.6;
      margin-bottom: 0;
    }

    &.press-release,
    &.exec-blog {
      background: $light-grey;
      transition: background 160ms, box-shadow 160ms;

      a,
      .news-title,
      .author-name {
        font-family: $font-family-primary;
        font-weight: $weight-bold;
        transition: color 160ms;
      }

      .news-summary{
        font-family: $font-family-primary;
        font-weight: $weight-regular;
        font-size: 20px;
      }
      

      &:hover {
        background: $blue;
        box-shadow: 0 2px 12px rgba(0, 0, 0, 0.2);

        a,
        .news-title,
        .author-name {
          color: #fff;
        }
      }
    }

    &.article,
    &.exec-blog {
      .news-img {
        overflow: hidden;
        position: absolute;
        top: 0;
        height: 100%;
      }
    }

    &.press-release {
      .news-img {
        display: none;
      }
    }

    &.article {
      align-items: flex-end;
      display: flex;
      min-height: 380px;
      transition: box-shadow 200ms ease-out;

      a,
      .news-title {
        color: #fff;
      }

      .news-img {
        width: 100%;
        left: 0;
      }

      .news-text-container {
        padding-right: 100px;
        position: relative;

        &:before {
          background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);
          background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);
          background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);
          filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#000000',GradientType=0 );
          border-radius: 0 0 30px 0;
          content: "";
          opacity: 0.75;
          position: absolute;
          bottom: 0;
          left: 0;
          height: 80%;
          width: 100%;
          z-index: -1;
        }
      }

      &:after {
        content: "";
        min-height: inherit;
        font-size: 0;
      }

      &:hover {
        box-shadow: 0 2px 12px rgba(0, 0, 0, 0.2);

        .news-img img {
          transform: scale(1.1);
        }
      }
    }

    &.exec-blog {
      min-height: 240px;

      .news-text-container {
        padding-right: 300px;
      }

      .news-img {
        right: 0;
        width: 280px;
      }
    }

    &:last-child:before {
      background: #fff;
      content: "";
      height: 100%;
      width: 2px;
      position: absolute;
      left: -21px;
      top: 0;
    }
  }

  .no-results {
    font-size: 32px;
    font-weight: $weight-bold;
    text-align: center;
  }

  .error {
    font-size: 32px;
    font-weight: $weight-bold;
    color: $red;

    padding-top: 80px;
    padding-left: 40px;
  }

  // Small screen
  @media (max-width: $breakpoint-sm-max) {
    border-left: 0;
    padding-left: 0;
    margin-left: 0;

    .news-item {
      .detail-date-container {
        transform: translate(0, -50%);
      }

      &.article,
      &.exec-blog {
        padding-top: 0;

        .news-img {
          border-radius: 0;
          position: relative;
          height: 380px;
          width: 100%;
        }

        .news-text-container {
          padding-top: 20px;
          padding-right: 24px;
        }
      }

      &.article {
        background: $light-grey;

        a {
          color: $black;
        }

        .news-title {
          color: $blue;
        }

        .news-text-container:before {
          display: none;
        }
      }

      &:last-child:before {
        display: none;
      }
    }
  }
}

.featured-news-promo {
  min-height: 464px;
  margin-bottom: $component-margin-bottom;

  .featured-article {
    @media (max-width: $breakpoint-sm-max) {
      padding-bottom: 40px;
      border-bottom: 1px solid $border-grey;

      a {
        text-decoration: none;

        img {
          width: 100%;
        }

        .article-details {
          .article-date {
            font-family: $font-family-secondary;
            text-transform: uppercase;
            color: $mid-grey;
            font-size: 16px;
            display: inline;
            padding-right: 24px;
            letter-spacing: 0.1em;
          }

          .featured-title {
            font-family: $font-family-secondary;
            text-transform: uppercase;
            color: $orange;
            font-size: 16px;
            display: inline;
            letter-spacing: 0.01em;
          }

          .article-title {
            color: $black;
          }

          .article-summary {
            color: $black;
          }
        }
      }
    } // @media (max-width: $breakpoint-sm-max)

    @media (min-width: $breakpoint-md-min) {
      min-height: 464px;
      padding-right: 40px;

      a {
        position: relative;
        display: block;
        height: 100%;
        width: 100%;
        overflow: hidden;

        color: $white;
        background-color: $mid-grey;
        text-decoration: none;
        transition: box-shadow $transition-speed $transition-style;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          position: absolute;
          transition: transform $transition-speed $transition-style;
          z-index: 1;
        }

        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .article-details {
          position: relative;
          z-index: 2;

          flex: 1;
          flex-direction: column;
          justify-content: flex-end;
          display: flex;
          background: linear-gradient(rgba($black, 0), rgba($black, 0.2));

          padding-bottom: 40px;
          padding-left: 32px;
          padding-right: 32px;

          .featured-title {
            position: absolute;
            top: 32px;
            left: 32px;
            letter-spacing: 0.1em;
          }

          .article-date,
          .article-title,
          .article-summary {
            max-width: 670px;
          }

          .article-date {
            text-transform: uppercase;
            font-size: 16px;
            letter-spacing: 0.1em;
          }
          .article-title {
          }

          .article-summary {
            font-size: 14px;
          }
        }

        &:hover {
          box-shadow: 0 8px 16px rgba($black, 0.2);

          img {
            transform: scale(1.1);
          }
        }
      }
    } // @media (min-width: $breakpoint-md-min)
  } // .featured-article

  .featured-press-releases {
    padding-top: 32px;

    .featured-press-releases-header {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;

      @media (min-width: $breakpoint-lg-min) {
        flex-direction: row;
        align-items: flex-end;
      }

      h4 {
        margin-bottom: 0;
        flex: 1 0 auto;
        padding-right: 10px;
      }
    }

    @media (min-width: $breakpoint-md-min) {
      padding-left: 0;
    }

    .featured-press-release {
      padding-top: 20px;

      .details {
        font-family: $font-family-secondary;
        text-transform: uppercase;
        color: $mid-grey;
        font-size: 16px;
        letter-spacing: 0.12em;
      }
      .summary {
        font-weight: $weight-light;
        font-size: 20px;
      }
    }
  }
} // .featured-new-promo

// Background variations
.light-bg {
  .featured-news-promo {
    .featured-press-releases {
      .featured-press-release {
        .date {
          color: $black;
        }
      }
    }
  }
}

.dark-bg {
  .featured-news-promo {
    .featured-press-releases {
      .featured-press-release {
        .date {
          color: $white;
        }
      }
    }
  }
}
// End Background variations
