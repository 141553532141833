.flex-table {
    margin: 64px auto;

    a {
        text-decoration: none;
    }

    p {
        margin-bottom: 0;
    }

    .flex-table-title {
        color: $blue;
    }

    .flex-table-note {
        color: $mid-grey;
        margin-bottom: 0;
    }

    .row:not(.header-row) {
        align-items: center;
        border-bottom: 1px solid $border-grey;
        padding: 12px 0;

        [class^="col-"] {
            margin-top: 10px;
            margin-bottom: 10px;
        }

        .link {
            display: inline-block;
            margin: 4px 20px 4px 0;

            &.with-icon .text {
                padding-right: 8px;
            }

        }

    }

    @media (max-width: $breakpoint-sm-max) {

        .header-row {
            display: none;
        }

        .mobile-bold {
            font-weight: $weight-bold;
        }

    }

}

.stock-quote {
    align-items: flex-start;
    display: flex;

    .quote,
    .trend-container {
        display: flex;
        justify-content: center;
    }

    .quote {
        align-items: flex-end;
        margin-right: 120px;
    }

    .trend-container {
        align-items: center;
    }

    .quote-label {
        font-family: $font-family-secondary;
        font-size: 44px;
        font-weight: $weight-medium;
        margin-right: 16px;
    }

    .quote-price,
    .trend-num {
        font-size: 52px;
        font-weight: $weight-light;
        letter-spacing: -.05em;
    }

    .trend {
        align-items: flex-end;
        display: flex;
        flex-direction: column;
        margin-left: 16px;
    }

    .trend-num,
    .trend-time {
        color: $light-green;
    }

    .trend-time {
        font-size: 12px;
        margin-top: -5px;
    }

}
