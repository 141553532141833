.social-container {
  position: relative;
  margin-bottom: $component-margin-bottom;

  .content-container {
    .content{
      display: flex;
  
      .media {
        /* Adding a min height and explicitly defining the height of the media class due to a known IE11 bug. https://stackoverflow.com/questions/43979702/display-flex-not-working-on-internet-explorer/43979973 */
        min-height:0;
        height:100%;
        &.paused {
          position: relative;
          cursor: pointer;
          align-items: center;
          
 
          &::after {
            position: absolute;
            z-index: 1;
            top: 50%;
            left: 50%;
            transform: translate(-55%, -50%);
            pointer-events: none;

            font-family: $font-awesome-pro;
            content: "\f04b";
            font-weight: bold;
            
            font-size: 48px;
            margin-left: 7px;
            transition: color 120ms, background 120ms, border-color 120ms;

            border-width: 1px;
            border-style: solid;
            border-radius: 100%;
            padding: 25px 30px 22px 42px;
            box-shadow: 0 2px 8px rgba($black, 0.25);

            border-color: $border-grey;
            color: $blue;
            background-color: $white;
          }

          &:hover {
            &::after {
              background: $blue;
              border-color: $blue;
              color: $white;
            }
          }
        }

        img,
        video {
          width: 100%;
        }

        video {
          cursor: pointer;
        }
      }
      .details {
        flex: 1 1 auto;
        padding: 32px 25px;
        word-break: break-word;

        .date {
          font-family: $font-family-secondary;
          font-size: 20px;
          font-weight: $weight-medium;
          text-transform: uppercase;
          letter-spacing: .075em;

          a {
            text-decoration: none;
            &:hover {
              text-decoration: none;
            }

            i{
              padding-right: 10px;
            }
          }
        }
        .post {
        }
      }
    }
  }

  &.sm {
    .content-container {
      .content {
        flex-direction: column;
        max-width: 300px;
        margin: 0 auto;

        .media {
          width: 100%;
        }
      }
    }
  }

  &.md {
    .content-container {
      .content {
        flex-direction: row;

        .media,
        .details {
          min-width: 300px;
        }
      }
    }
  }

  &.lg {
    .content-container {
      max-width: 70%;

      .content {
        flex-direction: row;

        .media,
        .details {
          min-width: 300px;
        }
      }
    }
  }
}



// Background variations
.light-bg{
  .social-container {
    .content{
      background-color: $white;     
    }
  }
}

.dark-bg{
  .social-container {
    .content-container{
      color: $black;
    }
  }
}
// End Background variations