@import "plyr/dist/plyr";

.plyr--audio .plyr__control.plyr__tab-focus,
.plyr--audio .plyr__control:hover,
.plyr--audio .plyr__control[aria-expanded="true"],
.plyr--video .plyr__control.plyr__tab-focus,
.plyr--video .plyr__control:hover,
.plyr--video .plyr__control[aria-expanded="true"] {
  background: #fff;
  color: $blue;
  border-radius: 100%;
  box-shadow: 0 0 8px rgba($black, 0.2);
}

.plyr__control.plyr__tab-focus {
  box-shadow: 0 0 8px rgba($black, 0.3);
}

.plyr__progress__buffer {
  height: 4px;
}

.plyr--full-ui input[type="range"] {
  color: $blue;
  height: 4px;
}

.plyr--video .plyr__controls {
  background: linear-gradient(transparent, rgba($black, 0.7));
  padding: 20px 40px;
}

.plyr__controls .plyr__controls__item.plyr__progress__container {
  padding-left: 12px;
}

.plyr__controls .plyr__controls__item.plyr__volume {
  max-width: 36px;
}

.plyr__controls .plyr__controls__item {
  margin-left: 12px;
}

.video-player-container {
  margin-bottom: 40px;
  position: relative;

  .custom-controls {
    align-items: center;
    background: rgba($black, 0.08);
    box-shadow: 0 0 6px rgba($black, 0.16);
    border: 1px solid #fff;
    border-radius: 999px;
    display: flex;
    justify-content: space-between;
    padding: 0 10px;
    position: absolute;
    top: 20px;
    right: 40px;
    transition: background 100ms ease;
    z-index: 2;

    .vid-custom-control {
      color: #fff;
      cursor: pointer;
      display: block;
      font-size: 16px;
      padding: 12px 10px;
      transition: color 100ms 0ms ease, opacity 200ms;

      .control-label {
        font-family: $font-family-primary-alt;
        font-size: 16px;
        position: absolute;
        bottom: -8px;
        left: 50%;
        text-transform: uppercase;
        transform: translate(-50%, 100%);
        white-space: nowrap;
        opacity: 0;
        transition: opacity 100ms 0ms ease;
      }

      &:hover {
        color: #fff;
        opacity: 1 !important;

        .control-label {
          opacity: 1;
        }
      }
    }

    .unmute-control {
      display: none;
    }

    &.youtube {
      top: 80px;
    }

    &.muted {
      .unmute-control {
        display: block;
      }

      .mute-control {
        display: none;
      }
    }

    &:hover {
      background: rgba($black, 0.16);

      .vid-custom-control {
        opacity: 0.4;
      }
    }
  }

  .video-cover {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    overflow: hidden;
    z-index: $z-index-video-img-overlay;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: absolute;
      z-index: 1;
    }

    .play-button {
      align-items: center;
      background: #fff;
      border: 1px solid $border-grey;
      border-radius: 100%;
      box-shadow: 0 2px 8px rgba($black, 0.25);
      cursor: pointer;
      display: flex;
      justify-content: center;
      height: 120px;
      width: 120px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      transition: background 120ms, border-color 120ms;
      z-index: 3;

      .fa-play {
        color: $blue;
        font-size: 48px;
        margin-left: 7px;
        transition: color 120ms;
      }

      &:hover {
        background: $blue;
        border-color: $blue;

        .fa-play {
          color: #fff;
        }
      }
    }

    &:after {
      background: rgba($black, 0.12);
      content: "";
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
    }
  }

  .video-overlay{
    position: absolute;
    top: 12px;
    right: 12px;
    bottom: 12px;
    left: 12px;
    z-index: 11;
    background-color: $white;
    opacity: .98;

    .close-overlay{
      color: $link-color-hover;
      position: absolute;
      margin-top: 13px;
      right: 16px;
    }

    .close-overlay:hover{
      cursor: pointer;
    }

    .notice-message{
      font-size: 10px;
      width:535px;
      margin-left: 33px;
      position: absolute;
      bottom: 45px;
      color: $mid-grey;
    }

    .notice-countdown{
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      color: $black;
      letter-spacing: 4.8px;
      font-family: $font-family-primary-alt;
      line-height: 33px;
      text-transform: uppercase;
      width: 100%;
      text-align: center;

      .play-button{
        position: relative;
        display: inline-block;
        width: 70px;
        font-size: 24px;

        .play-icon{
         position: absolute;
         z-index: -1;
         top: 50%;
         transform: translateY(-50%);
         left: 0;

         font-size: 82px;
        }
  
        .counter{
          display: block;
          width: 60%;
          text-align: right;

          color: $white;
        }
      }
    }
    .overlay-controls{
      margin-left: 33px;
      position: absolute;
      bottom: 80px;

      .play,
      .cancel {
        font-size: 16px;
        font-family: $font-family-primary;
        letter-spacing: 3.5px;
        text-transform: uppercase;
      }

      .play{
        padding: 8px 16px;
        margin-right: 20px;

        background-color: $link-color-hover;
        border: 1px solid $link-color-hover;
        color: $white;

        &:hover,
        &:focus { 
          background-color: $dark-grey;
          border-color: $dark-grey;
        }
      }
      
      .cancel{
        color: $link-color-hover;
        background-color: transparent;
        border-color: transparent;

        &:hover,
        &:focus { 
          color: $dark-grey;
        }
      }
    }
  }

  .plyr--stopped .plyr__controls {
    display: none;
  }

  &.has-disclaimer {
    .plyr--stopped .plyr__controls {
      display: block;
    }
  }
  
  &.xs-player{
    .video-overlay{
      .close-overlay{
        margin-top: 13px;
        right: 16px;
      }

      .close-overlay:hover{
        cursor: pointer;
      }

      .notice-countdown{
        top: 10%;
        margin-left: 16px;

        font-size: 12px;
        letter-spacing: 2.4px;
        text-align: left;
        transform: none;

        width: 80%;
        line-height: 1em;

        .play-button{
          font-size: 12px;
          width: auto;

          .play-icon{
           display: none;
          }

          .counter{
            display: inline;
            color: $black;
            font-weight: $weight-bold;
          }
        }

        .notice-countdown__post-label {
          display: none;
        }
      }

      .overlay-controls{
        margin-left: 16px;
        bottom: 60%;
        transform: translateY(50%);

        .play,
        .cancel {
          font-size: 12px;
          padding: 4px;
        }

        .play {
          margin-right: 15px;
        }
      }

      .notice-message{
        font-size: 8px;

        margin-left: 0;
        padding-left: 16px;
        padding-right: 16px;
        bottom: 5%;
        max-width: 100%;
      }
    }
  }
  
  &.sm-player{
    .video-overlay{
      .close-overlay{
        margin-top: 13px;
        right: 16px;
      }

      .close-overlay:hover{
        cursor: pointer;
      }

      .notice-message{
        font-size: 8px;

        margin-left: 0;
        padding-left: 16px;
        padding-right: 16px;
        bottom: 12px;
        max-width: 100%;
      }

      .notice-countdown{
        top: 15px;
        margin-left: 16px;

        font-size: 12px;
        letter-spacing: 2.4px;
        text-align: left;
        transform: none;

        .play-button{
          font-size: 12px;
          width: auto;

          .play-icon{
           display: none;
          }

          .counter{
            display: inline;
            color: $black;
            font-weight: $weight-bold;
          }
        }

        .notice-countdown__post-label {
          display: none;
        }
      }

      .overlay-controls{
        margin-left: 16px;
        bottom: 50%;
        transform: translateY(50%);

        .play {
          margin-right: 15px;
        }

        .play,
        .cancel {
          font-size: 12px;
        }
      }
    }
  }
  
  &.md-player{
    .video-overlay{
      .close-overlay{
        margin-top: 13px;
        right: 16px;
      }

      .close-overlay:hover{
        cursor: pointer;
      }

      .notice-message{
        margin: 0 29px 0 33px;
        height: 28px;
        bottom: 26px;
        max-width: 90%;
      }

      .notice-countdown{
        font-size: 16px;
        top: 25%;
        margin-left: 34px;
        transform: none;
        text-align: left;

        .play-button{
          font-size: 16px;
          width: 50px;

          .play-icon{
           font-size: 60px;
          }

          .counter{
            display: inline-block;
            width: 60%;
            text-align: right;
          }
        }

        .notice-countdown__post-label {
          display: none;
        }
      }

      .overlay-controls{
        margin-left: 33px;
        bottom: 65px;
      }
    }
  }
}

// Small Screen
@media (max-width: $breakpoint-sm-max) {
  .plyr--video .plyr__controls {
    padding: 10px 20px;
  }

  .video-player-container .custom-controls {
    right: 30px;

    &.youtube {
      top: 50px;
    }
  }
}
