.tracking-modal-trigger{
  cursor: pointer;
  
  i{
    position: relative;
    top: 2px;
  }
}

.tracking-modal {
  .modal-content {
    border-radius: 0 24px 24px 24px;
    box-shadow: 0 8px 16px rgba($black, 0.2);

    .modal-header {
      text-align: center;
      border-bottom: none;
      padding: 40px 40px 20px 40px;

      @media (max-width: $breakpoint-sm-max) {
        padding: 20px 20px 0 20px;
      }

      .modal-title {
        flex: 1;
        text-align: center;
        font-family: $font-family-secondary;
        font-size: 44px;
        letter-spacing: 0.01em;
      }
    }

    .modal-body {
      padding: 20px 40px 20px 40px;
      margin-bottom: 0;

      &.rich-text {
        width: 100%;
      }

      .modal-body-scroll {
        height: calc(100vh - 400px);
        padding-right: 10px;
      }

      @media (max-width: $breakpoint-sm-max) {
        padding: 10px 20px 20px 20px;

        .modal-body-scroll {
          height: calc(100vh - 375px);
        }
      }
    }

    .modal-footer {
      border-top: none;
      padding: 20px 40px 40px 40px;

      padding: 0 20px 20px 20px;

      // On small screen, stack buttons
      flex-direction: column;

      .checkbox-container {
        align-self: flex-start;
        padding-left: 20px;

        input {
          margin-right: 5px;
        }
      }

      button {
        align-self: flex-end;
        margin: 20px 20px 0 0;
      }
    }
  }
} // #modal-notice

.modal-backdrop {
  &.show {
    opacity: 0.85;
  }
}
