// Latest Press Releases
.latest-press-releases {
  $post-spacing: 22px;

  margin-bottom: $component-margin-bottom;

  .component-header {
    display: flex;

    @media (max-width: $breakpoint-sm-max) {
      flex-direction: column;
    }

    .component-title {
      flex-grow: 1;
      flex-shrink: 1;
      flex-basis: auto;
      text-transform: uppercase;
    }

    .view-all {
      a {
        text-decoration: none;
        font-family: $myriad-pro-condensed;
        font-weight: $weight-semibold;
      }
    }
  }

  .posts {
    white-space: nowrap;
    display: flex;

    .post {
      margin-top: 35px;
      margin-bottom: 20px;
      white-space: normal;

      display: inline-block;
      position: relative;
      min-height: 100%;

      margin-right: 20px;
      &:last-child{
        margin-right: 0;
      }

      padding-bottom: 20px;

      background-color: $light-grey;
      border-radius: 0 0 30px 0;

      a {
        text-decoration: none;

        .post-date {
          position: absolute;
          top: -25px;
          left: -1px;
          z-index: 2;
        }

        .post-content{
          margin-top: 70px;
          margin-bottom: 40px;

          .post-title,
          .post-body {
            margin-left: 25px;
            margin-right: 25px;
          }

          .post-title {
            color: $blue;
          }
          .post-body {
            color: $black;
          }
        }
      }

      &:hover {
        background-color: $blue;
        -webkit-box-shadow: 0px 2px 12px rgba($black, 0.2);
        -moz-box-shadow: 0px 2px 12px rgba($black, 0.2);
        box-shadow: 0px 2px 12px rgba($black, 0.2);

        a {
          .post-image {
            transform: translate(-50%, -50%) scale(1.1);
          }

          .post-title,
          .post-body {
            color: $white;
          }
        }
      }
    }
  }

  &.scrollable-posts {
    .posts {
      .post {
        width: 100%;

        @media (min-width: $breakpoint-md-min) {
          min-width: calc(33% - #{$post-spacing});
          width: calc(33% - #{$post-spacing});
        }

        .post-image-container {
          height: 221px;
        }
      }
    }
  }

  &.double-post,
  &.single-post {
    .posts {
      .post {
        min-width: calc(50% - #{$post-spacing});
        width: calc(50% - #{$post-spacing});
      }
    }
  }

  &.double-post{
    .posts {
      .post {
        min-width: calc(50% - #{$post-spacing});
        width: calc(50% - #{$post-spacing});
      }
    }
  }

  &.single-post {
    .posts {
      .post {
        width: 100%;

        @media (min-width: $breakpoint-lg-min) {
          min-width: 70%;
          width: 70%;
        }
      }
    }
  }
}


// Background variations
.light-bg{
  .latest-press-releases {
    .posts {
      .post {
        background-color: $white;
        &:hover {
          background-color: $blue;
        }
      }
    }
  }
}
// End Background variations

// End Latest Press Release
