body {
  @media (min-width: $breakpoint-lg-min) {
    &.has-hero {
      #header:not(.scrolled):not(.country-selector-open) {
        background: linear-gradient(rgba($black, 0.5), rgba($black, 0));

        #main-nav-desktop-container {
          background: none;

          .container .left-col .logo-container {
            color: $white;
          }

          .links {
            li {
              a {
                color: $white;
                &:hover {
                  color: $blue;
                }
              }
            }
          }

          .container .fa-search {
            color: $white;

            &:hover {
              color: $blue;
            }
          }

          #main-nav-desktop > ul > li > a,
          #main-nav-desktop > ul > li > .nav-flyout {
            color: $white;
          }
        }

        i {
          color: $white;
          transition: color $transition-speed $transition-style;

          &:hover {
            color: $blue;
          }
        }
      }
    }
  }
  // End (min-width: $breakpoint-lg-min)
}
