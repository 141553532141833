body {
  display: none;
}

// Sticky Sidebar Layout
.sticky-sidebar-container {
  margin-top: 82px;

  .sidebar-container {
    .sidebar {
      background-color: $light-grey;
      border-left: 4px solid $black;
      padding-top: 32px;
      padding-left: 32px;
      padding-right: 32px;

      $sidebar-margin-top: 20px;
      $sidebar-margin-bottom: 40px;

      margin-top: $sidebar-margin-top;
      margin-bottom: $sidebar-margin-bottom;

      @media (min-width: $breakpoint-lg-min) {
        max-height: calc(100vh - #{$header-height-lg + px} - #{$sidebar-margin-bottom} - #{$sidebar-margin-top});
      }

      // sidebar positioning
      position: relative;

      &.sticky-top {
        position: fixed;
      }

      &.sticky-bottom {
        position: absolute;
        bottom: 20px;
      }

      // When in small or medium screen, ignore any sticky classes
      @media (max-width: $breakpoint-md-max) {
        position: relative !important;
        top: auto !important;
        bottom: auto !important;
      }

      .link-list {
        margin-bottom: 20px;

        h4 {
          font-family: $font-family-secondary;
          font-size: 24px;
          font-weight: $weight-regular;
          padding-bottom: 32px;
          text-transform: uppercase;
        }

        ul {
          margin-right: 15px;

          li:last-child {
            padding-bottom: 0;
          }
        }
      }
    }
  }

  .sidebar-content {
    padding-top: 32px;
  }
}
// End Sticky Sidebar Layout
