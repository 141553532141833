body {
  main .background-container {
    position: relative;
    z-index: 1;
  }
  .page-title-container {
    position: relative;

    .background-container-fade-out {
      position: absolute;
      top: 100%;
      height: 140px;
      width: 100%;
      background: linear-gradient(180deg, $light-grey, $white);
    }

    &.center {
      h1,
      .summary {
        text-align: center;
      }
    }
  }

  &:not(.has-hero) {
    .page-title-container {
      padding-top: $header-height-lg + 25px;

      @media (max-width: $breakpoint-md-max) {
        padding-top: $header-height-md + 25px;
      }

      @media (max-width: $breakpoint-sm-max) {
        padding-top: $header-height-sm + 25px;
      }
    }

    &.has-sub-nav {
      .page-title-container {
        padding-top: $header-height-lg + 75px;

        @media (max-width: $breakpoint-md-max) {
          padding-top: $header-height-md + 75px;
        }

        @media (max-width: $breakpoint-sm-max) {
          padding-top: $header-height-sm + 75px;
        }
      }
    }
  }

  &.has-hero {
    .page-title-container {
      position: relative;
      min-height: $page-title-hero-height + px;
      width: 100%;
      display: flex;
      align-items: flex-end;
      overflow: hidden;

      .hero {
        position: absolute;
        z-index: $z-index-page-title-hero;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: 50% 0;
        top: 0;
        left: 0;
      }

      .background-container {
        position: relative;
        z-index: $z-index-page-title-text;
        width: 100%;
        margin-top: 175px;
        padding-top: 24px;
        padding-bottom: 40px;
      }

      .title-background {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background: linear-gradient(rgba($black, 0), rgba($black, 0.4));
      }

      .image--disclaimer {
        display: block;
        color: $font-color-dark-bg;
        font-size: 12px;
        text-align: right;

        @media (max-width: $breakpoint-md-max) {
          position: relative;
          bottom: -20px;
        }

        @media (min-width: $breakpoint-md-min) and (max-width: $breakpoint-md-max) {
          width: 450px;
          margin-left: auto;
        }

        @media (min-width: $breakpoint-lg-min) {
          position: absolute;
          z-index: $z-index-page-title-text;
          bottom: -20px;
          right: 20px;
          width: 200px;
          margin-left: auto;
        }
      }

      .container {
        position: relative;

        h1,
        .summary {
          font-size: 60px;
          font-weight: $weight-light;
          color: $white;
        }
      }

      &:before {
        content: "";
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
        height: 0;
        width: 0;
        background: transparent;
        transition: background 200ms;
      }

      &:after {
        content: "";
        min-height: inherit;
        font-size: 0;
      }
    }

    &.nav-open {
      .page-title-container {
        &::before {
          z-index: $z-index-page-title-text;
          height: 100%;
          width: 100%;
          background: rgba($black, 0.65);
        }
      }
    }
  }
}
