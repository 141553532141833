.text-input,
.text-textarea {
    box-shadow: 0 8px 16px rgba($black,.1);
    border: 1px solid $border-grey;
    color: $blue;
    display: block;
    padding-left: 28px;
    padding-right: 56px;
    height: 60px;
    font-family: $font-family-primary-alt;
    font-size: 20px;
    line-height: 45px;
    outline: 0;
    text-decoration: none;
    text-align: left;
    width: 100%;

    &::placeholder {
      color: $light-blue;
      opacity: 1;
    }

    &:-ms-input-placeholder {
      color: $light-blue;
    }

    &::-ms-input-placeholder {
      color: $light-blue;
    }

    &.error {
        border-color: $red;
    }

}

.text-input {
    border-radius: 30px;
}

.text-textarea {
    border-radius: 0 30px 30px 30px;
    height: 220px;
}

.input-label.error {
    color: $red;
}

.error-message-container {
    border: 1px solid $red;
    padding: 20px;
    margin-bottom: 24px;

    .error-message {
        color: $red;
        margin-bottom: 8px;

        &:last-child {
            margin-bottom: 0;
        }

    }

}

.checkbox-input {
    align-items: center;
    display: flex;
    margin: 20px 0;

    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }

    .checkbox {
        background: #fff;
        border: 1px solid $border-grey;
        border-radius: 8px;
        box-shadow: 0 0 8px rgba($black, .1);
        cursor: pointer;
        height: 50px;
        width: 50px;
        margin-right: 20px;
    }

    .checkbox-label {

        label {
            font-weight: $weight-bold;
            margin-bottom: 0;
        }

        .checkbox-collapse-anchor {
            display: block;
            font-size: 16px;
            text-decoration: none;
        }

    }

    input:checked ~ .checkbox {
      background-color: $blue;
    }

}

.checkbox-collapse {
    margin-left: 70px;
}

@media (max-width: $breakpoint-sm-max) {

    .input-label {
        margin: 20px 0;
    }

    .checkbox-collapse {
        margin-left: 0;
    }

}
