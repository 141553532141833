.language-selector{
  ul{
    list-style:none;
    display:flex;
    margin:0 auto;
    padding-inline-start: 15px;
    padding-inline-end: 15px;
    
    li{
      display:inline-block;
      cursor: pointer;
      padding: 0 5px;

      text-transform: uppercase;

      @media (max-width: $breakpoint-md-max) {
        font-size: 10px;
        padding-left: 10px;
        padding-right: 10px;
      }

      &.selected{
        background-color: rgba($grey, .25);
      }
    }
  }
}