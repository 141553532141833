footer {
  margin-top: 75px;

  .footer-nav {
    padding-bottom: 75px;

    .logo {
      padding-left: 0;
      padding-right: 0;

      .white-logo {
        display: none;
      }

      img {
        height: 37px;
        margin: 12px 0;
      }
    }

    .links {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;

      padding-left: 0;
      padding-right: 0;

      .links-section {
        display: flex;
        flex-wrap: nowrap;
        justify-content: flex-end;

        .link {
          align-items: center;
          display: flex;
          margin-left: 78px;
          margin: 12px 0 12px 78px;
          cursor: default;

          a {
            text-decoration: none;
            font-size: 20px;
            text-transform: uppercase;
            font-weight: $weight-semibold;
            font-family: $font-family-primary-alt;
            white-space: nowrap;
            text-decoration: none;
          }

          &.general-link {
            a {
              color: $dark-blue;
            }
          }

          &.terms-link {
            a {
              color: $light-blue;
            }
          }
        }
      }
    }

    .additional {
    }

    .social {
      display: flex;
      list-style: none;
      margin: 0;
      padding: 32px 0 0 0;

      a {
        color: $light-blue;
        padding: 0 12px;
        margin-right: 24px;

        span {
          margin-left: 12px;
        }

        &:hover {
          text-decoration: none;

          span {
            text-decoration: underline;
          }
        }
      }

      li:last-child a {
        margin-right: 0;
      }
    }

    // Medium screen
    @media (min-width: $breakpoint-md-min) and (max-width: $breakpoint-md-max) {
      .logo img {
        margin: 0;
      }
      .links {
        padding-top: 40px;

        .links-section:first-child {
          margin-left: 0;
        }
      }
    }

    // Small screen
    @media (max-width: $breakpoint-sm-max) {
      .logo {
        text-align: center;

        img {
          margin: 0;
        }
      }

      .links {
        flex-direction: column;

        .links-section {
          flex-direction: column;
          justify-content: center;

          .link {
            display: block;
            text-align: center;
            margin: 0;
            padding-top: 32px;
          }
        }
      }
      .additional {
        text-align: center;
      }

      .social {
        justify-content: center;
        margin: 8px 0;
      }
    }
  }
}