body {
  @media (min-width: $breakpoint-lg-min) {
    #header {
      padding: 0;

      #main-nav-desktop-container {
        padding-top: 23px;
        transition: padding $transition-speed $transition-style;

        color: $black;
        font-size: 20px;

        .container {
          display: flex;
          flex-direction: row;
          padding-left: 0;
          padding-right: 0;

          .left-col,
          .right-col {
            width: 200px;
            padding-bottom: 23px;
          }

          .left-col {
            img {
              height: 36px;
            }

            .logo-container {
              font-size: 32px;
              color: $dark-blue;
              font-weight: $weight-bold;
              font-style: italic;
              text-decoration: none;
            }
          }

          .middle-col {
            flex: 1;
            text-align: center;
          }

          .right-col {
            text-align: right;
          }

          .links {
            a {
              margin-right: 20px;

              color: $black;
              transition: color $transition-speed $transition-style;

              &:hover {
                color: $blue;
              }
            }
          }
        }
      }
    }
  }
  // End (min-width: $breakpoint-lg-min)
}

#main-nav-desktop {
  position: relative;
  display: inline-block;

  max-width: 100%;
  color: #000;

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  li {
    padding: 0;
  }

  a,
  .nav-flyout {
    text-decoration: none;
    text-transform: uppercase;
    height: 100%;
    display: block;
    font-family: $font-family-primary-alt;

    &.product-name {
      text-transform: none;
    }
  }

  .products {
    a,
    .nav-flyout {
      text-transform: none;
    }
  }

  /* Any sub menu */
  .nav-flyout + ul {
    position: absolute;
    opacity: 0;
    transition: opacity 200ms;
  }

  .nav-flyout + ul:not(.show-element) {
    top: -9999999px;
  }

  .nav-flyout + ul.show-element {
    opacity: 1;
  }

  /* The Main Navigation Bar - Navigation Level One */
  > ul {
    height: 100%;
  }

  > ul {
    display: flex;
    flex-direction: row;

    /* First Level Items */
    > li {
      position: relative;
      text-align: center;
      cursor: pointer;
      padding: 0 36px 23px 36px;
      transition: padding $transition-speed $transition-style;

      > a,
      > .nav-flyout {
        color: $black;
        font-size: 20px;
        font-weight: $weight-bold;

        &:hover,
        .show-element + & {
          color: $blue !important;
        }
      }

      &.active {
        > .nav-flyout {
          color: $blue !important;
        }
      }

      &:not(.show-element) {
        display: inline-block;
      }

      /* Second Level and above */
      ul {
        line-height: initial;
        width: 200px;
        background-color: $white;
        min-height: 100%;

        display: flex;
        flex-direction: column;

        li {
          white-space: normal;
          text-align: left;

          &.bottom {
          }

          &.active {
            background-color: $blue-bg;
          }

          a,
          .nav-flyout {
            color: $blue;
            font-size: 16px;
            font-weight: $weight-semibold;
            line-height: 1.3;

            i {
              color: $blue !important;
            }

            &:focus,
            &:hover {
              background-color: $blue-bg;
            }
          }

          &.menu-title {
            font-family: $font-family-primary;
            font-size: 12px;
            color: $dark-grey;
            font-weight: $weight-bold;
            line-height: 1.3;
          }

          a,
          .nav-flyout,
          &.menu-title {
            padding: 12px 28px;
          }
          
          > img {
            margin: 10px 28px;
          }

          &.sub-nav {
            margin-left: 27px;
            border-left: 1px solid $blue;

            a {
              color: $dark-blue;
              padding-left: 12px;
            }

            &.divider {
              display: none;
            }
          }

          .descr {
            display: block;
            font-family: $font-family-primary;
            font-weight: $weight-light;
            font-size: 12px;
            text-transform: none;
          }

          &.divider {
            border-bottom: 1px solid $border-grey;
            cursor: default;
            margin-left:28px;
            margin-right:28px;
          }

          &.bottom {
            flex: 1;
          }

          .link.with-icon {
            display: flex;
            justify-content: space-between;
            margin-right: 0;
            margin-bottom: 0;

            .text {
              word-break: break-word;
              padding-right: 0;
            }
          }
        }
      }

      /* Second Level Dropdown */
      > ul {
        top: 100%;
        left: 50%;
        margin-left: -100px;

        border-top: 2px solid $blue;
        padding-bottom: 20px;

        > li {
          /* Third Level Dropdown */
          > ul {
            top: 0;
          }
        }

        &:before {
          font-family: $font-awesome-pro;
          font-weight: $weight-light;
          font-size: 15px;
          position: absolute;
          top: -27px;
          transition: top $transition-speed $transition-style;
          left: 50%;
          margin-left: -7px;
          color: $blue;
          content: "\f078";
          line-height: 1.3;
        }

        &.multi-col {
          flex-direction: row;
          display: flex;
          width: 402px;
          left: 50%;

          &.two-col {
            margin-left: -200px;
          }

          &.three-col {
            width: 600px;
            margin-left: -300px;
          }

          &.four-col {
            width: 800px;
            margin-left: -400px;
          }

          &.five-col {
            width: 1000px;
            margin-left: -500px;
          }

          &.six-col {
            width: 1200px;
            margin-left: -600px;
          }

          > li {
            border-left: 1px solid #f8f8f8;

            &:first-child {
              border-left: none;
            }
          }
        }
      }
    }

    // Border Shadow
    > li {
      // second level
      > ul {
        -webkit-box-shadow: 0px 4px 8px 0px rgba($black, 0.15);
        -moz-box-shadow: 0px 4px 8px 0px rgba($black, 0.15);
        box-shadow: 0px 4px 8px 0px rgba($black, 0.15);
      }

      // third level and up
      &.right-sub-nav {
        > ul ul {
          -webkit-box-shadow: 4px 4px 8px 0px rgba($black, 0.15);
          -moz-box-shadow: 4px 4px 8px 0px rgba($black, 0.15);
          box-shadow: 4px 4px 8px 0px rgba($black, 0.15);
        }
      }
      &.left-sub-nav {
        > ul ul {
          -webkit-box-shadow: -4px 4px 8px 0px rgba($black, 0.15);
          -moz-box-shadow: -4px 4px 8px 0px rgba($black, 0.15);
          box-shadow: -4px 4px 8px 0px rgba($black, 0.15);
        }
      }

      // if its a multi col, remove the shadow drom the cols
      > ul.multi-col {
        ul {
          -webkit-box-shadow: none;
          -moz-box-shadow: none;
          box-shadow: none;
        }
      }
    }
    // End Border Shadow

    // Caret placement
    > li {
      &.right-sub-nav,
      &.left-sub-nav {
        .nav-flyout {
          position: relative;
          &:before,
          &:after {
            font-family: $font-awesome-pro;
            font-weight: $weight-light;
            font-size: 15px;
            position: absolute;
            top: 50%;
            margin-top: -7px;
          }
        }
      }
      &.right-sub-nav > ul {
        .active {
          > .nav-flyout {
            &:after {
              content: "\f054";
              right: 7px;
            }
          }
        }
        .nav-flyout {
          &:hover {
            &:after {
              content: "\f054";
              right: 7px;
            }
          }
        }
        ul {
          left: 100%;
          top: 0;
        }
      }
      &.left-sub-nav > ul {
        .active {
          > .nav-flyout {
            &:after {
              content: "\f053";
              left: 7px;
            }
          }
        }
        .nav-flyout {
          &:hover {
            &:before {
              content: "\f053";
              left: 7px;
            }
          }
        }
        ul {
          right: 100%;
          top: 0;
        }
      }
    }
    // end Caret placement
  }

  /* Javascript classes */
  .hide-element {
    display: none;
  }

  .show-element {
    display: flex;
  }

  /* Fallback for users without javascript */
  html.no-js li:hover > a + ul,
  html.no-js li:focus > a + ul {
    display: block;
  }
}
