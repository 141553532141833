// Publication Listing Layout
#publication-listing-page {
  .page-title-container {

    h1{
      text-align: center;
    }

    .rich-text {
      padding-top: 10px;
    }

    &.with-hero {
      color: $white;
    }
  }

  // Publication Listing Filters
  #list-filters {
    padding-top: 24px;

    // Make sure this is over the listing
    position: relative;
    z-index: 2;

    .dropdown-item {
      color: $blue;

      &.selected {
        color: $black;
      }
    }

    @media (max-width: $breakpoint-md-max) {
      flex-direction: column;

      .dropdown.filter,
      .input-group.filter-group {
        margin-bottom: 24px;
        margin-right: 0;
      }
    }
  }
  // End Publication Listing Filters

  // Publication Listing List
  #listing-list-container {
    #listing-list {
      margin-top: 80px;
      
      
      @media (min-width: $breakpoint-md-min) {
        &.has-results{
          border-left: 1px solid $blue;
          padding-left: 20px;
          margin-left: 5px;
        }
      }

      .item {
        position: relative;
        padding-top: 24px;
        border-radius: 0 0 30px 0;
        margin-bottom: 80px;

        background: $light-grey;
        transition: box-shadow 160ms;
        
        &:hover {
          box-shadow: 0 2px 12px rgba(0, 0, 0, 0.2);
        }
        
        .detail-date-container {
          position: relative;
          z-index: 1;
          top: -55px;
          left: -55px;
          margin-bottom: 0;
          
          @media (max-width: $breakpoint-sm-max) {
            left: 0;

            span {
              display: block;
              border-left: 0;
              padding-top: 0;
              padding-bottom: 0;

              &:first-child {
                padding-top: 16px;
              }
              &:last-child {
                padding-bottom: 16px;
              }

              &:not(:first-child) {
                padding-left: 20px;
              }
            }
          }
        }

        .details-container {
          position: relative;
          top: -15px;
          padding: 0 32px 29px;
          z-index: 1;
          width: 100%;

          a {
            &:hover {
              text-decoration: none;
            }
          }

          .item-detail {
            margin-top: 10px;
          }

          .detail-label {
            font-weight: $weight-bold;
            padding-right: 10px;
          }

          .multi-line-details{
            display: flex;
            flex-direction: row;

            // This is to offset the first item-detail margin-left
            margin-left: -20px;

            .item-detail{
              flex: 1;
              margin-left: 20px;
            }
          }
        }

        &:last-child:before {
          background: #fff;
          content: "";
          height: 100%;
          width: 2px;
          position: absolute;
          left: -21px;
          top: 0;
        }
      }

      .no-results {
        font-size: 32px;
        font-weight: $weight-bold;
        text-align: center;
      }

      .error {
        font-size: 32px;
        font-weight: $weight-bold;
        color: $red;

        padding-top: 80px;
        padding-left: 40px;
      }
    }
  }
  // End Publication Listing List
}
// End Publication Listing Layout
