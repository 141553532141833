#sub-nav {
  z-index: -1;

  .sub-nav {
    display: flex;
    flex-direction: row;

    a,
    .nav-flyout {
      text-transform: uppercase;

      &:hover {
        text-decoration: none;
      }
    }

    .primary-title {
      white-space: nowrap;
      color: $white;
      padding-right: 20px;
      text-transform: uppercase;
      font-family: $font-family-primary-alt;
    }

    .back-nav {
      white-space: nowrap;
      color: $white;
      padding-right: 20px;
      text-transform: uppercase;

      i {
        transition: none !important;
        position: relative;
        top: 1px;
        padding-right: 5px;
      }

      &:hover {
        color: $blue;
        i {
          color: $blue !important;
        }
      }
    }

    .sub-nav-items {
      .vertical-menu-trigger {
        text-transform: uppercase;
        white-space: nowrap;

        padding-right: 20px;

        &:after {
          position: absolute;
          right: 0;
          font-family: $font-awesome-pro;
          content: '\f107';
        }
      }

      ul {
        list-style: none;
        padding-left: 0;

        display: flex;
        flex-wrap: wrap;
      }

      // root list
      > ul {
        position: relative;
        justify-content: space-around;
        flex-direction: column;

        // root items
        > li {
          
          > a,
          > .nav-flyout {
            color: $white;

            &:hover {
              text-decoration: none;
              color: $blue;
            }
          }

          // children
          > ul {
            display: none;
            transition: opacity 200ms;

            flex-direction: column;

            position: absolute;
            top: 100%;
            left: 0;

            background-color: $white;
            border-top: 2px solid $blue;

            li {
              a,
              .nav-flyout {
                display: block;
                color: $blue;
                padding: 12px 28px;

                &:hover {
                  background-color: $light-blue-hover;
                }
              }
            }
          }
        }
      }
    }
  }

  &.horizontal {
    border-top: 1px solid $grey;
    border-bottom: 1px solid $grey;

    .sub-nav {

      .primary-title,
      .back-nav {
        padding-top: 10px;
        border-right: 1px solid $grey;
      }

      .sub-nav-items {
        flex: 1;

        .vertical-menu-trigger {
          display: none;
        }
        
        .nav-flyout {
          position: relative;
          &:after {
            position: absolute;
            right: 0;
            font-family: $font-awesome-pro;
            content: '\f107';
          }
        }

        > ul {
          flex-direction: row;
          flex: 1;
          display: flex;
          justify-content: space-around;
          text-align: center;
          margin-bottom: 0;

          > li {
            flex: 1 1 0;
            text-align: center;
            position: relative;

            > a {
              display: inline-block;
            }

            > a,
            > .nav-flyout {
              padding: 10px 20px;
            }

            > ul {
              position: absolute;
              top: 100%;
              left: 50%;
              margin-left: -100px;
              width: 200px;

              border-top: 2px solid $blue;
              padding-bottom: 20px;

              box-shadow: 0px 4px 8px 0px rgba($black, 0.15);

              li {
                text-align: left;

                a,
                .nav-flyout {
                  display: block;
                  font-size: 16px;
                  color: $blue;
                  padding: 12px 28px;

                  &:hover {
                    background-color: $light-blue-hover;
                  }
                }
              }
            }

            &.active {
              > .nav-flyout {
                color: $blue;
              }

              > ul {
                display: flex;
              }
            }
          }
        }
      }
    }
  }

  &.vertical {
    .sub-nav {
      justify-content: flex-start;

      .primary-title {
        display: none;
      }

      .back-nav {
        span {
          display: none;
        }
      }

      .sub-nav-items {
        position: relative;
        padding-bottom: 25px;

        .vertical-menu-trigger {
          color: $white;
        }

        > ul {
          position: absolute;
          top: 100%;
          left: 0;
          width: 200px;

          display: none;
          flex-direction: column;
          flex: 1;

          background-color: $white;
          border-top: 2px solid $blue;
          padding-bottom: 20px;

          box-shadow: 0px 4px 8px 0px rgba($black, 0.15);

          // &:before {
          //   font-family: $font-awesome-pro;
          //   font-weight: $weight-light;
          //   font-size: 15px;
          //   position: absolute;
          //   top: -27px;
          //   transition: top $transition-speed $transition-style;
          //   left: 50px;
          //   margin-right: -7px;
          //   color: $blue;
          //   content: "\f078";
          //   line-height: 1.3;
          // }

          li {
            a,
            .nav-flyout {
              display: block;
              color: $blue;
              font-size: 16px;
              padding: 12px 28px;

              &:hover {
                background-color: $light-blue-hover;
              }
            }

            > ul {
              display: none;
              position: absolute;
              top: -2px;
              left: 100%;
              width: 200px;

              min-height: calc(100% + 2px); // add 2px for the border offset

              background-color: $white;
              border-top: 2px solid $blue;
              padding-bottom: 20px;

              box-shadow: 8px 4px 8px 4px rgba($black, 0.15);
            }
          }
        }

        &.active {
          .vertical-menu-trigger {
            color: $blue;
          }

          > ul {
            display: flex;

            li.active {
              > ul {
                display: block;
              }
            }
          }
        }
      }

      // on large screen, the vertial nav is on the right side of the screen
      @media (min-width: $breakpoint-lg-min) {
        justify-content: space-between;

        .primary-title {
          // if vertical in large screen, keep this, so the justify-content: space-between
          //  pushes the menu to the right
          display: inline;
          visibility: hidden;
        }

        .sub-nav-items {
          > ul {
            left: unset;

            right: 0;

            &:before {
              left: unset;
              margin-right: unset;

              right: 50px;
              margin-left: -7px;
            }

            li {
              > ul {
                left: -200px;

                box-shadow: -8px 4px 8px 4px rgba($black, 0.15);
              }
            }
          }
        }
      }
    }
  }
}

body:not(.has-hero) {
  #sub-nav {
    .sub-nav {
      .primary-title {
        color: $black;
      }

      .back-nav {
        color: $black;
        &:hover {
          color: $blue;
        }
      }

      .sub-nav-items {
        .vertical-menu-trigger {
          color: $black;
        }

        ul {
          li {
            a,
            .nav-flyout {
              color: $black;
            }
          }
        }
      }
    }
  }
}

.scrolled {
  #sub-nav {
    .sub-nav {
      .primary-title {
        color: $black;
      }

      .back-nav {
        color: $black;
        &:hover {
          color: $blue;
        }
      }

      .sub-nav-items {
        .vertical-menu-trigger {
          color: $black;
        }

        ul {
          li {
            a,
            .nav-flyout {
              color: $black;

              padding-bottom: 10px;
            }
          }
        }
      }
    }
  }
}

@media (max-width: $breakpoint-md-max) {
  #sub-nav {
    background-color: $white;

    .sub-nav {
      display: none;
      .primary-title {
        color: $black;
      }

      .back-nav {
        color: $black;

        i {
          color: $black;
        }
      }

      .sub-nav-items {
        & > ul > li {
          & > a,
          & > .nav-flyout {
            color: $black;
          }
        }
      }
    }

    &.vertical {
      .sub-nav {
        .sub-nav-items {
          .vertical-menu-trigger {
            color: $black;
          }

          & > ul > li > ul {
            // reset submenu to be inline
            position: relative;
            top: auto;
            left: 0;

            border-top: none;
            background: none;
            box-shadow: none;
            padding-bottom: 0;

            li a {
              padding-left: 48px;
            }
          }

          .nav-flyout {
            position: relative;

            &::after {
              font-family: $font-awesome-pro;
              font-size: 16px;
              content: "\f067"; // expand icon

              position: absolute;
              top: 50%;
              margin-top: -11px;
              right: 15px;
            }

            &.open-sub-menu {
              &::after {
                content: "\f068";
              }
            }
          }
        }
      }
    }
  }
}
