body {
  @media (min-width: $breakpoint-lg-min) {
    #header {
      #eyebrow-nav {
        position: relative;
        line-height: 30px;

        transition: color $transition-speed $transition-style;
        color: $white;
        transition: background-color $transition-speed $transition-style;
        background-color: $black;

        .container {
          display: flex;
          flex-direction: row;

          text-transform: uppercase;
          padding: 0;

          .left-col,
          .middle-col,
          .right-col {
            z-index: 2;
          }

          .left-col {
            flex: 1;

            position: relative;
            display: flex;
            flex-direction: row;

            font-family: $font-family-primary;
            font-size: 10px;
            font-weight: $weight-semibold;

            .back-btn {
              transition: background-color $transition-speed $transition-style;
              background-color: $dark-blue;
              padding-right: 22px;
              margin-right: 20px;

              .back-btn-bg {
                height: 100%;
                position: absolute;
                right: 100%;
                top: 0;
                width: 99999px;

                transition: background-color $transition-speed $transition-style;
                background-color: $dark-blue;
              }

              a {
                display: flex;
                align-items: center;
                height: 100%;

                i {
                  padding-right: 8px;
                }
              }
            }
          }

          .middle-col {
            flex: 1;
            text-align: center;
          }

          .right-col {
            flex: 1;
            text-align: right;

            font-family: $font-family-primary;
            font-size: 10px;

            a {
              margin-left: 22px;
            }

            .contact {
              font-weight: $weight-black;
            }
          }

          .logo-container {
            position: relative;
            margin-top: 2px;

            img {
              position: relative;
              z-index: 1;
              height: 18px;
            }

            .scrolled-logo {
              position: absolute;
              z-index: 2;
              top: 6px;
              left: 0;

              opacity: 0;
              transition: opacity $transition-speed $transition-style;
            }

            span {
              font-family: $font-family-primary-alt;
              font-weight: $weight-regular;
            }
          }

          a {
            font-family: $font-family-primary;
            font-weight: $weight-semibold;
            transition: color $transition-speed $transition-style;
            color: $white;
            text-decoration: none;

            &:hover {
              color: $blue;
            }
          }
        }
      }
      
      &.scrolled {
        /*
          If scrolled and the country selector menu is open,
          we want to put the eyebrow back to the non-scrolled theme
        */
        &:not(.country-selector-open) {
          #eyebrow-nav {
            color: $black;
            background-color: $light-grey;

            .container {
              .left-col,
              .middle-col,
              .right-col {
                z-index: 2;
              }
              .left-col {
                .back-btn {
                  background-color: $mid-grey;

                  .back-btn-bg {
                    background-color: $mid-grey;
                  }
                }
              }
              .middle-col {
                .logo-container {
                  .scrolled-logo {
                    opacity: 1;
                    top: 6px;
                  }
                }
              }
              a {
                color: $black;

                &:hover {
                  color: $blue;
                }
              }
            }
          }
        }
      }
    }

    &.has-hero {
      #header:not(.scrolled):not(.country-selector-open)  {
        #eyebrow-nav {
          background: none;
        }
      }
    }
  }
  // End (min-width: $breakpoint-lg-min)

  &.homepage {
    #eyebrow-nav {
      max-height: $eyebrow-nav-height;
      overflow: hidden;
    }
  }
}
