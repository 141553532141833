.image-grid-container {
  margin-bottom: $component-margin-bottom;

  display: flex;
  flex-wrap: wrap;

  .grid-item {
    margin-bottom: 24px;
    padding-left: 10px;
    padding-right: 10px;
    width: 100%;
    @media (min-width: $breakpoint-md-min) and (max-width: $breakpoint-md-max) {
      width: 50%;
    }

    @media (min-width: $breakpoint-lg-min) {
      width: 33%;
    }
    .image-container {
      margin-bottom: 16px;
      position: relative;

      // On small screen, make image 100% width
      @media (max-width: $breakpoint-sm-max) {
        img {
          width: 100%;
        }
      }

      // On med screen and up, set center image
      @media (min-width: $breakpoint-md-min) {
        height: 360px;
        overflow: hidden;

        img {
          position: absolute;
          max-width: none;
          height: 100%;
          top: 50%;
          left: 50%;
          transform: translateX(-50%) translateY(-50%);
        }
      }
    }

    .content-container {
      font-weight: $weight-light;
      width: 100%;
    }
  }
}
