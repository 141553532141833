

.filter-row{
  padding-bottom: 20px;

  > div{
    display: flex;
    flex-direction: row;

    .dropdown.filter{
      display: inline-block;
      margin-right: 20px;
      flex: 1 1 1;

      .btn-filter {
        @extend .btn;
        @include button-variant($white, $light-grey, $white, $light-grey);
        color: $blue;

        position: relative;
        z-index: 2;
        width: 100%;

        box-shadow: 0 8px 16px rgba($black,.1);
        border-radius: 0 30px 30px 30px;
        padding-left: 28px;
        padding-right: 56px;
        height: 60px;
        font-family: $font-family-primary-alt;
        font-size: 20px;
        line-height: 45px;
        text-decoration: none;
        text-align: left;
        overflow: hidden;
        text-overflow: ellipsis;

        &:hover{
          text-decoration: none;
        }

        &[disabled]{
          @include button-variant($mid-grey, $mid-grey, $mid-grey-hover, $mid-grey-hover, $mid-grey, $mid-grey);
          color: $white;
        }

        &::after{
          border: 0; // remove existing icon

          font-family: $font-awesome-pro;
          content: "\f078"; // Chevron down

          position: absolute;
          top: 7px;
          right: 20px;
        }
      }

      .dropdown-menu{
        position: relative;
        z-index: 1;
        min-width: 100%;
        top: -30px !important;
        padding-top: 30px;
      }

      &.show{
        .filter{
          box-shadow: none;
        }

        .dropdown-menu{
          border: 1px solid $light-grey;
          border-radius: 0 20px 20px 20px;
          box-shadow: 0 8px 16px rgba($black,.1);

          font-size: 16px;
        }
      }
      // End btn-filter
    }



    // Multi Dropdown
    .multi-dropdown{
      position: relative;
      width: 100%;

      .btn-filter {
        @extend .btn;
        @include button-variant($white, $light-grey, $white, $light-grey);
        color: $blue;
        width: 100%;

        white-space: unset;

        box-shadow: 0 8px 16px rgba($black,.1);
        border-radius: 0 30px 30px 30px;
        padding-left: 28px;
        padding-right: 56px;
        min-height: 48px;
        font-size: 16px;
        text-decoration: none;
        text-align: left;

        // make sure button is above the dropdown
        position: relative;
        z-index: 2;


        display: flex;
        flex-wrap: wrap;

        &:hover{
          text-decoration: none;
        }

        &[disabled]{
          @include button-variant($mid-grey, $mid-grey, $mid-grey-hover, $mid-grey-hover, $mid-grey, $mid-grey);
          color: $white;
        }

        &::after{
          border: 0; // remove existing icon

          font-family: $font-awesome-pro;
          content: "\f078"; // Chevron down

          position: absolute;
          top: 12px;
          right: 20px;
        }

        .selected{
          position: relative;
          background-color: $light-blue;
          color: $white;
          padding: 5px 20px 5px 8px;
          border-radius: 6px 0 6px 6px;
          margin-right: 10px;
          margin-top: 2px;
          margin-bottom: 3px;

          cursor: pointer;

          &::after{
            font-family: $font-awesome-pro;
            font-size: 8px;
            color: white;
            content: "\f00d"; // times

            position: absolute;
            z-index: 2;
            top: 0;
            right: 3px;
          }
        }
      }

      .dropdown-menu{
        position: relative;
        z-index: 1;
        width: 100%;
        min-width: unset;
        top: 100% !important;
        transform: translate3d(0px, -30px, 0px) !important;
        padding-top: 30px;
      }

      &.show{
        .filter{
          box-shadow: none;
        }

        .dropdown-menu{
          border: 1px solid $light-grey;
          border-radius: 0 0 20px 20px;
          box-shadow: 0 8px 16px rgba($black,.1);

          font-size: 16px;

          .dropdown-item{
            position: relative;
            color: $light-blue;
            padding-left: 40px;

            &::before{
              position: absolute;
              top: 50%;
              margin-top: -6px;
              left: 19px;
              height: 12px;
              width: 12px;
              border: 1px solid $blue;
              content: ""; // times
            }

            &.default{
              color: $black;
            }

            &.selected{
              &::before{
                background-color: $light-blue;
              }
            }
          }
        }
      }
      // End btn-filter

    }
    // End Multi Dropdown


    .filter-group{
      padding-right: 20px;
      display: flex;
      flex: 1;

      background-color: $white;
      border: 1px solid $light-grey;
      border-radius: 30px 30px 30px 30px;
      box-shadow: 0 8px 16px rgba($black,.1);
      height: 100%;

      .input-group-prepend{
        .btn{
          .fa-search{
            color: $blue;
          }
        }
      }

      .input-group-append{
        margin-right: -15px;

        .btn{
          .fa-times{
            color: $blue;
          }
        }
      }

      input{
        flex: 1;
        height: 100%;
        width: 100%;
        border: 0;
        font-size: 16px;
        color: $blue;
        margin-right: 0;

        &::placeholder{
          color: $blue;
          text-transform: uppercase;
          opacity: .4;
        }

        &::-ms-clear {
            display: none;
        }
      }

      &.disabled{

        .input-group-prepend{
          .btn{
            cursor: not-allowed;
            .fa-search{
              opacity: .3;
            }
          }
        }
      }
    }

    @media (max-width: $breakpoint-sm-max) {
      flex-direction: column;

      .dropdown.filter,
      .input-group.filter-group{
        margin-bottom: 24px;
        margin-right: 0;
      }
    }

    .btn-group-toggle{
      border-radius: 30px 30px 30px 30px;
      box-shadow: 0 8px 16px rgba($black,.1);

      .btn-toggle{
        @extend .btn;
        @include button-variant($white, $light-grey, $white, $light-grey);
        color: $blue;
        font-size: 16px;
        padding: 12px 16px 12px 16px;
        cursor: pointer;
        white-space: nowrap;

        display: flex;
        align-items: center;

        &:first-child{
          border-radius: 30px 0 0 30px;
          padding-left: 28px;
        }

        &:last-child{
          border-radius: 0 30px 30px 0;
          padding-right: 28px;
        }

        &.active{
          @include button-variant($blue, $light-grey, $blue, $light-grey);
          color: $white;
        }

        &.focus{
          outline: -webkit-focus-ring-color auto 1px;
        }
      }

      
      @media (max-width: $breakpoint-md-max) {
        border-radius: 0 0 0 0;
        box-shadow: none;
        flex-wrap: wrap;

        .btn-toggle,
        .btn-toggle:first-child,
        .btn-toggle:last-child{
          flex: none;
          border-radius: 30px 30px 30px 30px !important;
          box-shadow: 0 8px 16px rgba($black,.1);
          margin-right: 20px;
          margin-bottom: 25px;
          padding-left: 20px;
          padding-right: 20px;
        }
      }
    }
  }
}
