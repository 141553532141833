
// Headers
h1 {
  font-size: 88px;
  font-family: $font-family-secondary;
  font-weight: $weight-medium;
  text-transform: uppercase;
  letter-spacing: .01em;
  line-height: .91;

  @media (max-width: $breakpoint-sm-max) {
		line-height: 1;
    font-size: 60px;
	}
}

h2 {
  font-size: 44px;
  font-family: $font-family-secondary;
  font-weight: $weight-medium;
  text-transform: uppercase;
  letter-spacing: .01em;
  line-height: 40px;

  @media (max-width: $breakpoint-sm-max) {
		line-height: 30px;
    font-size: 32px;
	}
}

h3 {
  font-size: 44px;
  font-family: $font-family-primary;
  font-weight: $weight-bold;
  line-height: 48px;

  @media (max-width: $breakpoint-sm-max) {
		line-height: 36px;
    font-size: 32px;
	}
}

h4 {
  font-size: 32px;
  font-family: $font-family-primary;
  font-weight: $weight-bold;
  line-height: 36px;

  @media (max-width: $breakpoint-sm-max) {
		line-height: 32px;
    font-size: 28px;
	}
}

h5 {
  font-size: 32px;
  font-family: $font-family-primary;
  font-weight: $weight-light;
  line-height: 36px;

  @media (max-width: $breakpoint-sm-max) {
		line-height: 32px;
    font-size: 28px;
	}
}

h6 {
  font-size: 24px;
  font-family: $font-family-primary;
  font-weight: $weight-regular;
  line-height: 32px;

  @media (max-width: $breakpoint-sm-max) {
		line-height: 28px;
    font-size: 20px;
	}
}