@import './base';

$footer-link-color: $white !default;
$footer-link-color-hover: $white-hover !default;

footer {
  .additional {
    color: $mid-grey;
    font-size: 12px;
    text-align: right;
    padding: 38px 0 0 0;
  }
}
