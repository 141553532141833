/*======================================
  Selectric v1.11.0
======================================*/

.selectric-wrapper {
  position: relative;
  cursor: pointer;
}

.selectric-responsive {
  width: 100%;
}

.selectric {
  position: relative;

  background-color: $white;

  border: 1px solid #e5e5e5;
  border-radius: 0 30px 30px 30px;

  -webkit-box-shadow: 0 8px 16px rgba($black, 0.1);
  -moz-box-shadow: 0 8px 16px rgba($black, 0.1);
  box-shadow: 0 8px 16px rgba($black, 0.1);
}

.selectric .label {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0 38px 0 28px;
  height: 48px;
  line-height: 48px;

  color: $blue;
  font-family: $font-family-primary-alt;
  font-size: 20px;
  text-decoration: none;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.selectric .button {
  display: block;
  position: absolute;
  top: 14px;
  right: 10px;
  height: 38px;
  width: 38px;
  text-align: center;
  color: $blue;

  &:hover {
  }
}

//   .selectric .button:after {
//     content: " ";
//     position: absolute;
//     top: 0;
//     right: 0;
//     bottom: 0;
//     left: 0;
//     margin: auto;
//     width: 0;
//     height: 0;
//     border: 4px solid transparent;
//     border-top-color: #BBB;
//     border-bottom: none;
//   }

// .selectric-focus .selectric {
//   border-color: #aaaaaa;
// }

//   .selectric-hover .selectric {
//     border-color: #c4c4c4;
//   }

//   .selectric-hover {
//     color: #a2a2a2;
//   }

.selectric-hover .selectric .button {
  color: $dark-blue;
}

.selectric-open {
  z-index: 9999;
}

// .selectric-open .selectric {
//   border-color: #c4c4c4;
// }

.selectric-open .selectric-items {
  display: block;
}

.selectric-disabled {
  filter: alpha(opacity=50);
  opacity: 0.5;
  cursor: default;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.selectric-hide-select {
  position: relative;
  overflow: hidden;
  width: 0;
  height: 0;
}

.selectric-hide-select select {
  position: absolute;
  left: -100%;
}

.selectric-hide-select.selectric-is-native {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 10;
}

.selectric-hide-select.selectric-is-native select {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  border: none;
  z-index: 1;
  box-sizing: border-box;
  opacity: 0;
}

.selectric-input {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  margin: 0 !important;
  padding: 0 !important;
  width: 1px !important;
  height: 1px !important;
  outline: none !important;
  border: none !important;
  *font: 0/0 a !important;
  background: none !important;
}

.selectric-temp-show {
  position: absolute !important;
  visibility: hidden !important;
  display: block !important;
}

/* Items box */
.selectric-items {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;

  margin-top: -20px;
  padding-top: 20px;
  padding-bottom: 8px;

  background: $white;
  z-index: -1;

  border: 1px solid $light-grey;
  border-radius: 0 0 20px 20px;
  box-shadow: 0 8px 16px rgba($black, 0.1);

  font-size: 16px;
}

.selectric-items .selectric-scroll {
  height: 100%;
  overflow: auto;
}

.selectric-above .selectric-items {
  top: auto;
  bottom: 100%;
}

.selectric-items ul,
.selectric-items li {
  list-style: none;
  padding: 0;
  margin: 0;
  line-height: 20px;
  min-height: 20px;
}

.selectric-items li {
  display: block;
  cursor: pointer;
  color: $black;
  font-size: 16px;
  padding: 4px 24px;
  background-color: $white;
  min-height: 28px;

  &:hover {
    background-color: #f8f9fa;
  }

  &.selected {
    background-color: rgba($light-blue, .25);
  }

  &.highlighted {
    background: #d0d0d0;
    color: #444;
  }

  &.disabled {
    filter: alpha(opacity=50);
    opacity: 0.5;
    cursor: default !important;
    background: none !important;
    color: #666 !important;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
}

.selectric-items .selectric-group .selectric-group-label {
  font-weight: bold;
  padding-left: 10px;
  cursor: default;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background: none;
  color: #444;
}

.selectric-items .selectric-group.disabled li {
  filter: alpha(opacity=100);
  opacity: 1;
}

.selectric-items .selectric-group li {
  padding-left: 25px;
}
