@import '../../node_modules/bootstrap/scss/mixins/buttons';
@import '../../node_modules/bootstrap/scss/mixins/transition';

$link-color: $blue !default;
$link-color-hover: $dark-blue !default;

$button-primary-background: $orange !default;
$button-primary-background-hover: $orange-hover !default;
$button-primary-text-color: $white !default;

$button-secondary-background: $black !default;
$button-secondary-background-hover: $black-hover !default;
$button-secondary-text-color: $white !default;

.link {
  align-items: center;
  display: inline-flex;
  color: $link-color;
  font-family: $font-family-primary-alt;
  font-size: 20px;
  text-decoration: none;

  &.component{
    margin-right: $inline-component-margin-right;
    margin-bottom: $inline-component-margin-bottom;
  }

  &:hover{
    color: $link-color-hover;
    text-decoration: none;
  }

  &.with-arrow-right,
  &.with-arrow-left{
    i {
      font-size: 32px;
      position: relative;
      top: 2px;
    }
  }

  &.with-arrow-right{
    i{
      transition: padding-left 0.5s ease;
      padding-left: 20px;
    }

    &:hover{
      i{
        padding-left: 40px;
      }
    }
  }

  &.with-arrow-left{
    i{
      padding-right: 20px;
    }
  }

  &.with-icon{
    .text{
      padding-right: 20px;
    }

    .extension{
      padding-right: 8px;
      text-transform: uppercase;
    }
  }
}

.btn-primary {
  @extend .btn;
  @include button-variant($button-primary-background, $button-primary-background, $button-primary-background-hover, $button-primary-background-hover, $button-primary-background, $button-primary-background);
  color: $button-primary-text-color;
}

.btn-secondary {
  @extend .btn;
  @include button-variant($button-secondary-background, $button-secondary-background, $button-secondary-background-hover, $button-secondary-background-hover, $button-secondary-background, $button-secondary-background);
  color: $button-secondary-text-color;
}

.btn-primary,
.btn-secondary {
  align-items: center;
  border-radius: 0 40px 40px 40px;
  padding: 10px 36px 10px 28px;

  font-family: $font-family-primary-alt;
  font-size: 20px;
  text-decoration: none;
  margin: 0 40px 40px 0;

  display: inline-flex;

  .text{
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: auto;
    text-align: left;
    line-height: 1.3;
  }

  .info{
    white-space: nowrap;
  }

  &:hover{
    text-decoration: none;
  }

  &.with-arrow-right,
  &.with-arrow-left{
    .info{
      i {
        font-size: 32px;
      }
    }
  }

  &.with-arrow-right{
    padding-right: 24px;

    .text{
      padding-right: 48px;

      @media (max-width: $breakpoint-sm-max) {
        padding-right: 24px;
      }

      transition: padding-right 0.5s ease;
    }

    &:hover{
      .text{
        padding-right: 68px;

        @media (max-width: $breakpoint-sm-max) {
          padding-right: 44px;
        }
      }
    }
  }

  &.with-arrow-left{
    border-radius: 40px 0 40px 40px;
    padding-left: 20px;
    padding-right: 32px;

    i{
      padding-right: 48px;
    }
  }

  &.with-icon{
    padding-right: 24px;

    .text{
      padding-right: 40px;
    }

    .extension{
      padding-right: 8px;
      text-transform: uppercase;
    }
  }

  &[disabled]{
    @include button-variant($mid-grey, $mid-grey, $mid-grey-hover, $mid-grey-hover, $mid-grey, $mid-grey);
    color: $white;
  }
}



// Background variations
.dark-bg{
  .btn-secondary {
    @include button-variant($grey, $grey, $white, $white, $grey, $grey);
    color: $black;
  }
}
