@keyframes scrollIndicatorBounce {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(8px);
  }
  100% {
    transform: translateY(0);
  }
}

body.homepage {
  #header:not(.scrolled) {
    background: linear-gradient(rgba($black, 0.5), rgba($black, 0));
  }

  .homepage-hero-container {
    height: 100vh;
    max-height: 800px;
    position: relative;

    .page-title-container {
      align-items: center;
      height: 100%;
      margin-bottom: 0;
      transition: height 600ms ease;

      img {
        left: 50%;
        transform: translateX(-50%) scale(1);
      }

      .image-disclaimer--container {
        position: absolute !important;
        bottom: 20px;

        &.sits-high {
          bottom: 75px;
          transition: bottom 600ms ease;
        }

        .container {
          .image--disclaimer {
            display: inline-block;
            width: 200px;
            text-align: left;
            left: 12px;
          }
        }
      }

      &.shorten {
        height: calc(100% - 102px);

        img {
          transform: translateX(-50%) scale(1);
        }

        .image-disclaimer--container {
          bottom: 20px;
        }

        .home-titles {
          margin: 0 -30px;

          @media (min-width: $breakpoint-md-min) {
            margin: 0 -42px;
          }
          @media (min-width: $breakpoint-lg-min) {
            margin: 0 -50px;
          }
        }
      }

      .video-container {
        display: none;
        height: 100%;
        width: 100%;
        overflow: hidden;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;

        video {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
      }

      .background-container {
        margin-top: 100px;
        position: relative;
        z-index: 1;

        .container {
          text-align: center;
        }
      }

      .home-titles {
        transition: margin 400ms ease;

        display: flex;
        justify-items: center;
        flex-direction: column;
        flex-wrap: n;
      }

      .home-subtitle {
        color: $white;
        letter-spacing: 0.03em;
        margin: 4px 0 12px;
      }

      .home-title {
        font-size: 60px;
        line-height: 1;
        margin-bottom: 0;
        transition: letter-spacing 600ms ease;
        white-space: nowrap;

        &.letter-expand {
          letter-spacing: 0.125em;
        }
      }

      .btn-primary {
        align-items: center;
        background-color: $white;
        border-color: $white;
        color: $black;
        cursor: pointer;
        margin-right: 0;
        margin-top: 16px;

        i {
          transition: padding-left 0.5s ease;
          padding-left: 20px;
        }

        &:hover {
          i {
            padding-left: 40px;
          }
        }
      }

      .scroll-indicator {
        display: block;
        color: $white;
        cursor: pointer;
        font-size: 52px;
        line-height: 1;
        width: 80px;
        margin: 0 auto;
        opacity: 0;
        transform: translateY(8px);
        transition: opacity 300ms ease, transform 300ms ease;
        visibility: hidden;

        i {
          text-shadow: 0 0 6px rgba(0, 0, 0, 0.6);
        }

        &.show {
          visibility: visible;
          animation: scrollIndicatorBounce 2000ms ease infinite;
          opacity: 1;
          transform: translateY(0);

          &:hover {
            animation-play-state: paused;
            transform: translateY(12px);
          }
        }
      }
    }

    .hero-container-nav {
      bottom: 0;
      margin: 40px auto 0;
      padding: 0 50px;
      position: absolute;
      width: 100%;
      min-height: 100px;
      color: $white;

      .container {
        align-items: center;
        display: flex;
        justify-content: flex-end;
        padding: 0;
      }

      &.has-personas {
        justify-content: space-between;
      }

      .personas-title {
        font-weight: $weight-black;
      }

      .personas-title,
      ul a {
        display: block;
        font-size: 16px;
        padding: 40px 12px;
        text-transform: uppercase;
        transition: color 200ms 100ms ease;
      }

      ul {
        align-items: center;
        display: flex;
        flex-grow: 1;
        justify-content: space-between;
        list-style: none;
        margin: 0 auto;
        padding: 0;
        position: relative;
        z-index: 1;

        a {
          text-decoration: none;
          color: $white;
          &:hover {
            color: $blue;
          }
        }
      }

      .bg-video-controls {
        cursor: pointer;
        margin-left: 52px;
        position: relative;
        width: 52px;
        z-index: 1;
        padding-top: 40px;
        padding-bottom: 40px;

        .control-label {
          position: absolute;
          top: 15px;
          left: 50%;
          white-space: nowrap;
          width: 100%;
          transform: translateX(-50%);
          pointer-events: none;

          span {
            color: $white;
            font-family: $font-family-primary-alt;
            font-size: 12px;
            position: absolute;
            top: 4px;
            left: 50%;
            text-transform: uppercase;
            opacity: 0;
            height: 0;
            width: auto;
            transform: translateX(-50%);
            transition: color 100ms 0ms ease, opacity 200ms, top 200ms;
          }
        }

        .video-toggle {
          align-items: center;
          border: 1px solid transparent;
          border-radius: 999px;
          display: flex;
          justify-content: space-around;
          padding: 8px 4px;
          transition: border 200ms;

          .fas {
            color: $white;
            font-size: 10px;
            transition: opacity 200ms;
          }
        }

        &.play {
          .fa-play {
            opacity: 1;
          }

          .fa-stop {
            opacity: 0.4;
          }
        }

        &.stop {
          .fa-play {
            opacity: 0.4;
          }

          .fa-stop {
            opacity: 1;
          }
        }

        &:hover,
        &:focus {
          .video-toggle {
            border-color: $white;
          }

          &.play {
            .stop-label {
              top: 0;
              opacity: 1;
              height: auto;
              width: auto;
            }

            .fa-play {
              opacity: 0.4;
            }

            .fa-stop {
              opacity: 1;
            }
          }

          &.stop {
            .play-label {
              top: 0;
              opacity: 1;
              height: auto;
              width: auto;
            }

            .fa-play {
              opacity: 1;
            }

            .fa-stop {
              opacity: 0.4;
            }
          }
        }
      }

      &:before {
        background: rgb(0, 0, 0);
        background: linear-gradient(
          0deg,
          rgba(0, 0, 0, 0.5) 0%,
          rgba(0, 0, 0, 0) 100%
        );
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        transition: height 600ms ease;
      }

      &.white-bg {
        background: $white;
        transition: background 200ms 400ms ease;

        .personas-title,
        ul a {
          color: $black;
          transition: color 600ms ease;
        }

        ul a:hover {
          color: $blue;
        }

        .bg-video-controls {
          .control-label span,
          .video-toggle .fas {
            color: $black;
            transition: color 100ms 200ms ease;
            @media (max-width: $breakpoint-sm-max) {
              color: $white;
            }
          }

          &:hover .video-toggle {
            border-color: $black;
          }
        }

        &:before {
          height: 0;
        }
      }
    }

    .home-video-component {
      display: none;
      margin: 0 auto;
      max-width: 1500px;

      .video-player-container {
        margin-bottom: 0;
      }

      .plyr__controls__item.plyr__volume {
        display: none;
      }
    }

    &.video-hero {
      img {
        display: none;
      }

      .video-container {
        display: block;
      }
    }

    &.video-playing {
      background: #25282e;
      max-height: none;
      height: auto;
    }
  }

  // Medium && Small Screen
  @media (max-width: $breakpoint-md-max) {
    .homepage-hero-container {
      height: auto;
      max-height: none;

      .page-title-container {
        max-height: 600px;
        height: 100vh;

        img {
          height: 100%;
        }

        .background-container {
          margin-top: 70px;
        }
      }

      .hero-container-nav {
        background: $white;
        margin: 0 auto;
        padding-top: 20px;
        padding-bottom: 20px;
        position: relative;

        .container {
          align-items: flex-start;
        }

        ul {
          flex-wrap: wrap;
        }

        .personas-title,
        ul a {
          color: $black;
          padding: 12px;
        }

        .personas-title {
          flex: 1 1 100%;
        }

        .bg-video-controls {
          margin-top: 8px;

          .control-label {
          }
        }

        &:before {
          display: none;
        }
      }

      .home-video-component {
        margin-top: 70px;
      }
    }
  }

  // Medium
  @media (min-width: $breakpoint-md-min) and (max-width: $breakpoint-md-max) {
    .homepage-hero-container {
      .page-title-container {
        .home-title {
          // font-size: 120px;
          letter-spacing: 0.05em;
        }
      }

      .hero-container-nav .bg-video-controls {
        .control-label span,
        .video-toggle .fas {
          color: $black;
          transition: color 100ms 200ms ease;
        }

        &:hover .video-toggle {
          border-color: $black;
        }
      }
    }
  }

  // Small Screen
  @media (max-width: $breakpoint-sm-max) {
    .homepage-hero-container {
      .page-title-container {
        .home-subtitle {
          margin: 0;
        }

        .home-title {
          // font-size: 60px;
          letter-spacing: 0.05em;
          margin: 8px 0;

          // on small screen (like 330px wide), the text to go closer to the screen edges to allow for more room for the text
          .home-title__text {
            margin-left: -35px;
            margin-right: -35px;
            text-align: center;
          }
        }
      }

      .hero-container-nav {
        margin-top: 0;
        padding: 0;

        ul {
          flex-direction: column;
        }

        .personas-title,
        ul li,
        ul a {
          width: 100%;
        }

        .personas-title {
          background: $white;
          border-bottom: 1px solid $border-grey;
          padding: 24px 30px;
        }

        .bg-video-controls {
          margin-top: -10px;
          margin-left: 0;
          position: absolute;
          top: 0;
          right: 30px;
          width: 80px;
          transform: translateY(-100%);
          z-index: 1;

          .control-label {
            display: none;
          }

          .video-toggle {
            border-color: $white;

            .fas {
              font-size: 14px;
            }
          }
        }

        ul a {
          background: $light-grey;
          padding: 20px 30px;
        }

        &:before {
          display: none;
        }
      }
    }
  }

  &.initializing {
    #eyebrow-nav {
      visibility: hidden;
    }
  }

  &:not(.initializing) {
    #eyebrow-nav {
      //overflow: hidden;
    }
  }

  // This is because IE is the worst..
  // When scrolling down, the hero becomes to narrow and you see white on the sides
  // This prevents that.
  &.is-ie,
  &.is-edge {
    .page-title-container {
      img {
        transform: translateX(-50%) scale(1);
      }

      &.shorten {
        img {
          transform: translateX(-50%) scale(1.1);
        }
      }
    }
  }
}
