@import '../../node_modules/bootstrap/scss/mixins/buttons';
@import '../../node_modules/bootstrap/scss/mixins/transition';

.rich-text {
  width: 100%;
  margin-bottom: $component-margin-bottom;
  font-size: 20px;
   
  sup,
  sub{
    font-size: .60em;
    font-family: $font-family-primary-alt;
    font-weight: $weight-bold;
  }

  sup{
    top: -3px
  }

  sub{
    bottom: -4px
  }

  // Override the indent with our own indent amount
  [style*="margin-left:"]{
    margin-left: 32px !important;
  }

  ol,
  ul{
    font-family: $font-family-primary-alt;
    font-weight: $weight-medium;
    padding-left: 38px;
    
    li{
      margin-bottom: 16px;
    }
  }
  ul{
    list-style-type: square;
  }

  a:not(.btn){
    color: $blue;
    font-family: $font-family-primary-alt;
    font-size: 20px;
    text-decoration: none;

    &:hover{
      color: $dark-blue;
      text-decoration: none;
    }
  }
}

.one-col:not(.margin-large) {
  .rich-text {
    @media (min-width: $breakpoint-lg-min) {
      width: 66%;
    }
  }
}