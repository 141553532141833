#search-page {
  .page-title-container {
    .background-container {
      padding-bottom: 0;
    }
  }

  .header-section {
    padding-bottom: 0;
  }

  #result-summary-container {
    margin-bottom: 20px;
    .result-summary {
      .count {
        font-size: 32px;
        font-weight: $weight-light;
      }
      .term {
        font-family: $font-family-secondary;
        font-size: 44px;
        letter-spacing: 0.044em;

        text-transform: uppercase;
      }
    }

    .error {
      font-size: 32px;
      font-weight: $weight-bold;
      color: $red;

      padding-top: 80px;
      padding-left: 40px;
    }
  }

  .result-section {
    padding-top: 0;
    @media (max-width: $breakpoint-sm-max) {
      overflow-wrap: break-word;
    } 
    #result-list-container {
      margin-bottom:20px;
      .result-item {
        padding-top: 60px;

        .title {
          line-height: 0.5;

          a {
            font-family: $font-family-secondary;
            font-size: 32px;
            letter-spacing: 0.044em;

            text-transform: uppercase;
            text-decoration: none;
            line-height: 36px;
          }
        }

        .type {
          display: inline;
          text-transform: uppercase;
          font-size: 16px;
          color: $mid-grey;
          font-weight: $weight-bold;
          padding-right: 20px;
        }

        .date {
          display: inline;

          text-transform: uppercase;
          font-size: 16px;
          color: $mid-grey;
        }

        .excerpt {
          font-size: 16px;
        }
      }
    }
    
  }
  .placeholder-content{
    padding-left: 10px;

    @media (max-width: $breakpoint-md-max) {
      margin-top: 88px;
    }
  }
}
