.tabs-container {
  padding-bottom: 25px;
  max-width: 100%;
  margin-bottom: $component-margin-bottom;

  .tab-list {
    border-bottom: 1px solid $border-grey;
    margin-bottom: 55px;

    .col-12 {
      height: 100%;
      width: 100%;
      overflow: hidden;

      padding-left: 0;
      padding-right: 0;

      display: flex;
      flex-direction: row;
      position: relative;

      .nav-scroll-btn {
        width: 25px;

        background-color: $border-grey;
        color: $white;
        text-align: center;
        padding-top: 42px;
        cursor: pointer;

        position: absolute;
        top: 0;
        bottom: 0;

        &.nav-scroll-left {
          left: 0;
        }

        &.nav-scroll-right {
          right: 0;
        }

        &.disabled {
          visibility: hidden;
        }

        @media (min-width: $breakpoint-md-min) and (max-width: $breakpoint-md-max) {
          width: 35px;
        }
      }

      .nav-scroll-container {
        overflow-x: auto;
        flex: 1;
        display: flex;
        -webkit-overflow-scrolling: touch;

        // scrollable with hidden scrollbar
        scrollbar-width: none;
        -ms-overflow-style: none;

        &::-webkit-scrollbar {
          height: 0 !important;
        }
        // end scrollable with hidden scrollbar

        ul {
          list-style-type: none;
          padding: 0;
          margin: 0;

          white-space: nowrap;

          li {
            display: inline-block;

            font-family: $font-family-primary-alt;
            color: $blue;
            font-size: 16px;
            text-transform: uppercase;
            text-decoration: none;
            font-weight: $weight-bold;

            display: inline-block;
            padding: 40px 20px;
            margin: 0;

            @media (max-width: $breakpoint-sm-max) {
              padding-left: 15px;
              padding-right: 15px;
            }

            &:hover,
            &:focus {
              color: $dark-blue;
              text-decoration: none;

              background-color: $light-grey;
            }

            &.active {
              background-color: $light-grey;
              color: $black;
            }
          }
        }
      }
    }
  }

  .tab-content {
    > div {
      display: none;

      &.active {
        display: inline;
      }

      h2 {
        margin-bottom: 22px;
      }
    }
  }
}

// Background Variants

.light-bg {
  .tabs-container {
    .tab-list {
      border-color: $mid-grey;
      .col-12 {
        .nav-scroll-container {
          ul {
            li {
              &:hover,
              &:focus {
                background-color: $light-bg-body-bg;
              }

              &.active {
                background-color: $light-bg-body-bg;
              }
            }
          }
        }
      }
    }
  }
} //.light-bg

.dark-bg {
  .tabs-container {
    .tab-list {
      .col-12 {
        .nav-scroll-btn {
          background-color: $black-hover;
        }

        .nav-scroll-container {
          ul {
            li {
              color: $blue;

              &:hover,
              &:focus {
                background-color: $dark-bg-body-bg;
              }

              &.active {
                background-color: $dark-bg-body-bg;
                color: $white;
              }
            }
          }
        }
      }
    }
  }
} //.dark-bg

// End Background Variants
