#executive-blog-detail-page {
  .page-title-container {
    background: linear-gradient($white, $light-grey);

    @media (max-width: $breakpoint-md-min) {
      margin-bottom: 0;
    }

    .background-container {
      padding-bottom: 0;
    }

    .blog-title {
      display: flex;
      flex-direction: column;

      position: relative;
      padding-bottom: 40px;

      h1 {
        flex: 1;
      }
    }

    .blog-info {
      margin-top: 20px;


      @media (max-width: $breakpoint-sm-max) {
        .main-date-container {
          margin-top: 20px;
          margin-bottom: 20px;
          margin-right: 12px;

          .tag-label,
          .author {
            display: none;
          }
        }

        .secondary-date-container {
            display: inline-block;
            margin-right: 12px;
            margin-bottom: 20px;
        }
      }

      .secondary-date-container .detail-date-container {
          margin-bottom: 0;
      }

      @media (min-width: $breakpoint-md-min) {
        .secondary-date-container{
          display: none;
        }
      }

      @media (min-width: $breakpoint-md-min) {
        //position: absolute;
        //top: 100%;
        //left: 0;
        //z-index: 2;
        //margin-top: -20px;
      }
    }

    .blog-img {
      display: flex;
      align-items: flex-end;

      img {
        max-width: 480px;
        width: 100%;
      }
    }
  }

  .placeholder-container {
    padding-top: 0;
  }

  .page-title-container + .background-container {
    padding-top: 0;
  }
}

// Latest Executive Blog
.latest-executive-blog {
  $post-spacing: 22px;

  margin-bottom: $component-margin-bottom;

  .component-header {
    display: flex;

    @media (max-width: $breakpoint-sm-max) {
      flex-direction: column;
    }

    .component-title {
      flex: 1;
      text-transform: uppercase;
    }

    .view-all {
      a {
        text-decoration: none;
        font-family: $font-family-link;
        font-weight: $weight-semibold;
      }
    }
  }

  .posts {
    white-space: nowrap;
    display:flex;

    .post {
      margin-top: 35px;
      margin-bottom: 20px;
      white-space: normal;

      display: inline-block;
      position: relative;
      min-height: 100%;

      margin-right: 20px;
      &:last-child{
        margin-right: 0;
      }

      padding-bottom: 20px;

      background-color: $light-grey;
      border-radius: 0 0 30px 0;

      a {
        text-decoration: none;

        .post-date {
          position: absolute;
          top: -25px;
          left: -1px;
          z-index: 2;
        }

        .post-image-container {
          position: relative;
          overflow: hidden;
          width: 100%;
          height: 220px;

          .post-image {
            position: absolute;
            height: 100%;
            width: 100%;
            object-fit: cover;
            top: 50%;
            left: 50%;
            transform: translateX(-50%) translateY(-50%);
            transition: transform $transition-speed $transition-style;
          }
        }

        .post-title,
        .post-body {
          margin-left: 25px;
          margin-right: 25px;
        }

        .post-title {
          margin-top: 20px;
          color: $blue;
        }
        .post-body {
          color: $black;
        }
      }

      &:hover {
        background-color: $blue;
        -webkit-box-shadow: 0px 2px 12px rgba($black, 0.2);
        -moz-box-shadow: 0px 2px 12px rgba($black, 0.2);
        box-shadow: 0px 2px 12px rgba($black, 0.2);

        a {
          .post-image {
            transform: translate(-50%, -50%) scale(1.1);
          }

          .post-title,
          .post-body {
            color: $white;
          }
        }
      }
    }
  }

  &.scrollable-posts {
    .posts {
      .post {
        width: 100%;

        @media (min-width: $breakpoint-md-min) {
          min-width: calc(33% - #{$post-spacing});
          width: calc(33% - #{$post-spacing});
        }

        .post-image-container {
          height: 221px;
        }
      }
    }
  }

  &.double-post,
  &.single-post {
    .posts {
      .post {
        min-width: calc(50% - #{$post-spacing});
        width: calc(50% - #{$post-spacing});
      }
    }
  }

  &.double-post{
    .posts {
      .post {
        min-width: calc(50% - #{$post-spacing});
        width: calc(50% - #{$post-spacing});
      }
    }
  }

  &.single-post {
    .posts {
      .post {
        width: 100%;

        @media (min-width: $breakpoint-lg-min) {
          min-width: 70%;
          width: 70%;
        }
      }
    }
  }

  &.img-right{
    .post{

      margin-bottom: 0;
      padding-bottom: 0;
      border-radius: 30px 0 30px 0;

      a {
        display: flex;
        flex-direction: row-reverse;

        .post-date {
          top: -1px;
        }

        .post-image-container {
          height: auto;
          border-radius: 0 0 30px 0;

          .post-image {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        .post-content{
          padding-top: 55px;
          padding-bottom: 35px;
        }
      }
    }
  }
}


// Background variations
.light-bg{
  .latest-executive-blog {
    .posts {
      .post {
        background-color: $white;
        &:hover {
          background-color: $blue;
        }
      }
    }
  }
}
// End Background variations

// IE Hacks
.is-ie #executive-blog-detail-page {
  .blog-title{
    h1{
      min-height: 80px;
    }
  }
}
// End IE Hacks

// End Latest Executive Blog
