// Product Listing Page
#product-listing-page {
  .page-title-container {
    background: linear-gradient($white, $light-grey);

    .summary {
      text-align: center;
    }
  }

  #product-listing-filters{
    padding-bottom: 60px;

    .filter-row {
        justify-content: center;

        .col-12{
          padding-left: 0;
          padding-right: 0;
        }
    }
  }

  #product-listing-list-container {
    .wrapping-table {

      .table-body {
        .row {
          > div {
            vertical-align: top;

            &.product-name {
              flex: 3;
              padding-right: 10px;

              a {
                .product-title {
                  display: inline-block;
                  font-size: 24px;
                  padding-right: 20px;
                }

                .product-info {
                  display: inline-block;
                  font-size: 16px;
                  vertical-align: text-top;
                }

                @media (max-width: $breakpoint-sm-max) {
                  .product-title {
                    padding-right: 0;
                  }

                  .product-info {
                    display: block;
                  }
                }
              }
            }

            &.therapeutic-area {
              flex: 2;

              font-size: 16px;
              padding-top: 5px;

              @media (max-width: $breakpoint-sm-max) {
                padding-top: 0;
              }
            }
          }
        }
      }
    }
    // .wrapping-table

    .no-results{
      font-size: 32px;
      font-weight: 700;
      padding-top: 80px;
      text-align: center;
    }
  }
  // #product-listing-list-container
}

// End Product Listing Page

// Product Detail Page
#product-detail-page {
  padding-top: $header-height-sm + 0px;

  @media (min-width: $breakpoint-md-min) {
    padding-top: $header-height-md + 0px;
  }

  @media (min-width: $breakpoint-lg-min) {
    padding-top: $header-height-lg + 0px;
  }

  .alert {
    background-color: $red;
    color: $white;
    border-radius: 0;
    font-family: $font-family-primary-alt;

    .alert-title {
      font-size: 24px;
    }

    .alert-text {
      a {
        color: $white;
        text-decoration: none;

        i {
          padding-left: 15px;
        }
      }
    }
  }

  .product-info {
    padding-top: 40px;
    padding-bottom: 20px;

    .view-all {
      color: $black;
      display: inline-block;
      font-family: $font-family-primary-alt;
      text-transform: uppercase;
      text-decoration: none;
      font-weight: $weight-semibold;
      margin-bottom: 20px;

      i {
        padding-right: 10px;
      }
    }

    .name {
      margin-top: 30px;
      text-transform: none;
    }

    .details {
      padding-top: 40px;
      font-weight: $weight-bold;

      a {
        display: inline-block;
        padding-top: 5px;
        font-weight: $weight-semibold;
      }
    }

    .product-logo {
      img {
        width: 100%;
      }
    }
  }

  .providers-container,
  .patients-container {
    margin-top: 25px;
    border-top: 1px solid $border-grey;
    padding-top: 35px;
    padding-bottom: 14px;

    h4 {
      padding-bottom: 32px;
    }

    .contact {
      padding-top: 32px;

      &:first-child {
        padding-top: 0;
      }

      padding-right: 75px;

      .contact-label,
      .contact-info {
        padding-bottom: 18px;
      }

      .contact-label {
        font-weight: $weight-bold;
      }

      .contact-details {
        .label {
          font-weight: $weight-bold;
          padding-right: 20px;
        }

        .details {
        }
      }
    }

    .link-list {
      @media (max-width: $breakpoint-sm-max) {
        padding-top: 35px;

        ul {
          margin-bottom: 0;

          li:last-child {
            padding-bottom: 0;
          }
        }
      }
    }
  }
}

// End Product Detail Page

// PAP Product Listing Page
#pap-product-listing-page {
  .page-title-container {
    background: linear-gradient($white, $light-grey);

    .view-all {
      padding-top: 30px;
      text-align: center;

      a {
        text-decoration: none;
        font-family: $font-family-primary-alt;
        font-weight: $weight-semibold;
      }
    }

    .summary {
      text-align: center;
    }

    &.with-hero {
      .view-all {
        a {
          text-decoration: underline;
          color: $white;
        }
      }
    }
  }

  #product-listing-filters{
    padding-bottom: 60px;

    .filter-row {
        justify-content: center;

        .col-12{
          padding-left: 0;
          padding-right: 0;
        }
    }
  }

  #product-listing-list-container {
    .wrapping-table {
      font-family: $font-family-primary;

      .table-header {
        .row {
          > div {
            font-size: 32px;
            font-weight: $weight-bold;
            white-space: nowrap;

            &.product-name {
              flex: 3;
            }
            &.therapeutic-area {
              flex: 2;
            }

            &.sortable {
              span {
                &:before,
                &:after {
                  color: rgba($blue, 0.5);
                }
              }

              &.sort-asc {
                span {
                  &:before {
                    color: $blue;
                  }
                }
              }

              &.sort-desc {
                span {
                  &:after {
                    color: $blue;
                  }
                }
              }
            }
          }
        }
      }

      .table-body {
        .row {
          > div {
            vertical-align: top;

            &.product-name {
              flex: 3;
              padding-right: 10px;

              a {
                font-weight: $weight-bold;
                text-decoration: none;

                .product-title {
                  display: inline-block;
                  font-size: 24px;
                  padding-right: 20px;
                }

                .product-info {
                  display: inline-block;
                  font-size: 16px;
                }

                @media (max-width: $breakpoint-sm-max) {
                  .product-title {
                    padding-right: 0;
                  }

                  .product-info {
                    display: block;
                  }
                }
              }
            }

            &.therapeutic-area {
              flex: 2;

              font-size: 16px;
              vertical-align: top;
              padding-top: 9px;

              @media (max-width: $breakpoint-md-max) {
                padding-top: 0;
              }
            }
          }
        }
      }
    }
    // .wrapping-table

    .no-results{
      font-size: 32px;
      font-weight: 700;
      padding-top: 80px;
      text-align: center;
    }
  }
  // #product-listing-list-container
}

// End PAP Product Listing Page

// PAP Product Detail Page
#pap-product-detail-page {
  .page-title-container {
    .pap-label {
        color: $dark-blue;
        font-weight: bold;
        margin-bottom: 16px;
    }
    h1 {
      text-transform: none;
    }
  }
}

// End PAP Prod
