@media (max-width: $breakpoint-md-max) {
  #header {
    padding: 0;

    .link {
      margin-right: 0;
      margin-bottom: 0;
    }

    #main-nav-mobile-container {
      position: relative;
      z-index: $z-index-mobile-nav;
      background-color: $white;
      color: $black;
      font-size: 20px;

      .container {
        display: flex;
        z-index: 1;
        flex-direction: row;
        line-height: 70px;

        font-size: 16px;
        font-family: $font-family-primary-alt;
        font-weight: $weight-bold;
        text-transform: uppercase;

        .left-col {
          @media (min-width: $breakpoint-md-min) {
            width: 100px;
          }
          .open-nav {
            cursor: pointer;
          }
        }

        .middle-col {
          position: absolute;
          top: 0;
          left: 50%;
          height: 100%;
          text-align: center;
          transform: translateX(-50%);

          img {
            height: 31px;
          }

          span {
            font-size: 32px;
            color: $dark-blue;
            font-weight: $weight-bold;
            font-style: italic;
            text-decoration: none;
          }
        }

        .right-col {
          @media (min-width: $breakpoint-md-min) {
            width: 120px;
          }
          text-align: right;
          white-space: nowrap;

          a {
            margin-left: 20px;

            &:first-child {
              margin-left: 0;
            }

            color: $black;
            &:hover {
              color: $black-hover;
            }
          }
        }
      }

      .main-nav-menu {
        position: fixed;
        top: 0;

        height: 100%;

        $sm-width: 100%;
        $md-width: 375px;

        // on small, take up 100% of screen
        width: $sm-width;

        // on medium, take up 375px of screen
        @media (min-width: $breakpoint-md-min) {
          width: $md-width;
        }

        // Start off so its just off the screen to the left
        left: -$sm-width;
        @media (min-width: $breakpoint-md-min) {
          left: -$md-width;
        }

        background-color: $light-grey;

        .main-nav-menu-overlay {
          position: fixed;
          z-index: 1;
          top: 0;
          left: 0;
          height: 100vh;
          width: 100vw;
          pointer-events: none;
          background-color: $black;

          transition: opacity $transition-speed $transition-style;
          opacity: 0;
        }

        &.open {
          .main-nav-menu-overlay {
            pointer-events: all;
            opacity: 0.65;
          }
        }

        .main-nav-menu-header {
          position: relative;
          z-index: 2;
          height: $mobile-main-nav-menu-header-height;
          background-color: $black;
          color: $white;
          padding: 0 30px;

          display: flex;
          justify-content: space-between;

          .left-col,
          .middle-col,
          .right-col {
            align-items: center;
            display: flex;
          }

          .middle-col {
            position: absolute;
            top: 0;
            left: 50%;
            width: 125px;
            height: 100%;
            margin-left: -62.5px;

            display: flex;
            justify-content: center;

            .logo {
              max-height: 31px;
              max-width: 125px;
            }

            .text {
              display: block;
              max-width: 125px;

              color: $white;
              text-transform: uppercase;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;

              transition: color $transition-speed $transition-style;

              &:hover {
                color: $blue;
                text-decoration: none;
              }
            }
          }

          a {
            &:hover {
              text-decoration: none;
            }
          }

          .close-nav {
            cursor: pointer;

            font-size: 16px;
            font-family: $font-family-primary-alt;
            font-weight: $weight-bold;
            text-transform: uppercase;

            transition: color $transition-speed $transition-style;

            &:hover {
              color: $blue;
            }
          }

          .country-selector {
            font-size: 12px;

            .fa-globe {
              font-size: 16px;
            }

            .fa-chevron-down {
              font-size: 10px;
            }
          }
        }

        .main-nav-menu-header-additional {
          position: relative;
          z-index: 2;
          height: 35px;
          background-color: $black;
          color: $white;
          padding: 0 30px;

          display: flex;
          justify-content: flex-end;
        }

        .main-nav-links-container {
          position: relative;
          z-index: 2;
          height: 100%;
          border-top: 8px solid $light-grey;
          background-color: $light-grey;

          .main-nav-menu-links-section {
            width: 100%;
            height: 100%;
            background-color: $light-grey;

            .main-nav-menu-links {
              margin-top: 8px;

              &:first-child {
                margin-top: 0;
              }

              ul {
                list-style-type: none;
                padding: 0;
                margin: 0;

                li {
                  &.spacer {
                    height: 7px;
                  }

                  .nav-link,
                  a {
                    display: block;
                    width: 100%;
                    background-color: $white;
                    cursor: pointer;

                    padding: 16px 56px 16px 30px;
                    border-bottom: 1px solid $light-grey;

                    font-family: $font-family-primary-alt;
                    font-weight: $weight-semibold;
                    color: $blue;
                    text-transform: uppercase;
                    text-decoration: none;

                    position: relative;

                    &.segmented-link {
                      &::after {
                        font-family: $font-awesome-pro;
                        font-size: 16px;
                        content: "\f054";

                        position: absolute;
                        top: 16px;
                        right: 56px;
                      }
                    }

                    &.secondary-link {
                      font-family: $font-family-primary;
                      font-weight: $weight-bold;
                      font-size: 12px;
                      color: $dark-blue;
                      text-transform: none;

                      background-color: $light-grey;
                      border-bottom: none;

                      &::after {
                        content: "";
                      }
                    }

                    &.view-all {
                      color: $dark-blue;
                    }

                    .descr {
                      display: block;
                      font-family: $font-family-primary;
                      font-weight: $weight-light;
                      font-size: 9px;
                    }
                  }

                  &.segment-name {
                    padding: 16px 56px 16px 30px;
                    font-size: 20px;
                    font-family: $font-family-primary-alt;
                    font-weight: $weight-semibold;
                    color: $black;
                    cursor: pointer;
                    border-bottom: 1px solid $white;

                    i {
                      font-size: 16px;
                      padding-right: 6px;
                    }
                  }

                  &.group-title {
                    display: block;
                    width: 100%;

                    padding: 18px 56px 18px 30px;
                    border-bottom: 1px solid $white;

                    font-family: $font-family-primary;
                    font-weight: $weight-bold;
                    color: $dark-grey;
                    font-size: 12px;
                  }

                  &.expandable {
                    > .nav-link {
                      position: relative;

                      &::after {
                        font-family: $font-awesome-pro;
                        font-size: 16px;
                        content: "\f067"; // expand icon

                        position: absolute;
                        top: 16px;
                        right: 56px;
                      }
                    }

                    ul {
                      overflow: hidden;

                      // start off hidden
                      height: 0;

                      .nav-link,
                      a {
                        color: $dark-blue;
                        background-color: $light-grey;
                        border-bottom: 1px solid $white;
                      }

                      .section-title {
                        background-color: $light-grey;
                        border-bottom: 1px solid $white;
                      }
                    }

                    &.open {
                      > .nav-link {
                        // change the icon to collapse
                        &::after {
                          content: "\f068";
                          top: 16px;
                        }
                      }
                    }
                  }
                }
              }
            }

            &.segmented-nav {
              position: absolute;
              top: 0;
              right: 100%;
              z-index: 1;

              display: none;
            }
          }
        }
      }
    }
  }

  &.menu-open {
    overflow: hidden;
  }
}
