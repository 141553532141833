@import '@chenfengyuan/datepicker/dist/datepicker.min';

@import "../../link/styles/base";

form {
  [class^="col-"] {
    padding-bottom: 20px;
  }

  label {
    font-weight: $weight-bold;
  }

  input:not([type="hidden"]):not([type="submit"]),
  select,
  textarea {
    box-shadow: 0 8px 16px rgba($black, 0.1);
    border: 1px solid $border-grey;
    color: $blue;
    display: block;
    padding-left: 28px;
    padding-right: 56px;
    height: 48px;
    font-family: $font-family-primary-alt;
    font-size: 20px;
    outline: 0;
    text-decoration: none;
    text-align: left;
    width: 100%;

    &[type=number]{
      padding-right: 20px;
    }

    &[type=date]
    {
      padding-right: 10px;
    }

    &::placeholder {
      font-size: 16px;
      text-transform: uppercase;
      color: $light-blue;
      opacity: 1;
    }

    &:-ms-input-placeholder {
      color: $light-blue;
    }

    &::-ms-input-placeholder {
      color: $light-blue;
    }

    &.error {
      border-color: $red;
    }
  }

  input:not([type="hidden"]):not([type="submit"]) {
    border-radius: 30px;
  }

  textarea {
    border-radius: 30px 30px 30px 30px;
    height: 220px;
  }

  select {
    background: #fff;
    border-radius: 0 30px 30px 30px;
  }

  label.required{
    position: relative;

    &::after{
      content: '*';
    }
  }

  .form-check {
    display: flex;
    flex-direction: column;
    margin: 20px 0 0 0;

    label:not(.form-check-label) {

      // Radio\Checkbox Icon
      padding-left: 25px;
      position: relative;
      cursor: pointer;

      input[type=checkbox],
      input[type=radio]{
        position: absolute;
        top: 4px;
        left: 0;
        height: 0;
        width: 0;
        margin: 0;

        &::before {
          content: " ";
          display: inline-block;
          position: absolute;
          top: 0;
          left: 0;
          height: 16px;
          width: 16px;
          background: $white;
          border: 1px solid $border-grey;
          box-shadow: 0 4px 8px rgba($black, 0.1);
          cursor: pointer;
        }

        &:checked{
          &::before {
            background: $blue;
          }
        }
      }
      input[type=checkbox]{
        &::before {
          border-radius: 5px;
        }
      }
      input[type=radio]{
        &::before {
          border-radius: 16px;
        }
      }
      // End Radio\Checkbox Icon

      // Label
      text-transform: uppercase;
      color: $blue;
      font-size: 16px;
      font-weight: $weight-regular;
      // End Label

      &:hover{
        input[type=checkbox],
        input[type=radio]{
          &::before {
            border-color: $light-blue;
          }
        }

        text-decoration: underline;
      }

      input[type=checkbox],
      input[type=radio]{
        &:focus{
          &::before {
            border-color: $light-blue;
          }
        }
      }
    }
  }

  .field-validation-error > span{
    display: inline-block;
    margin-top: 8px;
  }
}



// IE and Edge the checkboxes and radio buttons pseudo styles dont work,
//  so just show the original checkboxes and radio buttons
.is-ie,
.is-edge {
  form {
    
    .form-check {
    
      label:not(.form-check-label) {
        input[type=checkbox],
        input[type=radio]{
          height: 16px;
          width: 16px;
        }
      }
    }
  }
}