.image-component-container {
  display: inline-block;
  margin-right: 40px;
  margin-bottom: 40px;
  max-width: 100%;

  &.full-width {
    display: block !important;

    img {
      width: 100%;
    }
  }

  .image-top-container {
    position: relative;

    .image-container {
      img {
        max-width: 100%;
      }
    }

    .image-content-container {
      position: absolute;
      z-index: 2;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;

      display: flex;
      flex-direction: column;
      justify-content: flex-end;

      color: $image-content-color;
      font-family: $font-family-primary;

      padding-bottom: 40px;

      > * {
        margin-left: 32px;
        margin-right: 200px;
      }
      @media (max-width: $breakpoint-sm-max) {
        > *{
          margin-right: 32px;
        }
      }

      .pre-headline {
        font-size: 16px;
        font-family: $image-pre-headline-font;
        text-transform: uppercase;
        font-weight: $weight-medium;
      }

      .sub-headline {
        font-size: 16px;
      }

      .link-title {
        font-family: $font-family-primary-alt;
        font-size: 20px;

        margin-top: 32px;

        i {
          margin-left: 16px;
        }
      }
    }

    .image--disclaimer {
      position: absolute;
      bottom: 20px;
      right: 20px;
      margin: 0;
      width: 150px;

      color: $font-color-dark-bg;
      font-size: 12px;
    }
  }
  .image-descr {
    width: 100%;
  }
}

.image-container {
  a {
    position: relative;
    display: block;
    height: 100%;
    width: 100%;
    background-color: $light-grey;
  }

  &.external-link {
    a {
      border-radius: 0 30px 0 0;
      overflow: hidden;

      .external-link-icon {
        position: absolute;
        top: 0;
        right: 0;
        background: $orange;
        color: $white;
        font-size: 16px;
        padding: 16px;
      }
    }
  }
}

// Background Variants

.light-bg {
  .image-container {
    a {
      background-color: $white;
    }
  }
} //.light-bg

// End Background Variants