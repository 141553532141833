.image-mosaic-container{
  margin-bottom: $component-margin-bottom;

  .grid-item{
    margin-bottom: 24px;
    padding-left: 10px;
    padding-right: 10px;

    width: 100%;


    @media (min-width: $breakpoint-md-min) and (max-width: $breakpoint-md-max) {
      width: 50%;
    }

    @media (min-width: $breakpoint-lg-min) {
      width: 33%
    }

    .image-container{
      margin-bottom: 16px;
      overflow: hidden;

      img{
        max-height: 500px;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
      }
    }

    .content-container{
      font-weight: $weight-light;
    }
  }
}