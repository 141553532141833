.dropdown-container{
  position: relative;
  display: inline-block;

  .dropdown-items{
    position: absolute;
    z-index: 2;
    top: 100%;
    left: 0; 
    width: 100%;
    
    height: 0;
    overflow: hidden;

    background-color: $white;
  
    border-radius: 0 20px 20px 20px;
    
    -webkit-box-shadow: 0px 0px 8px 0px rgba($black,0.75);
    -moz-box-shadow: 0px 0px 8px 0px rgba($black,0.75);
    box-shadow: 0px 0px 8px 0px rgba($black,0.75);

    ul{
      padding-top: 15px;
      padding-bottom: 15px;

      li{
        display: flex;

        > *{
          flex: 1;
          display: flex;
          justify-content: space-between;
          
          padding: 7px 15px;
          width: 100%;
  
          &:hover,
          &:focus{
            background-color: #ECF3FB;
          }
        }
      }
    }    
  }

  &:hover,
  &.focus{
    .dropdown-items{
      height: auto;      
    }
  }
}
