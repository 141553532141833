.social-links {
  display: inline;
  &.component {
    margin-right: $inline-component-margin-right;
    margin-bottom: $inline-component-margin-bottom;
    font-size: 32px;
    vertical-align: super;
  }
  > * {
    display: inline;
    margin-right: 40px;
  }
}

.social-link {
  color: $font-color;
  display: inline-block;

  &:hover,
  &:hover span {
    color: $font-color-hover;
    text-decoration: none;
  }
}

// Background variations
// .light-bg { }

.dark-bg {
  .social-link {
    color: $font-color-dark-bg;

    &:hover {
      color: $font-color-dark-bg-hover;
    }
  }
}
// End Background variations
