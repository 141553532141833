.accordion-container {
  position: relative;
  margin-bottom: $component-margin-bottom;

  .accordion-header {
    display: flex;
    justify-content: space-between;

    h4 {
      margin-bottom: 30px;
      flex: 1 1 1;
    }

    .action-container {
      .link {
        margin-left: 20px;
      }
    }
  }

  .accordion-item {
    position: relative;
    display: flex;
    flex-direction: column;
    padding-top: 20px;

    .accordion-title-container {
      border-bottom: 1px solid $mid-grey;
      cursor: pointer;

      > [class^="col-"]:first-child {
        display: flex;
        flex-direction: row;
      }

      color: $blue;
      text-decoration: none;

      padding-bottom: 20px;

      &:hover {
        color: $dark-blue;
        text-decoration: none;
      }

      .accordion-number {
        font-size: 44px;
        font-weight: $weight-light;
        line-height: 40px;

        padding-right: 32px;

        @media (max-width: $breakpoint-md-max) {
          padding-right: 12px;
        }
      }

      .accordion-title {
        font-weight: $weight-bold;
      }

      .accordion-expanded-triangle {
        text-align: right;

        .title-arrow-up,
        .title-arrow-down {
          position: relative;
          right: (-$grid-gutter-width-sm);
        }
      }

      .title-arrow-up {
        padding-top: 8px;
        display: none;
      }
      .title-arrow-down {
        padding-top: 7px;
        display: inline-block;
      }
    }

    .accordion-body {
      max-height: 0px;
      overflow: hidden;
      transition: max-height $transition-speed $transition-style;
      background-color: $light-grey;

      > div {
        padding: 20px 32px 80px 32px;
      }
    }

    &.has-number {
      .accordion-body {
        > div {
          padding-left: 88px;

          @media (max-width: $breakpoint-sm-max) {
            padding-left: 50px;
          }
        }
      }
    }

    &.expanded {
      .accordion-title-container {
        color: $black;

        .title-arrow-up {
          display: inline-block;
        }
        .title-arrow-down {
          display: none;
        }

        .accordion-title {
          position: relative;
          width: 100%;
        }

        .accordion-expanded-triangle {
          // Top of body triangle (http://www.cssarrowplease.com/)
          &::after,
          &::before {
            bottom: -21px;
            right: 0;
            border: solid transparent;
            content: " ";
            height: 0;
            width: 0;
            position: absolute;
            pointer-events: none;
          }

          &::after {
            border-color: rgba(136, 183, 213, 0);
            border-bottom-color: $light-grey;
            border-width: 10px;
            margin-left: 1px;
          }
          &::before {
            border-color: rgba(194, 225, 245, 0);
            border-bottom-color: $mid-grey;
            border-width: 12px;
            margin-right: -2px;
          }
          // End Top of body triangle
        }
      }

      .accordion-body {
        max-height: 9999px;
        transition: max-height 4s $transition-style;
      }
    }
  }
}

// Background variations
.background-container {
  &.light-bg {
    .accordion-container {
      .accordion-item {
        &.expanded {
          .accordion-title-container {
            .accordion-expanded-triangle {
              &::after {
                border-bottom-color: $light-bg-body-bg;
              }
            }
          }
        }
      }
      .accordion-body {
        background-color: $light-bg-body-bg;
      }
    }
  }
  &.dark-bg {
    .accordion-container {
      .accordion-item {
        &.expanded {
          .accordion-title-container {
            color: $white;

            .accordion-expanded-triangle {
              &::after {
                border-bottom-color: $dark-bg-body-bg;
              }
            }
          }
        }
      }
      .accordion-body {
        background-color: $dark-bg-body-bg;
      }
    }
  }
}
