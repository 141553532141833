#modal-notice{

  .modal {
    z-index: 20001;
  }
  
  .modal-content{
    border-radius: 0 24px 24px 24px;
    box-shadow: 0 8px 16px rgba($black,0.2);

    .modal-header{
      text-align: center;
      text-transform: uppercase;
      border-bottom: none;
      padding: 40px 40px 20px 40px;

      @media (max-width: $breakpoint-sm-max) {
        padding: 20px 20px 0 20px;
      }

      .modal-title{
        flex: 1;
        text-align: center;
        font-family: $font-family-secondary;
        font-size: 44px;
        letter-spacing: .01em;
      }
    }

    .modal-body{
      padding: 20px 40px 20px 40px;
      margin-bottom: 0;

      .modal-body-scroll {
        height: calc(100vh - 400px);
        padding-right: 10px;
      }

      @media (max-width: $breakpoint-sm-max) {
        padding: 10px 20px 20px 20px;

        .modal-body-scroll {
          height: calc(100vh - 375px);
        }
      }
    }

    .modal-footer{
      border-top: none;
      padding: 20px 40px 40px 40px;

      button{
        margin-left: 40px;
        margin-bottom: 0;
      }

      @media (max-width: $breakpoint-sm-max) {
        padding: 0 20px 20px 20px;

        // On small screen, stack buttons
        flex-direction: column;

        button{
          margin: 20px 0 0 0;
          
          &:first-child{
            margin-top: 0;
          }
        }
      }
    }
  }

} // #modal-notice

.modal-backdrop{
  &.show{
    opacity: 0.85;
  }
}