.image-scroll-carousel {
  position: relative;

  .prev-control,
  .next-control {
    position: absolute;
    top: 25%;
    transform: translateX(-50%);

    cursor: pointer;

    color: $black;
    font-size: 45px;
    font-weight: $weight-light;
    opacity: 0.2;
    transition: opacity 200ms ease-out;

    &.disabled {
      opacity: 0;
    }
  }

  .prev-control {
    left: -20px;
  }

  .next-control {
    right: -40px;
  }

  .image-scroll-carousel-container {
    position: relative;

    .scroll-items {
      white-space: nowrap;
      display: block;
      height: 100%;
      width: 100%;

      .scroll-item {
        display: inline-block;
        position: relative;
        vertical-align: top;

        margin-bottom: 48px;

        width: 100%;
        padding: 0 42px;

        @media (min-width: $breakpoint-md-min) {
          width: 50%;
        }

        @media (min-width: $breakpoint-lg-min) {
          width: 33%;
        }

        // Should not look like a link
        a {
          color: $font-color;
          font-family: $font-family-primary;
          font-weight: $weight-light;

          &:hover {
            text-decoration: none;
          }
        }

        .scroll-item--image {
          position: relative;
          z-index: 2;
          overflow: hidden;
          width: 100%;
          padding-top: 100%; // used to make the height the same as the width
          border-radius: 100%;
          margin: 0 auto;
          margin-bottom: 48px;

          img {
            object-fit: cover;
            position: absolute;
            z-index: 1;
            width: 100%;
            height: 100%;
            top: 50%;
            left: 50%;
            transform: translateX(-50%) translateY(-50%);
            transition: transform $transition-speed $transition-style;
          }

          .image--disclaimer {
            position: absolute;
            z-index: 2;
            bottom: 20px;
            left: 0;
            width: 100%;
            text-align: center;
            color: $font-color-dark-bg;
            font-size: 12px;
            padding: 0px 27%;
            white-space: normal;
          }
        }

        a:hover {
          .scroll-item--image {
            img {
              transform: translate(-50%, -50%) scale(1.1);
            }
          }
        }

        h5 {
          margin-top: 48px;
          margin-bottom: 0;
          text-align: center;
        }

        .sub-headline {
          width: 100%;
          font-size: 18px;
          font-weight: $weight-light;
          white-space: normal;
          text-align: center;
        }

        .description.rich-text {
          white-space: normal;
          text-align: center;
          font-size: 14px;
          font-weight: $weight-regular;
          white-space: normal;

          width: 100%;
          margin-top: 24px;

          a {
            font-family: $font-family-primary;
            font-size: 14px;
          }
        }

        &.loaded {
          a {
            .image-container {
              position: relative;
              overflow: hidden;
              height: 375px;

              img {
                object-fit: cover;
                position: absolute;
                width: 100%;
                height: 100%;
                top: 50%;
                left: 50%;
                transform: translateX(-50%) translateY(-50%);
                transition: transform $transition-speed $transition-style;
              }
            }

            &:hover {
              img {
                transform: translate(-50%, -50%) scale(1.1);
              }
            }
          }
        }
      }
    }
  }
}
