$privacy-warning-background-color: $light-grey !default;

.privacy-warning {
  position: fixed;
  z-index: $z-index-privacy;
  bottom: 0;
  left: 0;

  background-color: $privacy-warning-background-color;

  .container {
    margin-top: 24px;
    position: relative;
    display: flex;
    font-size: 14px;

    .consent-close {
      position: absolute;
      top: 0;
      right: 0;

      a {
        position: absolute;
        top: 0;
        right: 5px;
        height: 25px;
        width: 25px;
        overflow: hidden;

        &:before {
          display: block;
          height: 100%;
          width: 100%;
          padding-left: 6px;

          font-family: $font-awesome-pro;
          font-size: 20px;
          content: "\f00d";
          color: $black;
          cursor: pointer;
        }
      }
    }

    .info,
    .submit {
      margin-bottom: 16px;
    }


    .info {
      padding-left: 100px;
      padding-right: 20px;
    }

    .submit {
      display: flex;
      align-items: flex-start;
      width: 450px;

      a {
        cursor: pointer;
        margin: 0 auto;
      
        font-family: $font-family-link;
        color: $white;
        white-space: nowrap;
        display: inline-block;
        margin: 0 25%;
      }
    }
  }

  // Medium screen
  // Medium && Small screen
  @media (max-width: $breakpoint-md-max) {
    .container {
      flex-direction: column;
      margin-top: 12px;
      .info,
      .submit {
        margin-right: 0;
        margin-left: 0;
        padding-right: 0;
        padding-left: 0;

        a { 
          margin: 0;
        }
      }

      .submit {
        justify-content: flex-end;
      }
    }
  }

  @media (max-width: $breakpoint-md-max) and (min-width: $breakpoint-md-min) {
    .container {
      .info,
      .submit {
        width: 85%;
      }
    }
  }

  // Small screen
  @media (max-width: $breakpoint-sm-max) {
    .container {
      .info,
      .submit {
        width: 100%;
      }

      .info {
        font-size: 16px;
        line-height: 1.6;
        height: 100px;
        padding-right: 10px;
      }

      .submit {
        
        a {
          font-size: 16px !important;
        }
      }
    }
  }
}
