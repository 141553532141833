@import '../base/common';
@import './headers';
@import './dropdown';

// Global Styles
.disclaimer {
  font-family: $font-family-primary;
  font-weight: $weight-light;
  font-size: 14px;
  color: $black;
  line-height: 18px;
}

.detail-date-container {
  display: inline-block;
  position: relative;

  border-radius: 30px 30px 30px 30px;
  box-shadow: 0 8px 16px rgba($black,.1);
  padding-left:  25px;
  padding-right:  25px;
  font-family: $font-family-primary-alt;
  font-size: 20px;
  text-transform: uppercase;
  border: 1px solid $light-grey;
  z-index: 1;
  margin-bottom: 40px;

  color: $blue;
  background-color: $white;

  > span {
    display: inline-block;
    border-left: 1px solid $light-grey;
    padding-left: 15px;
    margin-left: 15px;

    padding-top: 16px;
    padding-bottom: 16px;

    &:first-child{
      border-left: 0;
      padding-left: 0;
      margin-left: 0;
    }

    i{
      margin-right: 12px;
    }
  }
}



.latest-articles {
  $post-spacing: 22px;

  .component-header {
    display: flex;

    .component-title {
      flex: 1;
      text-transform: uppercase;
    }

    .view-all {
      a {
        text-decoration: none;
        font-family: $font-family-link;
        font-weight: $weight-semibold;
      }
    }
  }

  .posts {
    white-space: nowrap;

    .post {
      margin-top: 35px;
      margin-bottom: 20px;
      white-space: normal;

      display: inline-block;
      position: relative;

      margin-right: 20px;
      &:last-child{
        margin-right: 0;
      }

      padding-bottom: 20px;

      background-color: $light-grey;
      border-radius: 0 0 30px 0;

      a {
        text-decoration: none;

        .post-date {
          position: absolute;
          top: -25px;
          left: 0;
          z-index: 2;
        }

        .post-image-container {
          position: relative;
          overflow: hidden;
          width: 100%;
          height: 220px;

          .post-image {
            position: absolute;
            width: 100%;
            top: 50%;
            left: 50%;
            transform: translateX(-50%) translateY(-50%);
            transition: transform $transition-speed $transition-style;
          }
        }

        .post-title,
        .post-body {
          margin-left: 25px;
          margin-right: 25px;
        }

        .post-title {
          margin-top: 20px;
          color: $blue;
        }
        .post-body {
          color: $black;
        }
      }

      &:hover {
        background-color: $blue;
        -webkit-box-shadow: 0px 2px 12px rgba($black, 0.2);
        -moz-box-shadow: 0px 2px 12px rgba($black, 0.2);
        box-shadow: 0px 2px 12px rgba($black, 0.2);

        a {
          .post-image {
            transform: translate(-50%, -50%) scale(1.1);
          }

          .post-title,
          .post-body {
            color: $white;
          }
        }
      }
    }
  }

  &.scrollable-posts {
    .posts {
      .post {
        width: 100%;
        
        @media (min-width: $breakpoint-md-min) {
          width: calc(33% - #{$post-spacing});
        }

        .post-image-container {
          height: 221px;
        }
      }
    }
  }

  &.double-post,
  &.single-post {
    .posts {
      .post {
        width: calc(50% - #{$post-spacing});
      }
    }
  }
  
  &.double-post{
    .posts {
      .post {
        width: calc(50% - #{$post-spacing});
      }
    }
  }

  &.single-post {
    .posts {
      .post {
        width: 100%;

        @media (min-width: $breakpoint-lg-min) {
          width: 70%;
        }
      }
    }
  }

  &.img-right{
    .post{
      
      margin-bottom: 0;
      padding-bottom: 0;
      border-radius: 30px 0 30px 0;

      a {
        display: flex;
        flex-direction: row-reverse;

        .post-date {
          top: 0;
        }

        .post-image-container {
          height: auto;
          border-radius: 0 0 30px 0;

          .post-image {
            width: auto;
            height: 100%;
          }
        }

        .post-content{
          padding-top: 55px;
          padding-bottom: 35px;
        }
      }
    }
  }
}

// Back Link Style
.back-link {

  // Start off hidden, being shown with backLink plugin
  display: none;

  text-transform: uppercase;
  font-family: $font-family-primary-alt;
  font-weight: $weight-semibold;

  margin-bottom: 32px;

  &.visible{
    display: block;
  }

  // This was added as a spacer, so when there isnt a back link,
  //  the top is really 175px offset
  &.invisible{
    display: block;
    visibility: hidden;
    height: 15px;
    max-height: 15px;
    margin-bottom: 0;
  }

  a {
    text-decoration: none;

    &:hover {
      text-decoration: none;
    }
  }

  i {
    margin-right: 12px;
  }
}

.page-title-container {
  &.with-hero {
    .back-link {
      a {
        color: $white;
      }
    }
  }
}
// End Back Link Style

// Logo
// .logo-container {
//   position: relative;
//   top: -2px;

//   .scrolled-logo {
//     transition: opacity $transition-speed $transition-style;
//     position: absolute;
//     opacity: 0;
//   }
// }

// .scrolled{
//   .logo-container {
//     .scrolled-logo {
//       opacity: 1;
//     }
//   }
// }

// body:not(.has-hero){
//   #main-nav-desktop-container{
//     .logo-container {
//       .scrolled-logo {
//         opacity: 1;
//       }
//     }
//   }
// }
  

// End Logo